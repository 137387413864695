import { Stack, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "../LandingPage.styles";
import Card from "../Card/Card";

const LocationCard = ({ locations, onCardClick }) => {
  const styles = useStyles();
  return (
    <>
      <Stack className={styles.titles} mt="5rem">
        <Typography variant="h1">EXPLORE POPULAR LOCATION</Typography>
        <Typography variant="h2">
          Explore the latest listings & projects in diverse areas
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        gap={"3rem"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        pb={"5rem"}
      >
        {locations?.map((item) => (
          <Card locations={item} onClick={onCardClick} />
        ))}
      </Stack>
    </>
  );
};

export default LocationCard;
