import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "1rem",
    paddingRight: "9rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem",
      marginLeft: "0rem",
    },
  },
  card: {
    width: "40rem",
    height: "60rem",
    borderRadius: "0.2rem",
    boxShadow: "0.2rem 0.2rem 1rem",
    marginLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
      width: "36rem",
      margin: "auto",
    },
  },
  imgContainer: {
    "& img": {
      width: "40rem",
      height: "28rem",
      aspectRatio: "4/2.5",
      [theme.breakpoints.down("sm")]: {
        width: "36rem",
      },
    },
  },
  content: {
    padding: "8rem 5rem",
    position: "relative",
    marginTop: "-10rem",
    zIndex: "1",
    transition: "all 0.3s linear",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: "#ffffff",
      transformOrigin: "100% 50%",
      transform: "skewY(-6deg)",
      willChange: "transform",
      transition: "all .3s ease",
      pointerEvents: "none",
      zIndex: -1,
      height: "10rem",
    },
    "&:hover": {
      "&:before": {
        transform: "skewY(0deg)",
      },
      "&>div:last-child": {
        right: "17rem",
      },
    },
  },
  index: {
    background: theme.palette.primary.main,
    borderRadius: "50%",
    position: "absolute",
    width: "6rem",
    height: "6rem",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "-1rem",
    right: "6rem",
    transition: "all 0.3s linear",
  },
}));
