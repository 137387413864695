import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  typoHeading: {
    fontWeight: "bold !important",
    color: `${theme.palette.secondary.main} !important`,
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  Box1: {
    borderRadius: "0.5rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    padding: "1rem",
    width: "85%",
  },
  InBox: {
    display: "flex",
    gap: "1rem",
    flexDirection: "row",
  },
  tickIcon: {
    width: "2rem",
    height: "2rem",
  },
  card: {
    borderRadius: "0.5rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    padding: "1rem",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "85%",
      margin: "auto",
    },
  },
}));
