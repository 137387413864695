import React from 'react'
import GallerySection from '../GallerySection/GallerySection'
import { ScrollComponent } from '../../CommonComponent/ScrollComponent/ScrollComponent'

const ScrollGallery = () => {
  return (
    <ScrollComponent variant='fade'>
      <GallerySection />
    </ScrollComponent>
  )
}

export default ScrollGallery
