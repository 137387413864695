import { Box, Typography, Stack } from "@mui/material";
import { useStyles } from "./Card.styles";

const Card = ({
  contactCard = { name: "cardName", icon: "cardImage", content: "content" },
  onclick = () => {},
}) => {
  const styles = useStyles();
  const { id, redirectTo, name, icon, content, title } = contactCard;
  return (
    <Box
      key={id}
      alignItems={"left"}
      // onClick={() => onclick(redirectTo)}
      className={styles.container}
    >
      <a href={redirectTo} target="_blank">
        <Box className={styles.LinkBox}>
          <img src={icon} />
          <Typography variant="h5">{title}</Typography>
          <Typography variant="h3">{content}</Typography>
        </Box>
      </a>
    </Box>
  );
};

export default Card;
