import { makeStyles } from "@mui/styles";
import { Background1 } from "../Assets";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem",
  },
  pContainer: {
    display: "flex",
    gap: "2rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  imgContainer: {
    "& img": { width: "100%" },
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
  stackContainer: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    justifyContent: "left",
  },
  aboutContainer: {
    padding: "1rem",
    justifyContent: "center",
    width: "90%",
  },
  typoHeading: {
    fontWeight: "bold !important",
    color: `${theme.palette.secondary.main} !important`,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  treezImgBox: {
    display: "flex",
    width: "35rem",
    height: "35rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  tickIcon: {
    width: "2rem",
    height: "2rem",
  },
  box: {
    // background: '#e5e5e5',
    background: `linear-gradient(#00000040 100%,#000000 100%), url(${Background1})`,

    backgroundAttachment: "fixed",
  },
  carouselLight: {
    background: theme.palette.background.main,
  },
  layout: {
    margin: "0rem 0",
    padding: "0rem 0rem",
    justifyContent: "center",
  },
  cLayout: {
    margin: "0rem 0",
    padding: "0rem 0rem",
  },
  imgbox1: {
    borderRadius: "0.5rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    // padding: "0.5rem",
    // width: "40%",
    // height: "60%",
    height: "52em",
    // width: "39rem",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  stackBox: {
    borderRadius: "0.5rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    padding: "1rem",
    width: "85%",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  imgBox2: {
    borderRadius: "0.5rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    padding: "1rem",
    width: "95%",
    height: "40rem",
  },
  imgBox3: {
    // borderRadius: "0.5rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    padding: "1rem",
    width: "40%",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  InBox3: {
    display: "flex",
    gap: "1rem",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  cardClass: {
    width: "40.5rem !important",
  },
  className: {
    padding: "0 !important",
    gap: "1rem !important",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  parent: {
    gap: "2rem",
    padding: "1rem 5rem 10rem",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      paddingBottom: "5rem",
    },
  },
}));
