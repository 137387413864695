import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    background: "linear-gradient(to right, #ee1c6f, #242c99)",
    position: "relative",
    height: "100rem",
    marginTop: "30rem",
    "&>div:first-child": {
      height: "100rem",
      [theme.breakpoints.down("md")]: {
        height: "100rem",
      },
      [theme.breakpoints.down("sm")]: {
        height: "250rem",
      },
    },
    [theme.breakpoints.down("lg")]: {
      height: "120rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "190rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "250rem",
    },
  },
  cardWrapper: {
    display: "flex",
    justifyContent: "space-around",
    gap: "1rem",
    position: "absolute",
    top: "-15rem",
    width: "100vw",
    flexWrap: "wrap",
  },
  formWrapper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  box: {
    width: "30%",
    padding: "5rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  card: {
    background: "white",
    boxShadow: "0rem 0rem 1rem",
    width: "30rem",
    height: "30rem",
    padding: "0.5rem",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    borderRadius: "1rem",
    position: "relative",
    "&>svg": {
      width: "6rem",
      height: "6rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "20rem",
    },
  },
  form: {
    alignItems: "center",
    gap: "3rem",
    "& .MuiFormControl-root": { width: "100%" },
    "& .MuiFormLabel-root": { color: "white", fontSize: "2rem" },
    "& .MuiInputBase-root": {
      "&>input": {
        color: "white",
        fontSize: "2rem",
      },
      "&:before": {
        borderColor: "white",
      },
    },
  },
  socialIcons: {
    justifyContent: "start !important",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center !important",
    },
  },

  banner: {
    position: "absolute",
    overflow: "hidden",
    // height: '25rem',
    width: "100%",
    transform: "rotate(-38deg)",
    transformOrigin: "left",
    top: "8rem",
    left: "0rem",

    "&>div": {
      position: "relative",
      height: "3rem",
      background: theme.palette.primary.main,
      width: "12rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "&>div::before": {
      content: '""',
      position: "absolute",
      right: "-18%",
      width: "30%",
      height: "2rem",
      top: "0",
      backgroundColor: theme.palette.primary.main,
      transform: "skew(-50deg)",
    },
    "&>div::after": {
      content: '""',
      position: "absolute",
      right: "-18%",
      width: "30%",
      height: "2rem",
      bottom: "0",
      backgroundColor: theme.palette.primary.main,
      transform: "skew(50deg)",
    },
  },
}));
