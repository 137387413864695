import {
  Amphitheater,
  BasketBall,
  Clubhouse,
  Doors,
  Electrical,
  ExpandIcon,
  Floor,
  FlowerGarden,
  Gym,
  IndoorGames,
  KidsZone,
  Plumbing,
  RetialShop,
  Road,
  Security,
  Structure,
  SwimmingPool,
  Tennis,
} from "../Components/Assets";
import emailjs from "@emailjs/browser";
import { notify } from "../Components/CommonComponent/Notify/Notify";

export const getAmentiesData = (type) => {
  switch (type) {
    case "gym":
      return { Icon: Gym, title: "Gym" };
    case "club_house":
      return { Icon: Clubhouse, title: "Club House" };
    case "swimming_pool":
      return { Icon: SwimmingPool, title: "Swimming Pool" };
    case "indoor_games":
      return { Icon: IndoorGames, title: "Indoor Games" };
    case "basket_ball":
      return { Icon: BasketBall, title: "Basket Ball" };
    case "roads":
      return { Icon: Road, title: "Roads" };
    case "tennis_courts":
      return { Icon: Tennis, title: "Tennis Courts" };
    case "flower_garden":
      return { Icon: FlowerGarden, title: "Flower Garden" };
    case "kids_club":
      return { Icon: KidsZone, title: "Kids Club" };
    case "kids_play_area":
      return { Icon: KidsZone, title: "Kids Play Area" };
    case "amphitheater":
      return { Icon: Amphitheater, title: "Amphitheater" };
    case "retail_shops":
      return { Icon: RetialShop, title: "Retail Shops" };
    default:
      return { Icon: ExpandIcon, title: type };
  }
};

export const getSepcificationData = (type) => {
  switch (type) {
    case "structure":
      return { Icon: Structure, title: "Structure" };
    case "flooring":
      return { Icon: Floor, title: "Flooring" };
    case "doors":
      return { Icon: Doors, title: "Foors" };
    case "plumbing_sanitary":
      return { Icon: Plumbing, title: "Plumbing Sanitary" };
    case "security":
      return { Icon: Security, title: "Security" };
    case "electrical":
      return { Icon: Electrical, title: "Electrical" };
    default:
      return { Icon: ExpandIcon, title: type };
  }
};

export const sendEmail = (e, form) => {
  e.preventDefault();

  emailjs
    .sendForm("service_idj28hc", "template_n7gzqvu", form.current, {
      publicKey: "G88NmfizmzXbGdQ3y",
    })
    .then(
      () => {
        notify.success("Submitted Successfully");
      },
      (error) => {
        notify.error("Something went wrong");
      }
    );
  return;
};
