import { makeStyles } from "@mui/styles";
import { Background1, Contact_Page_Img } from "../../Assets/index";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    // '& img': {
    //   width: '100%',
    // },
    width: "100%",
    height: "50rem",
    // background: `linear-gradient(180deg,rgba(0,66,116,-4.65) 0%,${theme.palette.background.dark} 100%),url(${Contact_Page_Img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  content: {
    position: "absolute",
    top: "15rem",
    width: "80%",
    marginLeft: "3rem",
    overflow: "hidden",
    zIndex: 2,
    "&>div": {
      color: theme.palette.text.main,
      letterSpacing: "0.2rem",
      textShadow: "0.4rem 0.4rem 0.2rem gray",
    },

    "&>div:first-child": {
      animation: "$slide 0.5s linear",
    },
    "&>div:last-child": {
      animation: "$slide_left 1s linear",
    },
    [theme.breakpoints.down("sm")]: {
      top: "2rem",
      width: "100%",
      textAlign: "center",
      marginLeft: "0",
    },
  },
  "@keyframes slide": {
    "0%": {
      transform: "translateX(60rem)",
    },
    "100%": {
      transform: "translateX(0rem)",
    },
  },
  "@keyframes slide_left": {
    "0%": {
      transform: "translateX(-60rem)",
    },
    "100%": {
      transform: "translateX(0rem)",
    },
  },

  banner: {
    position: "absolute",
    overflow: "hidden",
    // height: '25rem',
    width: "100%",
    transform: "rotate(-38deg)",
    transformOrigin: "left",
    top: "24rem",
    left: "1rem",

    "&>div": {
      position: "relative",
      height: "5rem",
      background: theme.palette.primary.main,
      width: "20rem",
    },
    "&>div::before": {
      content: '""',
      position: "absolute",
      right: "-75%",
      width: "85%",
      height: "2rem",
      top: "0",
      backgroundColor: theme.palette.primary.main,
      transform: "skew(-50deg)",
    },
    "&>div::after": {
      content: '""',
      position: "absolute",
      right: "-75%",
      width: "85%",
      height: "2rem",
      bottom: "0",
      backgroundColor: theme.palette.primary.main,
      transform: "skew(50deg)",
    },
  },
  //css
  exampleContainer: {
    width: "100vw",
    height: "100vh",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "&>img": {
      position: "absolute",
      width: "100vw",
      height: "inherit",
      scale: 1,
      animation: "$zoom 10s linear 0.5s",
      objectFit: "cover",
    },

    "& $nextBtn, $prevBtn": {
      top: "calc(50% - 20px)",
      position: "absolute",
      background: "white",
      borderRadius: "30px",
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      userSelect: "none",
      cursor: "pointer",
      fontWeight: "bold",
      fontSize: "18px",
      zIndex: 2,
      opacity: "0.2",
      transition: "opacity 0.2s linear",
      "&:hover": {
        opacity: 1,
      },
    },
  },
  "@keyframes zoom": {
    "0%": {
      zoom: "1",
    },
    "50%": {
      transform: "scale(1.1)",
    },
    "100%": {
      transform: "scale(1.2)",
    },
  },
  prevBtn: {
    left: "10px",
    transform: "scale(-1)",
  },
  nextBtn: {
    right: "10px",
  },
  svg: {
    width: "10rem",
    height: "10rem",
    position: "absolute",
    // background: `linear-gradient(to left top, #ff6995, #3ecdff)`,
    background: `linear-gradient(to left top, #ff6995, #3ecdff)`,
    zIndex: 3,
    borderRadius: "1rem",
    boxShadow: "inset -0.6rem -0.8rem 0rem #ffffff50",
    transform: "rotate(-45deg) rotateY(14deg) rotateX(35deg) rotate(68deg)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s linear",
    "&:hover": {
      transform: "rotate(0)",
      boxShadow: "1rem 1rem 3rem black",
      "& $childBox>div": {
        visibility: "visible",
        "&:nth-child(1)": {
          left: "-25rem",
        },
        "&:nth-child(2)": {
          top: "18rem",
        },
        "&:nth-child(3)": {
          left: "20rem",
        },
        "&:nth-child(4)": {
          top: "-18rem",
        },
      },
    },
    "& svg": {
      width: "8rem",
      height: "8rem",
    },
  },
  childBox: {
    "&>div": {
      transition: "all 0.1s linear",
      width: "15rem",
      height: "3rem",
      padding: "2rem",
      position: "absolute",
      boxShadow: "1rem 1rem 1rem ",
      visibility: "hidden",
      left: "0",
      top: "0",
      right: "0",
      bottom: "0",
      background: "red",
      borderRadius: "1rem",
    },
  },
}));
