import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
  visible: { opacity: 1, transition: { duration: 1, delay: 0.2 } },
  hidden: { opacity: 0 },
};
const getVariant = (type) => {
  switch (type) {
    case "scale":
      return {
        visible: { ...boxVariant.visible, scale: 1 },
        hidden: { ...boxVariant.hidden, scale: 0 },
      };
    case "fade":
      return {
        ...boxVariant,
      };
    case "slide_left":
      return {
        hidden: {
          x: -300,
          opacity: "0",
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            type: "slide",
            duration: 0.8,
          },
        },
      };
    case "slide_right":
      return {
        hidden: {
          x: 300,
          opacity: "0",
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            type: "slide",
            duration: 0.8,
          },
        },
      };
    case "spring":
      return {
        hidden: {
          y: 300,
          opacity: "0",
        },
        visible: {
          y: 30,
          opacity: 1,
          transition: {
            type: "spring",
            bounce: 0.6,
            duration: 0.8,
          },
        },
      };

    default:
      return {
        visible: { ...boxVariant.visible, scale: 1 },
        hidden: { ...boxVariant.hidden, scale: 0 },
      };
  }
};

export const ScrollComponent = ({ variant = "scale", children }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      // control.start("hidden");
    }
  }, [control, inView]);
  return (
    <motion.div
      className="card-container"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.8 }}
      ref={ref}
      animate={control}
    >
      <motion.div className="card" variants={getVariant(variant)}>
        {children}
      </motion.div>
    </motion.div>
    // <motion.div className='box' ref={ref} variants={getVariant(variant)} initial='hidden' animate={control}>
    //   {children}
    // </motion.div>
  );
};

const hue = (h) => `hsl(${h}, 100%, 50%)`;
function Card({ emoji, hueA, hueB }) {
  const background = `linear-gradient(306deg, ${hue(hueA)}, ${hue(hueB)})`;

  return <></>;
}
