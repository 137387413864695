import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Earphone_Telephone, Gmail_Logo, Whatapp_Icon } from "../Assets";
import Button from "../CommonComponent/Button/Button";
import { useStyles } from "./SocialLinkPage.styles";
import SocialIcons from "../Footer/SocialIcons";
import { socialLinks } from "../../Utils/Data/SocialLinksData";
import { BANGALORE_ROUTE, HYDERABAD_ROUTE } from "../../Utils/utils-constant";
import { Link } from "react-router-dom";
import { sendEmail } from "../../Utils/utils-function";

const contactCard = [
  {
    name: "Mobile_Number",
    icon: Earphone_Telephone,
    content: "+91 97529786900",
    background: "#666666",
    redirectTo: "",
  },
  {
    name: "WhatsApp",
    icon: Whatapp_Icon,
    content: "+91 97529786900",
    background: "#666666",
    redirectTo: "",
  },
  {
    name: "Gmail",
    icon: Gmail_Logo,
    content: "vivekwcdm@gmail.com",
    background: "#666666",
    redirectTo: "",
  },
];

const SocialLinkPage = () => {
  const styles = useStyles();

  let bangloreLinks = socialLinks[BANGALORE_ROUTE];
  let hyderabadLinks = socialLinks[HYDERABAD_ROUTE];
  const formRef = useRef();

  return (
    <Stack className={styles.container}>
      <Box sx={{}}>
        <Box className={styles.cardWrapper}>
          {Object.keys(bangloreLinks).map((data) => (
            <Card data={{ ...bangloreLinks[data], location: "Bangalore" }} />
          ))}
          {Object.keys(hyderabadLinks).map((data) => (
            <Card data={{ ...hyderabadLinks[data], location: "Hyderabad" }} />
          ))}
        </Box>
      </Box>

      <Box className={styles.formWrapper}>
        <Box className={styles.box}>
          <form ref={formRef}>
            <Form onClick={(e) => sendEmail(e, formRef)} />
          </form>
        </Box>
        {/* <Divider orientation="vertical" /> */}
        <Box className={styles.box}>
          <Details />
        </Box>
      </Box>
    </Stack>
  );
};

export default SocialLinkPage;

const Card = ({ data }) => {
  const styles = useStyles();
  const { content, name, icon: Icon, link, location } = data;

  return (
    <Link to={link} target="_blank" style={{ textDecoration: "none" }}>
      <Stack className={styles.card}>
        {/* <img src={icon} /> */}
        {/* <Box
          sx={{
            fontSize: "1.2rem",
            position: "absolute",
            top: "3rem",
            left: "1rem",
            rotate: "-45deg",
          }} */}
        {/* > */}
        <Banner data={location} />
        {/* </Box> */}
        <Icon />
        <Typography variant="h1">{name}</Typography>
        <Typography
          variant="body2"
          textAlign={"center"}
          sx={{ wordBreak: "break-all" }}
        >
          {link ?? content}
        </Typography>
      </Stack>
    </Link>
  );
};

export const Form = ({ className, sx = {}, onClick }) => {
  const styles = useStyles();
  const [formData, setFormData] = useState({});
  const nameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const messageRef = useRef();

  const handleForm = (val) => {
    setFormData({ ...formData, ...val });
  };

  return (
    <Stack className={[styles.form, className ? className : ""]} sx={{ ...sx }}>
      <TextField
        id="standard-basic"
        value={formData?.name || ""}
        label="Name"
        variant="standard"
        required
        onChange={(e) => handleForm({ name: e.target.value })}
        name="name"
        ref={nameRef}
      />
      <TextField
        id="standard-basic"
        value={formData?.email || ""}
        label="Email"
        variant="standard"
        required
        onChange={(e) => handleForm({ email: e.target.value })}
        name="email"
        ref={emailRef}
        type="email"
      />
      <TextField
        id="standard-basic"
        value={formData?.mobile_number || ""}
        label="Phone No."
        variant="standard"
        required
        onChange={(e) => {
          const value = e.target.value.replace(/[^0-9]+/g, "");
          if (value.length <= 10) handleForm({ mobile_number: +value });
        }}
        name="mobile_number"
        ref={mobileRef}
        type="tel:"
        inputProps={{ maxLength: 10 }}
      />
      <TextField
        id="standard-basic"
        value={formData?.message || ""}
        label="Message"
        variant="standard"
        onChange={(e) => handleForm({ message: e.target.value })}
        name="message"
        ref={messageRef}
      />
      <Button
        title="Submit"
        onClick={async (e) => {
          if (formData?.name && formData?.email && formData?.mobile_number) {
            onClick(e);
            setFormData({});
          }
        }}
      />
    </Stack>
  );
};

const Details = () => {
  const styles = useStyles();

  return (
    <Stack gap="2rem">
      <Typography color={"text.main"} variant="h1">
        Get in touch
      </Typography>
      <Typography color={"text.main"} variant="h3">
        Fill Out The Form Below & unlock Your Dream Home Information. Hurry Up!!
        Grab The Opportunity Now.
      </Typography>
      <Typography color={"text.main"} variant="h4">
        We can ensure reliability, low cost fares and most important, with
        safety and comfort in mind.
      </Typography>
      <SocialIcons className={styles.socialIcons} />
    </Stack>
  );
};

const Banner = ({ data }) => {
  const styles = useStyles();

  return (
    <Box className={styles.banner}>
      <Stack direction={"row"}>
        <Typography color={"text.main"}>{data}</Typography>
      </Stack>
    </Box>
  );
};
