import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  Box1: {
    borderRadius: "0.5rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    padding: "1rem",
    width: "85%",
  },
  InBox: {
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
  },
}));
