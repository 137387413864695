import { Kesineni1, PraneethG1 } from "../../../../Components/Assets";

export const Hyderabad_villas_list_data = [
  {
    id: "HAD501",
    project_name: "Praneet Pranav Group",
    type: "Villas",
    status: "Ongoing",
    location: "Chetlapotharam.Near to Dhindikal ,Gagillapur",
    price: "2 Cr* Onwards",
    size: " 2176.00 sq.ft. - 4566.00 sq.ft. ",
    units: "884",
    bg_img: PraneethG1,
  },
  {
    id: "HAD502",
    project_name: "Kesineni Villa",
    type: "Villas",
    status: "Ongoing",
    location: "Dungigal North Hyderabad",
    price: " 1.20 Cr* Onwards",
    size: "2195.00 sq.ft. - 2650.00 sq.ft.",
    units: "455",
    bg_img: Kesineni1,
  },
];
