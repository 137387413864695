import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  LinkBox: {
    alignItems: "center",
    // gap: "2rem",
    // display: "flex",
    // border: "0.01rem solid black",
    borderRadius: "0.5rem",

    // padding: "0.8rem",
    height: "50rem",
    width: "100%",
    boxSizing: "border-box",
    // color: "#666666",
    // backgroundColor: "#ebebeb",
    // boxShadow: "0.1rem 0.1rem 0.5rem",
  },
  mapBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
}));
