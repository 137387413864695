import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  LinkBox: {
    alignItems: "center",
    gap: "2rem",
    display: "flex",
    border: "0.01rem solid black",
    borderRadius: "0.5rem",
    cursor: "pointer",
    padding: "0.8rem",
    height: "25rem",
    width: "25rem",

    // color: "#666666",
    // backgroundColor: "#ebebeb",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      borderRadius: "0rem",
      width: "5rem",
      height: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      "& img": {
        width: "4rem",
        height: "4rem",
      },
      "&>div": {
        fontSize: "2rem",
      },
    },
  },
  container: {
    position: "relative",
    overflow: "hidden",
    "& a": {
      textDecoration: "none",
    },
    "&:before": {
      content: '""',
      background: theme.palette.primary.main,
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: -1,
      transform: " translate(0, 27rem)",
      transition: "all 0.2s linear",
    },
    "&:hover": {
      "&:before": {
        transform: " translate(0, 0)",
      },
      "& div": {
        color: `${theme.palette.text.main} !important`,
      },
    },
  },
}));
