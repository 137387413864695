import { Box, Stack, Typography } from "@mui/material";
import { Bangalore, RightArrow } from "../../Assets/index";
import { useStyles } from "./Card.styles";

const Card = ({
  locations = { name: "Bangalore", image: Bangalore, decription: "Bangalore" },
  onClick = () => {},
}) => {
  const styles = useStyles();
  const {
    id,
    redirectTo,
    name,
    image,
    decription,
    background = "linear-gradient(#7fc55c,#1f223e)",
  } = locations;
  return (
    <Stack
      key={id}
      alignItems={"center"}
      className={styles.container}
      sx={{ background: background }}
      onClick={() => onClick(redirectTo)}
    >
      <img src={image} />
      {/* <Typography className={styles.name} variant='h2'>
        {name}
        <RightArrow className={styles.arrowIcon} />
      </Typography> */}
      <Box className={styles.details}>
        <Typography className={styles.title} variant="h1" textAlign={"center"}>
          {decription}
          <RightArrow className={styles.arrowIcon} />
        </Typography>
      </Box>
    </Stack>
  );
};

export default Card;
