import {
  Accordion as MuiAccordian,
  AccordionDetails,
  AccordionSummary,
  List,
  Typography,
  Stack,
} from "@mui/material";
import { ExpandIcon } from "../../Assets";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    width: "2rem",
    "& path": {
      fill: "black",
    },
  },
  AccSummary: {
    backgroundColor: `${theme.palette.background.dark}10 !important`,
  },
  summary: {
    "&>img": {
      width: "2rem",
    },
  },
}));

export const Accordion = ({
  data,
  children,
  startIcon,
  expanded,
  onChange,
}) => {
  const { title, description, type } = data;
  const styles = useStyles();
  return (
    <MuiAccordian expanded={expanded} onChange={onChange}>
      <AccordionSummary
        className={styles.AccSummary}
        expandIcon={<ExpandIcon className={styles.expandIcon} />}
      >
        <Stack direction={"row"} className={styles.summary} gap={"2rem"}>
          {startIcon && startIcon}
          <Typography variant="h5">{title}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordian>
  );
};
