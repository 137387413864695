import { Box, Popover, Typography } from '@mui/material'
import { ChatIcon, CrossIcon } from '../../Assets/index'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import SocialIcons from '../../Footer/SocialIcons'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: '3rem',
    right: '3rem',
    padding: '1.8rem',
    borderRadius: '50%',
    zIndex: '1000',
    cursor: 'pointer',
    boxShadow: '0.6rem 0.5rem 1rem',
    transition: 'all 0.5s ease 1s',

    '&>img': {
      height: '2rem',
    },
    background: theme.palette.primary.main,
  },
  paper: {
    background: 'unset !important',
    boxShadow: 'unset !important',
  },
  CrossIcon: {
    animation: '$cross 0.5s linear',
  },
  '@keyframes cross': {
    '0%': {
      rotate: '0deg',
    },
    '100%': {
      rotate: '90deg',
    },
  },
}))

const Chat = () => {
  const styles = useStyles()
  const [open, setOpen] = useState(false)
  let anchorEl = Boolean(open)
  const handleClick = (event) => {
    setOpen(event.currentTarget)
  }
  return (
    <>
      <Box className={styles.container} id='simple-popover' onClick={handleClick}>
        {open ? <CrossIcon className={styles.CrossIcon} /> : <img src={ChatIcon} />}
      </Box>
      <Popover
        id={'simple-popover'}
        open={anchorEl}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{ top: '-1rem' }}
        classes={{ paper: styles.paper }}>
        <SocialIcons direction='column' isChat={true} />
      </Popover>
    </>
  )
}

export default Chat
