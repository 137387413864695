import { makeStyles } from "@mui/styles";
import { Background1, InteriorImg } from "../Assets";

export const useStyles = makeStyles((theme) => ({
  typoHeading: {
    fontWeight: "bold !important",
    color: `${theme.palette.secondary.main} !important`,
    // variant: "h4",
  },
  stackContainer: {
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    gap: "1.5rem",
    justifyContent: "left",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      // flexDirection: "column",
      gap: "1rem",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  ImgBox: {
    width: "90%",
    height: "40rem",
    border: "0.1rem solid #A19F9D",
    borderRadius: "0.5rem",
    justifyContent: "right",
    // padding: "1rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    backgroundImage: `url(${InteriorImg})`,
    flexDirection: "row !important",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column !important",
      // flexDirection: "column !important",
    },
  },
  TypoHeading2: {
    justifyContent: "center !important",
    fontSize: "3rem !important",
    fontWeight: "bold !important",
    padding: "1rem !important",
  },
  InBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
    alignItems: "center",
    gap: "2rem",
    width: "40%",
    height: "100%",
    backgroundColor: "white",
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    clipPath: "polygon(15% 0, 100% 0, 100% 100%, 0% 100%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  InBox2: {
    width: "60%",
    height: "20%",
  },
  TypoText: {
    fontSize: "2rem",
    padding: "1rem",
  },
  layout: {
    margin: "0rem 0",
    padding: "0rem 0rem",
  },
}));
