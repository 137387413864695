import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  LinkBox: {
    alignItems: "flex-start",
    gap: "1rem",
    display: "flex",
    border: "0.1rem solid #A19F9D ",
    flexDirection: "column",
    borderRadius: "0.5rem",
    "& img": {
      alignItems: "flex-start",
      borderRadius: "0rem",
      width: "4rem",
      height: "4rem",
    },
    padding: "0.8rem",
    height: "20rem",
    width: "28rem",
    boxShadow: "0.1rem 0.1rem 0.5rem",
    [theme.breakpoints.down("sm")]: {
      //   width: "95%",
      height: "22rem",
      "& img": {
        width: "4rem",
        height: "4rem",
      },
      "&>div": {
        fontSize: "2rem",
      },
    },
  },
  NmaeTypo: {
    fontWeight: "bold !important",
    // color: `${theme.palette.secondary.main} !important`,
    fontSize: "2rem !important",
  },
}));
