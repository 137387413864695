import {
  RKAgri1,
  RKAgri2,
  RKAgri3,
  RKAgri4,
  RKAgri5,
  RKAgri6,
  RKAgri7,
  RKAgri8,
  RKAgri9,
  AssetzM1,
  AssetzM2,
  AssetzM3,
  PurvaT1,
  PurvaT2,
  PurvaT3,
  PurvaT4,
  PurvaT5,
  PurvaT6,
  PurvaT7,
  PurvaT8,
  PurvaT9,
  PurvaT10,
  BrigadeO1,
  BrigadeO2,
  BrigadeO3,
  BrigadeO4,
  BrigadeO5,
  BrigadeO6,
  BrigadeO7,
  BrigadeO8,
  BrigadeO9,
  BrigadeO10,
  BrigadeO11,
  BrigadeO12,
  EarthA1,
  EarthA2,
  EarthA3,
  EarthA4,
  EarthA5,
  EarthA6,
  EarthA7,
  EarthA8,
  EarthA9,
  EarthA10,
  EarthA11,
  EarthA12,
  AssetzF1,
  AssetzF2,
} from "../../../../Components/Assets";

export const Bangalore_plots_details_data = [
  {
    id: "BAN401",
    project_name: "RK Agri Venture",
    about:
      "Step into opulence, a visionary project that promises to enrich lives and nurture a close-knit community. Set in the picturesque landscape of Budigere Cross, Bengaluru, this Residential Township offers a seamless fusion of opulent living and nature's breathtaking beauty.",
    project_details: [
      {
        name: "Project Location",
        value: "Budigere Cross, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "31 Acres",
      },
      {
        name: "Unit Variants",
        value: "30*40 // 30*50 // 40*60 // 1200--4000 sqft",
      },
      {
        name: "Possession Time",
        value: "Immediate",
      },
      {
        name: "RERA Approved",
        value: "Awating",
      },
    ],

    project_images: [
      RKAgri1,
      RKAgri2,
      RKAgri3,
      RKAgri4,
      RKAgri5,
      RKAgri6,
      RKAgri7,
      RKAgri8,
      RKAgri9,
    ],
  },
  {
    id: "BAN402",
    project_name: "Assetz Group",
    about:
      "Assetz Group came with the newest luxury villa plots development project launched just off Hosa Road, Sarjapur Road in East Bangalore. The 39-acre residential enclave Assetz Urban Sanctum features the best in Assetz Group’s plotted segment in Bangalore. The project offers spacious villa-sized Plots ranging from 1200 sft to 3000 sft with luxurious features. Beautiful landscapes with over 60% open areas with the project spread out over a massive 39 acres of land. Lush green-covered open spaces and walkways all around Assetz Codename Urban Sanctum make it more special and elite.",
    project_details: [
      {
        name: "Project Location",
        value: "Off Hosa Road, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "39.4 Acres",
      },
      {
        name: "Unit Variants",
        value: "30*40 // 30*60 // 30*50 // 40*60 // 1200-4000 sqft",
      },
      {
        name: "Possession Time",
        value: "Aug, 2026",
      },
      {
        name: "RERA Approved",
        value: "ACK/KA/RERA/1251/310/PR/030124/007722",
      },
    ],

    project_images: [AssetzM1, AssetzM2, AssetzM3],
  },
  //add
  {
    id: "BAN403",
    project_name: "Purvankara",
    about:
      "Purvankra  Sarjapur Plots @Medahalli, Attibele Road, Sarjapur is your one-stop destination for building your dream home.  This property is nestled between a major highway, industries, IT parks, schools, colleges, hospitals, and  office spaces, making it one of the best in the area. With thoughtful amenities, the residential plots offer residents a delightful living environment.  Garden & Parks, Jogging Tracks, Kid's Park & Play Area, Rain Water Harvesting, Underground Sewerage, Electric Overhead Lines,  25ft to 40ft Wide Roads, Excellent Connectivity, and Water & Sanitary Connections are some of the features of this well-planned development.  The development will be a landmark and the ideal setting for your life. Come home to Purva Tranquility Sarjapur Plots,  where convenience and comfort will truly enable you to live a fulfilling life.",
    project_details: [
      {
        name: "Project Location",
        value: "S.Medihalli , Sarjapura road",
      },
      {
        name: "Total Land Area",
        value: "45 Acres",
      },
      {
        name: "Number of Units",
        value: "700 Units",
      },
      {
        name: "Unit Variants",
        value: "30*40 // 30*50 // 30*60 // 40*60 // 1200--4000 sqft",
      },
      {
        name: "Possession Time",
        value: "Dec 2026",
      },
    ],

    project_images: [
      PurvaT1,
      PurvaT2,
      PurvaT3,
      PurvaT4,
      PurvaT5,
      PurvaT6,
      PurvaT7,
      PurvaT8,
      PurvaT9,
      PurvaT10,
    ],
  },
  {
    id: "BAN404",
    project_name: "Brigade Group",
    about:
      "Brigade is in the most strategic and picturesque of locations. Scenic vistas spread over acres of landscaped gardens, and salubrious  environs make living here truly iconic. The colors and hues you will experience, be it the lifestyle, the scenic beauty, or  the visiting birds, is simply unparalleled. . Just like the colors of a rainbow,  we have ensured everything in Brigade Oasis adds a lot more color to your life and lifestyle.",
    project_details: [
      {
        name: "Project Location",
        value: "N H Devanahalli",
      },
      {
        name: "Total Land Area",
        value: "70 Acres",
      },
      {
        name: "Number of Units",
        value: "300 Units",
      },
      {
        name: "Unit Variants",
        value: "1500 -2000sq.ft",
      },
      {
        name: "Possession Time",
        value: "Jun 2025",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1250/303/PR/220124/006562",
      },
    ],

    project_images: [
      BrigadeO1,
      BrigadeO2,
      BrigadeO3,
      BrigadeO4,
      BrigadeO5,
      BrigadeO6,
      BrigadeO7,
      BrigadeO8,
      BrigadeO9,
      BrigadeO10,
      BrigadeO11,
      BrigadeO12,
    ],
  },
  {
    id: "BAN405",
    project_name: "GM Group",
    about:
      "Earth Aroma villa plots  is surrounded by greenery and a lake that spans the entire length on one side of the community.  Complementing this natural scenery, there are about 300 fully grown different varieties of trees.  The views are even more impressive, as villa plots  offers maximum greenery with minimal concrete footprint & has just about 8 residential plots per acre unlike other projects in the vicinity. It is natural that one would stare at it in wide-eyed wonder.",
    project_details: [
      {
        name: "Project Location",
        value: "IVC Road",
      },
      {
        name: "Total Land Area",
        value: "20 Acres",
      },
      {
        name: "Number of Units",
        value: "8 Residential plots per acres",
      },
      {
        name: "Unit Variants",
        value: "1200 - 2400sq.ft",
      },
      {
        name: "Possession Time",
        value: "Dec 2024",
      },
      {
        name: "RERA Approved",
        value:
          "Phase I RERA : PRM/KA/RERA/1250/303/PR/030822/005130, Phase II RERA : PRM/KA/RERA/1250/303/PR/171023/006343",
      },
    ],

    project_images: [
      EarthA1,
      EarthA2,
      EarthA3,
      EarthA4,
      EarthA5,
      EarthA6,
      EarthA7,
      EarthA8,
      EarthA9,
      EarthA10,
      EarthA11,
      EarthA12,
    ],
  },
  {
    id: "BAN406",
    project_name: "Assetz Group",
    about:
      "Introducing Assetz group The First Bloom, the latest opulent plot development venture situated on the bustling Bangalore-Hyderabad Highway in North Bangalore. Encompassing a sprawling 21 acres of verdant landscapes, Assetz The First Bloom epitomizes the epitome of quality construction by Assetz Group in the realm of plotted development projects. The project boasts generously sized plots of 1200, 1500, and 3300 square feet, replete with luxurious features. Distinguished by its breathtaking scenery adorned with over 5000 trees, expansive green spaces accounting for more than 47% of the area, and extensive Miyawaki gardens, Assetz  The First Bloom exudes exclusivity and sophistication. Crafted by the renowned builder, Assetz Group, this project is set to deliver a premium and wholesome living experience to the Devanahalli community, showcasing exceptional architecture and a lifestyle equivalent to the standards set by Assetz Codename The First Bloom, their pioneering plotted development endeavor. Strategically positioned with excellent connectivity, the location is right on the Bangalore-Hyderabad National Highway, a mere 10 minutes from Devanahalli and Bellary Road, and a convenient 20-minute drive from the Bangalore Kempegowda International Airport. The extensive array of amenities at Assetz The First Bloom includes a grand 3-acre clubhouse, picturesque landscaped gardens, a well-equipped gymnasium, a refreshing swimming pool, recreation rooms, outdoor sports courts, a children’s play area, a spacious party hall, and meticulous 24/7 security planning to ensure a safe and secure environment.",
    project_details: [
      {
        name: "Project Location",
        value: "N H Devanahalli",
      },
      {
        name: "Total Land Area",
        value: "23 Acres",
      },
      {
        name: "Number of Units",
        value: "251 Plots",
      },
      {
        name: "Unit Variants",
        value: "1200 - 3300 Sq.Ft",
      },
      {
        name: "Possession Time",
        value: "2026",
      },
    ],

    project_images: [AssetzF1, AssetzF2],
  },
];
