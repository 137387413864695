import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import AuthContainer from "../AuthContainer/AuthContainer";
import Button from "../../CommonComponent/Button/Button";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Form } from "../../SocialLinkPage/SocialLinkPage";
import { sendEmail } from "../../../Utils/utils-function";

const EnquiryForm = ({ className, containerClass, callback }) => {
  const handleForm = () => {};
  const form = useRef();
  return (
    <AuthContainer className={containerClass}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="h3"
          width="90%"
          m="1rem 0"
          color={"text.main"}
          textAlign={"center"}
        >
          Unlock Your Dream Home Information. Contact Us Now.
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "#ffffff50" }} />
      <form ref={form}>
        {/* <Stack
          sx={{
            "& .MuiTextField-root": {
              marginTop: "3rem",
            },

            "& .MuiTypography-root": {
              textAlign: "center",
              margin: "2rem 0",
            },
            "& a": {
              margin: "auto",
            },
          }}
          className={className}
        >
          <TextField
            variant="outlined"
            label="Name"
            placeholder="Enter Your Name"
            onChange={(e) => handleForm({ name: e.target.value })}
            name="name"
          />
          <TextField
            label="Phone No."
            placeholder="Enter Your Phone No"
            onChange={(e) => handleForm({ phone_no: e.target.value })}
            name="mobile_number"
          />
          <TextField
            label="Email Id"
            placeholder="Enter Your Email Id"
            onChange={(e) => handleForm({ email: e.target.value })}
            name="email"
          />
          <TextField
            label="Message"
            type="text-area"
            name="message"
            multiline
            rows={3}
            placeholder="Type your Message..."
            onChange={(e) => handleForm({ email: e.target.value })}
          />

          <Button
            title="Enquiry Now"
            size="large"
            variant="contained"
            sx={{ margin: "3rem auto" }}
            onClick={sendEmail}
          />
        </Stack> */}
        <Form
          sx={{
            marginTop: "3rem",
            "& .MuiFormLabel-root": { color: "white", fontSize: "1.5rem" },
            "& .MuiInputBase-root": {
              "&>input": {
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "300",
              },
            },
          }}
          onClick={(e) => {
            sendEmail(e, form);
            callback && callback();
          }}
        />
      </form>
    </AuthContainer>
  );
};

export default EnquiryForm;

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    let formData = {
      name: "ram",
      mobileNumber: "",
    };

    emailjs
      .sendForm("service_4bqjlxf", "template_5iycbrc", formData, {
        publicKey: "VRYxkrImzyLrpvzAR",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form>
  );
};
// export default ContactUs;
