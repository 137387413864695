import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Background1 } from "../../Assets";
import { useStyles } from "./ProjectCard.styles";

function ProjectCard({ data = {}, onClick = () => {} }) {
  const styles = useStyles();
  const {
    id,
    project_name,
    status,
    location,
    price,
    size,
    units,
    bg_img,
    message,
    redirect,
  } = data;
  return (
    <Stack className={styles.container} onClick={() => onClick(id, redirect)}>
      <Box className={styles.cardImg}>
        <img src={bg_img ?? Background1} />
      </Box>
      <Stack className={styles.details}>
        <Typography variant="h2">{project_name}</Typography>
        {status && (
          <Stack
            direction={"row"}
            gap="1rem"
            className={styles.statusContainer}
          >
            <Typography variant="body1">{status}</Typography>
            <Typography variant="body1">{location}</Typography>
          </Stack>
        )}
        {message ? (
          <Typography variant="h4">{message}</Typography>
        ) : (
          <Stack direction={"row"} gap="3rem">
            {price && (
              <Stack gap="1rem">
                <Typography variant="h4">Price</Typography>
                <Typography>₹{price}</Typography>
              </Stack>
            )}
            {size && (
              <>
                <Divider orientation="vertical" />
                <Stack gap="1rem">
                  <Typography variant="h4">Sqft</Typography>
                  <Typography>{size}</Typography>
                </Stack>
              </>
            )}
            {units && (
              <>
                <Divider orientation="vertical" />
                <Stack gap="1rem">
                  <Typography variant="h4">Units</Typography>
                  <Typography>{units}</Typography>
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default ProjectCard;
