import {
  DNRGroup1,
  Spectra1,
  PrestigeS1,
  GodrejB1,
  SvamithraS1,
  PavaniM1,
  SNN_E1,
  PurvaC1,
  OrchidS1,
  AssetzOOK1,
  OrchidN4,
} from "../../../../Components/Assets";

export const Bangalore_apartment_list = [
  {
    id: "BAN101",
    project_name: "DNR Groups",
    type: "Apartment",
    status: "Ongoing",
    location: "Bellandur",
    price: "1.66 Cr - 2.50 Cr",
    size: "1260 To 2300 sq.ft. ",
    units: "241 Units",
    bg_img: DNRGroup1,
  },
  {
    id: "BAN102",
    project_name: "Spectra Construction ",
    type: "Apartment",
    status: "Ongoing",
    location: "Kundhanalli",
    price: "2.05 To 2.15Cr",
    size: "1740.00 sq.ft. - 1760.00 sq.ft",
    units: "140 Units",
    bg_img: Spectra1,
  },
  {
    id: "BAN103",
    project_name: "Presitge Group",
    type: "Apartment",
    status: "Ongoing",
    location: "Ramgondalli, Bangalore",
    price: "Price On Request",
    size: "1100.00 sq.ft. - 2050.00 sq.ft.",
    units: "306 Units",
    bg_img: PrestigeS1,
  },
  {
    id: "BAN104",
    project_name: "Godrej Group",
    type: "Apartment",
    status: "Ongoing",
    location: "Budgeeri Corss, Bangalore",
    price: "Price On Request",
    size: "1285.00 sq.ft. - 2025.00 sq.ft. ",
    units: "1075 Units",
    bg_img: GodrejB1,
  },
  {
    id: "BAN105",
    project_name: "Svamitva Infra",
    type: "Apartment",
    status: "Ongoing",
    location: "HSR Layout, Bangalore",
    price: " 2.35 to 2.70 Cr",
    size: "889.00 sq.ft. - 1409.00 sq.ft.",
    units: "495 Units",
    bg_img: SvamithraS1,
  },
  {
    id: "BAN106",
    project_name: "Pavani Group",
    type: "Apartment",
    status: "Ongoing",
    location: "Kadugodi, Bangalore",
    price: " 1.20 cr to 1.46cr",
    size: "1050.00 sq.ft. - 1585.00 sq.ft. ",
    units: "1006 Units",
    bg_img: PavaniM1,
  },
  {
    id: "BAN107",
    project_name: "SNN Builder",
    type: "Apartment",
    status: "Ongoing",
    location: "Rachenalli, Bangalore",
    price: " 2.35 to 2.70 Cr",
    size: "700 sq. ft. -  2300 sq. ft.",
    units: "580 Units",
    bg_img: SNN_E1,
  },
  {
    id: "BAN108",
    project_name: "Purvankara",
    type: "Apartment",
    status: "Ongoing",
    location: "HOSAHALLI VILLAGE, Bangalore",
    price: " 1 cr to 2.05 cr",
    size: "1050 sq. ft. -  2216 sq. ft.",
    units: "438 Units",
    bg_img: PurvaC1,
  },
  {
    id: "BAN109",
    project_name: "Goyal & Co Hariyana group",
    type: "Apartment",
    status: "Ongoing",
    location: "Thanisndra main road, Bangalore",
    price: " 1.12cr to  1.60 Cr",
    size: "930 sq.ft. - 1675 sq.ft.",
    units: "663 Units",
    bg_img: OrchidS1,
  },
  {
    id: "BAN110",
    project_name: "Goyal & Co Hariyana group",
    type: "Apartment",
    status: "Ongoing",
    location: "Gunjur",
    price: "Starting From 1.20 Cr",
    size: "900 sq.ft. - 1800 sq.ft.",
    units: "772 Units",
    bg_img: OrchidN4,
  },
  {
    id: "BAN111",
    project_name: "Assetz Group",
    type: "Apartment",
    status: "Ongoing",
    location: "Whitefield",
    price: "Starting From 3 Cr",
    size: "1260 To 2300 Sqft",
    units: "96 Units",
    bg_img: AssetzOOK1,
  },
];
