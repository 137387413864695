import { Box, Typography } from "@mui/material";
import { Background1, TreezLogo, TreezLogo2 } from "../../Assets";
import { theme } from "../../Theme/theme";

const AuthContainer = ({ children, className, shwoTitle = false, title }) => {
  return (
    <Box sx={{ height: "100%", display: "flex" }} className={className}>
      <Box
        sx={{
          width: "35rem",
          height: "50rem",
          boxShadow: "0.2rem 0.2rem 1rem",
          borderRadius: "0.4rem",
          padding: "1rem",
          background: `linear-gradient(to right, #000000aa, ${theme.palette.secondary.main}bb),url(${Background1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",

          // margin: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default AuthContainer;
