import { createTheme, Typography, Button } from "@mui/material";
const COLORS = {
  // PRIMARY: '#a6594d',
  // SECONDARY: '#7fc55c',
  PRIMARY: "#7fc55c",
  SECONDARY: "#a52a2a",
  TEXT_PRIMARY: "#FFFFFF",
  TEXT_SECONDARY: "#DAA520",
  TEXT_DARK: "#1f223e",
  BACKGROUND_PRIMARY: "#FFFFFF",
  BACKGROUND_SECONDARY: "#000000",
  BACKGROUND_100: "#000000",
};
const FONT_FAMILY = "Cormorant";
const isMobile = window.innerWidth < 600;

const GLOBALSTYLES = {
  html: {
    fontSize: isMobile ? "9px" : window.innerWidth < 1024 ? "9px" : ".695vw",
  },
  body: {
    fontFamily: FONT_FAMILY,
  },
  ...{
    "*::-webkit-scrollbar": {
      width: "0.8em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: `${COLORS.PRIMARY} !important`,
      borderRadius: "0.5rem",
      border: "0.2em solid transparent",
      backgroundClip: "padding-box !important",
      // outline: '1px solid slategrey',
    },
    "*::-webkit-scrollbar-thumb:hover": {
      border: 0,
    },
    ".thin-scrollbar::-webkit-scrollbar": {
      width: "0.2em",
    },

    ".thin-scrollbar::-webkit-scrollbar-thumb": {
      backgroundColor: COLORS.PRIMARY,
      outline: "1px solid slategrey",
    },

    " input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: "0",
    },
    "input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: "0",
    },
  },
};

export const theme = createTheme({
  globalStyles: GLOBALSTYLES,
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    background: {
      main: COLORS.BACKGROUND_PRIMARY,
      dark: COLORS.BACKGROUND_SECONDARY,
      100: COLORS.BACKGROUND_100,
    },
    text: {
      main: COLORS.TEXT_PRIMARY,
      secondary: COLORS.TEXT_SECONDARY,
      dark: COLORS.TEXT_DARK,
    },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    color: COLORS.TEXT_DARK,
    allVariants: {
      color: COLORS.TEXT_DARK,
    },
    h1: {
      fontSize: isMobile ? "3.23rem" : "4.00rem",
    },
    h2: {
      fontSize: isMobile ? "2.5rem" : "2.93rem",
    },
    h3: {
      fontSize: "2.21rem",
    },
    h4: {
      fontSize: "1.90rem",
    },
    h5: {
      fontSize: "1.74rem",
    },
    h6: {
      fontSize: "1.49rem",
    },

    subtitle1: {
      fontSize: "1.19rem",
    },

    subtitle2: {
      fontSize: "1.26rem",
    },

    body1: {
      fontSize: "1.34rem",
    },

    body2: {
      fontSize: "1.42rem",
    },
    title1: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: "0.5rem",
          color: COLORS.TEXT_PRIMARY,
          padding: "0.5rem 2rem",
          "& svg": { "& path": { fill: COLORS.TEXT_PRIMARY } },
          "&:disabled": {
            opacity: "0.5",
            cursor: "not-allowed",
          },
          "&.MuiButton-sizeXsmall": {
            fontSize: "0.937rem",
          },
          "&.MuiButton-sizeSmall,&.MuiButton-sizeSm": {
            fontSize: "1rem",
          },
          "&.MuiButton-sizeMedium,&.MuiButton-sizeMd": {
            fontSize: "1.3rem",
          },
          "&.MuiButton-sizeLarge,&.MuiButton-sizeLg": {
            fontSize: "1.5rem",
          },
        },
      },
    },
  },
});
Typography.defaultProps = {
  component: "div",
  variant: "h6",
};
Button.defaultProps = {
  ...Button.defaultProps,
  size: "large",
};
