import {
  Autocomplete,
  InputAdornment,
  InputBase,
  TextField,
} from "@mui/material";
import useStyles from "./SearchBar.styles";
import { SearchIcon } from "../../Assets/index";

export function SearchBar({
  className = "",
  onChange,
  value,
  placeholder = "Search by Tag, Keyword",
  fullWidth = true,
  sx = {},
  end = false,
  onFocus,
  isAutoComplete = false,
  options = [1,2,3],
}) {
  const styles = useStyles();
  return (
    <>
      {!isAutoComplete ? (
        <InputBase
          className={`${styles.searchBox} ${className}`}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          startAdornment={
            !end && <SearchIcon style={{ marginRight: "10px" }} />
          }
          endAdornment={end && <SearchIcon style={{ marginLeft: "10px" }} />}
          fullWidth={fullWidth}
          sx={{ ...sx }}
          onFocus={onFocus}
        />
      ) : (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          sx={{ width: 300 }}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: styles.customInput,
              }}
            />
          )}
        />
      )}
    </>
  );
}
export default SearchBar;
