import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link as ReactLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.text.dark} !important`,
    // position: 'relative',
    // textAlign: 'center',
    "&:hover": {
      color: `${theme.palette.primary.main} !important`,
    },
    // '&:hover': {
    //   '&::after': {
    //     transition: 'all 0.2s linear',
    //     width: '6rem',
    //   },
    // },
    // '&::after': {
    //   position: 'absolute',
    //   background: theme.palette.primary.main,
    //   width: '0',
    //   content: "''",
    //   height: '0.2rem',
    //   display: 'block',
    //   left: '-0.8rem',
    // },
  },
  activeClass: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

export const Link = ({
  to,
  children,
  className,
  isActiveRequire = true,
  target = "",
}) => {
  const styles = useStyles();
  const isActive = window.location.pathname
    .split("/")
    .slice(1)
    .includes(to.split("/")[1]);


  return (
    <>
      <ReactLink
        to={to}
        className={`${className && className}`}
        target={target}
      >
        <Typography
          component={"span"}
          className={[
            isActiveRequire && isActive && styles.activeClass,
            styles.link,
          ]}
        >
          {children}
        </Typography>
      </ReactLink>
    </>
  );
};
