import React from "react";
import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { ErrorPageGhost } from "../Assets";
import { Box, Stack, Typography } from "@mui/material";
import Button from "../CommonComponent/Button/Button";
import { useNavigate } from "react-router-dom";

const EmptyComponent = ({ message }) => {
  const navigate = useNavigate();
  return (
    <AppLayout>
      <Stack
        gap={"2rem"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100vh - 10rem)"}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <img src={ErrorPageGhost} />
        <Typography variant="h1" textAlign={"center"}>
          {message ? message : "Oops! That page can't be found"}
        </Typography>
        <Button
          title="Back To Home"
          sx={{ marginTop: "3rem" }}
          onClick={() => navigate("/")}
        />
      </Stack>
    </AppLayout>
  );
};

export default EmptyComponent;
