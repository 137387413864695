import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30rem",
    height: "15rem",
    gap: "1rem",
    borderRadius: "1rem",
    boxShadow: "0 0 0.7rem",
    overflow: "hidden",
    margin: "1rem",
    background: theme.palette.background.main,
  },
  cardImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },
  parent: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));
