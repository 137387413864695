import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import { useStyles } from "./Header.styles";
import { CrossIcon, MenuBurgerIcon } from "../Assets/index";
import { useState } from "react";
import { Link } from "../CommonComponent/Link/Link";
import { theme } from "../Theme/theme";

const MobileHeader = () => {
  const styles = useStyles();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  return (
    <>
      <MenuBurgerIcon
        onClick={() => {
          setIsOpenDrawer(true);
        }}
        className={styles.burgerIcon}
      />
      <Drawer
        open={isOpenDrawer}
        anchor={"right"}
        PaperProps={{
          sx: {
            width: "70%",
            background: theme.palette.background.main,
          },
        }}
      >
        <LinkContainer isMobile={true} />
        <CrossIcon
          className={styles.crossIcon}
          onClick={() => {
            setIsOpenDrawer(false);
          }}
        />
      </Drawer>
    </>
  );
};
export default MobileHeader;

const LinkContainer = ({ isMobile }) => {
  const styles = useStyles();
  return (
    <Stack alignItems={"center"} className={` ${styles.linkContainer}`}>
      <Link to="/">HOME</Link>
      {/* <Link to='/gallery'>GALLERY</Link> */}
      <Link to="/about">ABOUT</Link>
      <Accordion classes={{ root: styles.accordion }}>
        <AccordionSummary>
          <Typography width={"100%"} textAlign={"center"}>
            SERVICES
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              textAlign: "center",
              padding: "2rem",
            }}
          >
            <Link to="/interior">INTERIOR</Link>
          </Typography>
          <Divider />
          <Typography
            sx={{
              textAlign: "center",
              padding: "2rem",
            }}
          >
            <Link to="/home-automation">HOME AUTOMATION</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Link to="/contacts">CONTACT</Link>
    </Stack>
  );
};
