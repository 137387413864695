import { Box, Typography, Stack, Divider } from "@mui/material";
import { useStyles } from "./ContentCard.styles";
import { Verfied_Account } from "../../Assets";
import { ScrollComponent } from "../../CommonComponent/ScrollComponent/ScrollComponent";

const ContentCard = ({
  contentData = { heading: "head", boldText: "Text", content: "content" },
}) => {
  const styles = useStyles();
  const { id, heading, content } = contentData;
  return (
    <Box
      key={id}
     className={styles.card}
    >
      <Typography variant="h3" className={styles.typoHeading}>
        <ScrollComponent> {heading}</ScrollComponent>
      </Typography>
      <Divider />
      <Box padding={"1rem"} />
      <Stack gap="0.5rem">
        {content.map((item) => (
          <Box style={{ display: "flex", gap: "1rem", flexDirection: "row" }}>
            <img src={Verfied_Account} className={styles.tickIcon} />
            <Typography variant="h6">{item}</Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default ContentCard;
