import {
  ModernSpaces1,
  NaturaDain1,
  Preethi1,
  SattvaLA1,
  AvaniA1,
  SLNN1,
  AlluriA1,
  KoingV1,
  Sriram1,
} from "../../../../Components/Assets";

export const Bangalore_villas_list_data = [
  {
    id: "BAN501",
    project_name: "Modern Space Villa",
    type: "Villas",
    status: "Ongoing",
    location: "Sompura Gate, Sarjapur Road, Bangalore",
    price: "3.30 cr* TO 4.20 cr*",
    size: "3387.00 sq.ft. - 4235.00 sq.ft. ",
    units: "266 Units ",
    bg_img: ModernSpaces1,
  },
  {
    id: "BAN502",
    project_name: "MANA Group Villa",
    type: "Villas",
    status: "Ongoing",
    location: "Bomasandra Sarjapur Road, Bangalore",
    price: "2.59 cr*",
    size: "3003 sqft TO 3700 sqft",
    units: "48 Units",
    bg_img: NaturaDain1,
  },
  {
    id: "BAN503",
    project_name: "Preethi Developer",
    type: "Villas",
    status: "Ongoing",
    location: "IVC Road, Bangalore",
    price: "1.98cr* - 2.90cr*",
    size: "2407 sqft TO 3800 sqft",
    units: "135 Units",
    bg_img: Preethi1,
  },
  {
    id: "BAN504",
    project_name: "Sattva Group",
    type: "Villas",
    status: "Ongoing",
    location: "Hennur Main Road, Bangalore",
    price: "5cr*",
    size: "3260 sqft",
    units: "63 Units",
    bg_img: SattvaLA1,
  },
  {
    id: "BAN505",
    project_name: "Avani Builder & NG developer",
    type: "Villas",
    status: "Ongoing",
    location: "Budigere cross, Bangalore",
    price: "2.33cr to 4.20cr*",
    size: "2490- 4945sq.ft",
    units: "333 Units",
    bg_img: AvaniA1,
  },
  {
    id: "BAN506",
    project_name: "SLN Group",
    type: "Villas",
    status: "Ongoing",
    location: "Budigere cross, Bangalore",
    price: "Price On Request",
    size: "2755 - 3380sq.ft",
    units: "83 Units",
    bg_img: SLNN1,
  },
  {
    id: "BAN507",
    project_name: "Shriram Properties",
    type: "Villas",
    status: "Ongoing",
    location: "Sarjapur",
    price: "Starting From 2 Cr",
    size: "2755 - 3380sq.ft",
    units: "108 Units",
    bg_img: Sriram1,
  },
  {
    id: "BAN508",
    project_name: "Koing Homes",
    type: "Villas",
    status: "Newly Launch",
    location: "Budigere",
    bg_img: KoingV1,
    message: "Contact us to get more details about this project",
    redirect: false,
  },
  {
    id: "BAN509",
    project_name: "Alluri Avani",
    type: "Villas",
    status: "Newly Launch",
    location: "Budigere",
    bg_img: AlluriA1,
    message: "Contact us to get more details about this project",
    redirect: false,
  },
];
