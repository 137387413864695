import {
  ModernSpaces1,
  ModernSpaces2,
  ModernSpaces3,
  ModernSpaces4,
  ModernSpaces5,
  ModernSpaces6,
  ModernSpaces7,
  ModernSpaces8,
  NaturaDain1,
  NaturaDain2,
  NaturaDain3,
  NaturaDain4,
  NaturaDain5,
  NaturaDain6,
  NaturaDain7,
  NaturaDain8,
  NaturaDain9,
  NaturaDain10,
  NaturaDain11,
  NaturaDain12,
  NaturaDain13,
  NaturaDain14,
  Preethi1,
  Preethi2,
  Preethi3,
  Preethi4,
  Preethi5,
  Preethi6,
  Preethi7,
  Preethi8,
  Preethi9,
  Preethi10,
  Preethi11,
  Preethi12,
  Preethi13,
  Preethi14,
  Preethi15,
  Preethi16,
  Preethi17,
  Preethi18,
  SattvaLA1,
  SattvaLA2,
  SattvaLA3,
  SattvaLA4,
  SattvaLA5,
  SattvaLA6,
  SattvaLA7,
  SattvaLA8,
  SattvaLA9,
  SattvaLA10,
  SattvaLA11,
  SattvaLA12,
  SattvaLA13,
  AvaniA1,
  AvaniA2,
  AvaniA3,
  AvaniA4,
  AvaniA5,
  AvaniA6,
  AvaniA7,
  AvaniA8,
  AvaniA9,
  AvaniA10,
  AvaniA11,
  AvaniA12,
  AvaniA13,
  AvaniA14,
  AvaniA15,
  AvaniA16,
  AvaniA17,
  AvaniA18,
  AvaniA19,
  AvaniA20,
  AvaniA21,
  SLNN1,
  SLNN2,
  SLNN3,
  SLNN4,
  SLNN5,
  SLNN6,
  Sriram1,
  Sriram2,
  Sriram3,
  Sriram4,
  Sriram5,
} from "../../../../Components/Assets";

export const Bangalore_villas_details_data = [
  {
    id: "BAN501",
    project_name: "Modern Space Villa",
    about:
      "Modern Spaaces recently launched a Villa project developed by Modern Spaaces. It is situated on Sarjapur Road, Bangalore.Soulace By Modern Spaaces spans an area of 26+ acres of land. The villas also have each side of wide spaces.With the view of gardens and trees surrounding the development, everyone feels that they are in touch with the nature.Where you are able to breathe in fresh air and feel joy everywhere around.Soulace Villa Bangalore situated in Sarjapur which is bounded by Ambalipura-Sarjapur and Kada Agrahara Road. This is well connected to IT hubs such as Whitefield as well as Electronic City . Additionally, the Hospitals as well as Schools and Shopping Complexes are accessible easily.The project has 4 and 5 BHK premium Villas which are constructed to provide the best living spaces to live in luxury. The apartments are designed with vaastu concepts with a focus on proper facings and measures, as well as space arrangement layouts, spatial geometrical. They are a perfect example of airflow thanks to strategically placed windows as well as spacious balconies with views of the beautiful gardens and trees.",

    project_details: [
      {
        name: "Project Location",
        value: "Sompura Gate, Sarjapur Road, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "26.39 Acres",
      },
      {
        name: "Number of Units",
        value: "266 Units",
      },
      {
        name: "Unit Variants",
        value: "3387.00 sq.ft. - 4235.00 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Sep. 2024 Onwards",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/308/PR/150223/005723",
      },
    ],
    project_price: [
      {
        unit: "4BHK",
        size: "3385 Sqft",
        price: "₹ 3.30 cr*",
      },
      {
        unit: " 5BHK",
        size: "4235 sqft",
        price: "₹ 4.20 cr*",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [
      ModernSpaces1,
      ModernSpaces2,
      ModernSpaces3,
      ModernSpaces4,
      ModernSpaces5,
      ModernSpaces6,
      ModernSpaces7,
      ModernSpaces8,
    ],
  },
  {
    id: "BAN502",
    project_name: "MANA Group",
    about:
      "Inspired by the tropical Daintree Rainforest of Australia, these Villas are spread over 10 acres of pristine greens. The project is divided into 2 Phases : Phase 1 has 61 villas spread over 6 acres and Phase 2 has 55 villas spread over 4 acres. The 4 BHK villa sizes range from 3100 sq ft to 3700 sq ft approximately (built up area). This project is developed by Mana Projects Pvt Ltd. Enjoy the luxury of 4 BHK Villas, with Double Height Courtyards & Private Backyard with a Garden.Experience a rare harmony of privacy, tranquility and sustainability. With 50% more open space, 200% more natural light & 300% more oxygen. Every villa has huge French windows, large bathrooms and walk in closets, and ceilings that are twice or thrice the average height. Living, dining and kitchen spaces flow seamlessly. Where environment friendly measures include reducing the carbon footprint, water & power consumption, by harnessing the power of sun & wind and rainwater harvesting. Homes that are naturally cooler in summer (courtesy turbo ventilators) and warmer in winter (courtesy thermal insulation tiles). The luxury amenities are a blend of sports, lifestyle and recreational, both for kids as well as adults. Natura Daintree sports an amphitheatre, party lawn, mega luxury club house, outdoor gym & working pods with cafetaria, golf putting, swimming pools, tennis court, yoga & meditation & hammock zone. At Daintree, Nature is everywhere, indoors and outdoors, so project exclusives include a herbal garden , butterfly garden and a pets corner. These Villas are located at Chambenahalli, Sarjapur Road, in close proximity to the IT hub, with prominent schools, malls, hospitals next door. A rare privilege to get closer to nature, closer home in the heart of today’s Bengaluru!",
    project_details: [
      {
        name: "Project Location",
        value: "Bomasandra Sarjapur Road, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "10 Acres (56% open)",
      },
      {
        name: "Number of Units",
        value: "48 Units",
      },

      {
        name: "Unit Variants",
        value: "3003 sqft TO 3700 sqft",
      },
      {
        name: "Possession Time",
        value: "Dec 2024 Onwards",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/308/PR/151222/005516|-1",
      },
    ],
    project_price: [
      {
        unit: "4BHK",
        size: "3003--3700 sqft",
        price: "₹ 2.59 cr*",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [
      NaturaDain1,
      NaturaDain2,
      NaturaDain3,
      NaturaDain4,
      NaturaDain5,
      NaturaDain6,
      NaturaDain7,
      NaturaDain8,
      NaturaDain9,
      NaturaDain10,
      NaturaDain11,
      NaturaDain12,
      NaturaDain13,
      NaturaDain14,
    ],
  },
  //need to in the list
  {
    id: "BAN503",
    project_name: "Preethi Developer",
    about:
      "Discover unparalleled luxury at Preeti Iksa Ville, offering stunning Mediterranean lakeside villas in North Bangalore just 20 minutes from Hebbal, located before the Airport Toll. Wake up in one of our  exquisite luxury villas in Bangalore, surrounded by lush greenery and calming lake views. As  dedicated villa developers in Bangalore, every detail at Preeti Iksa Ville is meticulously designed for your comfort. Whether you seek Villas near Bangalore Airport or a luxurious abode in North Bangalore,  Preeti Iksa Ville is your ideal choice. Book your dream villa now and experience the  epitome of sophisticated living.",
    project_details: [
      {
        name: "Project Location",
        value: "IVC Road, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "10 Acres",
      },
      {
        name: "Number of Units",
        value: "135 Units",
      },

      {
        name: "Unit Variants",
        value: "2407 - 3900 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Reday to move in",
      },
      {
        name: "RERA Approved",
        value: "PRM/ KA / RERA/1251/309/PR/ 171208/002253",
      },
    ],
    project_price: [
      {
        unit: "3BHK",
        size: "2407 sqft",
        price: "₹ 1.98cr*",
      },
      {
        unit: "4BHK",
        size: "3800 sqft",
        price: "₹ 2.90cr*",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [
      Preethi1,
      Preethi2,
      Preethi3,
      Preethi4,
      Preethi5,
      Preethi6,
      Preethi7,
      Preethi8,
      Preethi9,
      Preethi10,
      Preethi11,
      Preethi12,
      Preethi13,
      Preethi14,
      Preethi15,
      Preethi16,
      Preethi17,
      Preethi18,
    ],
  },
  {
    id: "BAN504",
    project_name: "Sattva Group",
    about:
      "Sattva , as the name suggests is located on the fast-developing Hennur Main Road, en-route to the Bangalore International Airport. If you ever dreamt of owning a home with a garden of your own – look no further!  These pre-launch stage in 4 BHK Row houses are just ideal in every sense! Sattva La Vita is a secure gated community of stylishly designed villas spread across 2 Acres of lush green land and located near to Manyata Tech Park. Designed for exclusivity and class, this super luxury project has 4 bedrooms and comes with a private basement, terrace, garden and a deck area. Sophisticated amenities include a swimming pool with Jacuzzi, well-equipped gymnasium, stylish multi-purpose halls, children’s play area, jogging track and a common area for other activities of outdoor recreation. Beautiful landscaping with perennial flowering plants add to your delight in this aesthetically designed residential community.  The best part – you get to have your own private garden, a deck and the provision for a private elevator. La Vita is luxurious living at its best, come own a dream Row House!",
    project_details: [
      {
        name: "Project Location",
        value: "Hennur Main Road, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "2.99 Acres ",
      },
      {
        name: "Number of Units",
        value: "63 Units",
      },

      {
        name: "Unit Variants",
        value: "3255 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "july 2026 ",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/446/PR/041223/006446",
      },
    ],
    project_price: [
      {
        unit: "4BHK",
        size: "3260 sqft",
        price: "₹ 5cr*",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [
      SattvaLA1,
      SattvaLA2,
      SattvaLA3,
      SattvaLA4,
      SattvaLA5,
      SattvaLA6,
      SattvaLA7,
      SattvaLA8,
      SattvaLA9,
      SattvaLA10,
      SattvaLA11,
      SattvaLA12,
      SattvaLA13,
    ],
  },
  {
    id: "BAN505",
    project_name: "Avani Builder & NG developer",
    about:
      " Avani Builder & NG developeroffers an exceptional residential experience through their luxurious 4 BHK villas in Whitefield which have been meticulously designed to maximize space utilization. The villas are surrounded by open greenery presenting residents with a serene and peaceful environment. The striking villas have been designed to offer a perfect blend of luxury, comfort, and nature.  With spacious balconies and terrace spaces the residents can enjoy stunning views of the lush greenery. The villas come with high-quality finishes and cutting-edge smart home technologies.",
    project_details: [
      {
        name: "Project Location",
        value: "Budigere cross, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "2 Acres",
      },
      {
        name: "Number of Units",
        value: "333 Units",
      },

      {
        name: "Unit Variants",
        value: "2490- 4945sq.ft",
      },
      {
        name: "Possession Time",
        value: "Dec 2026",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/446/PR/220223/005736",
      },
    ],
    project_price: [
      {
        unit: "4BHK",
        size: "2490 to 4945 sqft",
        price: "₹ 2.33cr to 4.20cr*",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [
      AvaniA1,
      AvaniA2,
      AvaniA3,
      AvaniA4,
      AvaniA5,
      AvaniA6,
      AvaniA7,
      AvaniA8,
      AvaniA9,
      AvaniA10,
      AvaniA11,
      AvaniA12,
      AvaniA13,
      AvaniA14,
      AvaniA15,
      AvaniA16,
      AvaniA17,
      AvaniA18,
      AvaniA19,
      AvaniA20,
      AvaniA21,
    ],
  },
  {
    id: "BAN506",
    project_name: "SLN Group",
    about:
      "This  is a lavish villa venture by Sln Builder coming up on Budigere Cross, East Bangalore.  The Nidhi Palms project in total Villas is 83 units and plots size Combination of 1200-1500 Sqft and SBA - Combination of 2700 - 3440 sq.ft appox. Spread over a sprawling 5 acres, in close proximity to the 1Km Away from Prestige Tranquility, is a land of diverse contours, all dressed in varied hues of green. 2 clubhouses and a host of community parks, this is not a place to build a mere home. It's a place to put down your roots for  generations to come, a place to build upon your legacy, and give it a place to call home. This project is highligts: Budigere Cross is a mid-budget locale in East Bangalore, hosting ample plots and villas by city-based builders,  About 3 km from Old Madras Road (NH-75) that offers sound connectivity with the rest of the city, Whitefield, a famous IT Hub in the city, is about 12 km via  Whitefield-Hoskote Road, NH-75 connects this locale to one of the key industrial clusters of the city, Hoodi, which is about 13 km from Sln Nidhi Palms,  Nearest metro station is Whitefield on the Purple Line, which is about 10 km from Budigere Cross, Frequent traffic congestion during peak-hour is a common  issue in several parts of the locale, Residents face difficulties due to limited availability of public transport here. ",
    project_details: [
      {
        name: "Project Location",
        value: "Budigere cross, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "5 Acres",
      },
      {
        name: "Number of Units",
        value: "83 Units",
      },

      {
        name: "Unit Variants",
        value: "2755 - 3380sq.ft",
      },
      {
        name: "Possession Time",
        value: "Dec, 2029",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/446/PR/281223/006513",
      },
    ],
    project_price: [
      {
        unit: "4BHK",
        size: "2700 - 3440 Sqft",
        price: "Price On Request",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [SLNN1, SLNN2, SLNN3, SLNN4, SLNN5, SLNN6],
  },
  {
    id: "BAN507",
    project_name: "Shriram Properties",
    about:
      "Shriram Properties represented a once-in-a-lifetime opportunity to own your luxurious dream home in a community of only 217 independent row villas for exclusive families like yours. These boutique row villas in Sarjapur offer an unrivalled lifestyle in a tranquil neighbourhood and are well-connected for all your needs, allowing you to live the lifestyle of your dreams in a private community. ",
    project_details: [
      {
        name: "Project Location",
        value: "Sarjapur",
      },
      {
        name: "Total Land Area",
        value: "12 Acres",
      },
      {
        name: "Number of Units",
        value: "108 Units",
      },

      {
        name: "Unit Variants",
        value: "3/4 BHK Row Villa Houses",
      },

      {
        name: "RERA Approved",
        value:
          "PRM/KA/RERA/1251/308/PR/200226/003310 & PRM/KA/RERA/1251/308/PR/210902/004291",
      },
    ],

    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [Sriram1, Sriram2, Sriram3, Sriram4, Sriram5],
  },
  //no data
  {
    id: "BAN508",
    project_name: "Koing Homes",
    about:
      "Shriram Properties represented a once-in-a-lifetime opportunity to own your luxurious dream home in a community of only 217 independent row villas for exclusive families like yours. These boutique row villas in Sarjapur offer an unrivalled lifestyle in a tranquil neighbourhood and are well-connected for all your needs, allowing you to live the lifestyle of your dreams in a private community. ",
    project_details: [
      {
        name: "Project Location",
        value: "Budigere",
      },
    ],
    showDetails: false,
  },
  {
    id: "BAN509",
    project_name: "Alluri Avani",
    about:
      "Shriram Properties represented a once-in-a-lifetime opportunity to own your luxurious dream home in a community of only 217 independent row villas for exclusive families like yours. These boutique row villas in Sarjapur offer an unrivalled lifestyle in a tranquil neighbourhood and are well-connected for all your needs, allowing you to live the lifestyle of your dreams in a private community. ",
    project_details: [
      {
        name: "Project Location",
        value: "Budigere",
      },
    ],
    showDetails: false,
  },
];
