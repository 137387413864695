import { useStyles } from "../ProjectDetails.styles";
import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { getAmentiesData } from "../../../../Utils/utils-function";

export const Amenities = ({ data }) => {
  const styles = useStyles();

  return (
    <Stack className={`${styles.box}`} gap="1rem">
      <Typography variant="h2">Amenities</Typography>
      <Divider />
      <Typography variant="h5">
        Enjoy a life of action and health with our thoughtfully curated
        international-standard amenities that will get you up and going. We
        appreciate your paticipation! Get the full amenities after filling the
        form.
      </Typography>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-evenly"}
        gap="8rem"
        p="2rem 5rem"
      >
        {data?.map((item) => {
          let { Icon, title } = getAmentiesData(item);

          return (
            <Stack
              sx={{
                width: "10rem",
                justifyContent: "center",
                alignItems: "center",
                "& img": {
                  width: "6rem",
                },
              }}
            >
              <img src={Icon} />
              <Typography>{title}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
