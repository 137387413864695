import { Box, Divider, Stack, Typography } from "@mui/material";
import { useStyles } from "./ImageSlider.styles";
import {
  Apartment1,
  Background1,
  Background2,
  Cloud,
  Commercial1,
  Commercial2,
  Contact_Page_Img,
  Doors,
  Farmlands1,
} from "../../Assets/index";
import { Carousel } from "../Carousel/Carousel";
import { useMobileView } from "../Hooks/useMobileView/useMobileView";
import { theme } from "../../Theme/theme";

import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

const ImgContent = [
  {
    id: 0,
    title: "WELCOME TO TREEZ ENTERPRISE",
    subTitle:
      "We are delighted to introduce you to our world of exceptional real estate services. Whether you are a seasoned property investor, a first-time homebuyer, or someone exploring commercial real estate opportunities, we are here to guide you through every step of your real estate journey.",
    bg_img: Apartment1,
  },
  {
    id: 1,
    title: "WELCOME TO TREEZ ENTERPRISE",
    subTitle:
      "Discover a diverse range of residential and commercial properties tailored to meet your unique needs. Our extensive property portfolio showcases a variety of options, from cozy family homes to prime commercial spaces.",
    bg_img: Background2,
  },
  {
    id: 2,
    title: "WELCOME TO TREEZ ENTERPRISE",
    subTitle:
      "Our team of experienced real estate professionals possesses in-depth knowledge of local markets. We understand the intricacies of neighborhoods, property values, and market trends, ensuring that you receive well-informed guidance for your real estate decisions.",
    bg_img: Farmlands1,
  },
  {
    id: 3,
    title: "WELCOME TO TREEZ ENTERPRISE",
    subTitle:
      "Your goals and preferences are at the forefront of our services. Our dedicated team is committed to providing personalized consultation to help you make informed decisions that align with your aspirations.",
    bg_img: Commercial2,
  },
];

export const ImageSlider = ({ data = ImgContent, isLight = false }) => {
  const isMobile = useMobileView();
  return <Slider imgData={data} isLight={isLight} />;
};

const Component = ({ data }) => {
  const styles = useStyles();
  const { id, title, subTitle, bg_img } = data;
  return (
    <Box
      className={styles.container}
      key={id}
      sx={{
        background: `linear-gradient(180deg,rgba(0,66,116,-4.65) 0%,${theme.palette.background.dark} 100%),url(${bg_img})`,
      }}
    >
      <Box className={styles.content}>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="h4" mt={"2rem"}>
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const Slider = ({ imgData, isLight }) => {
  const styles = useStyles();

  const [[page, direction], setPage] = useState([0, 0]);

  const imageIndex = wrap(0, imgData.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  React.useEffect(() => {
    let interval = setInterval(() => setPage([page + 1, 1]), 4000);
    return () => clearInterval(interval);
  }, [page]);
  return (
    <Box sx={{ height: "50rem" }} className={styles.exampleContainer}>
      {!isLight && (
        <Box
          sx={{
            width: "100vw",
            height: "50rem",
            background: "#00000080",
            zIndex: 2,
          }}
        ></Box>
      )}
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={page}
          src={imgData[imageIndex]?.bg_img}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      <Box className={styles.nextBtn} onClick={() => paginate(1)}>
        {"‣"}
      </Box>
      <Box className={styles.prevBtn} onClick={() => paginate(-1)}>
        {"‣"}
      </Box>

      <Box className={styles.content} key={imgData[imageIndex]?.id}>
        <Typography variant="h1" textAlign={"center"}>
          {imgData[imageIndex]?.title}
        </Typography>
        <Typography
          variant="h2"
          mt={"2rem"}
          textAlign={"center"}
          className={styles.title}
        >
          {imgData[imageIndex]?.subTitle}
        </Typography>
      </Box>
    </Box>
  );
};
