import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "../ProjectDetails.styles";

const BrochureDownload = ({ brochure }) => {
  const styles = useStyles();

  return (
    <Stack gap={"1rem"} sx={{}} className={`${styles.box}`}>
      <Typography variant="h2">{brochure?.project_name} Brochure</Typography>
      <Divider />
      <Typography variant="h6">{brochure?.description}</Typography>
    </Stack>
  );
};

export default BrochureDownload;
