import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AppLayout from "../../CommonComponent/AppLayout/AppLayout";
import { useStyles } from "./ProjectDetails.styles";
import { AboutProject } from "./component/AboutProject";
import { WhyShouldSection } from "./component/WhyShouldSection";
import { WhyToInvest } from "./component/WhyToInvest";
import { Amenities } from "./component/Aminities";
import { Specifications } from "./component/Specification";
import { ProjectImage } from "./component/ProjectImage";
import { ProjectTable } from "./component/ProjectTable";
import { projectDetailsData } from "../../../Utils/Data/ProjectDetailsData";
import { useNavigate, useParams } from "react-router-dom";
import ProjectDetailsCard from "./ProjectDetailsCard/ProjectDetailsCard";
import { ScrollComponent } from "../../CommonComponent/ScrollComponent/ScrollComponent";
import { allProjectDetailsList } from "../../../Utils/utils-constant";
import BrochureDownload from "./component/BrochureDownload";
import EmptyComponent from "../../EmptyComponent/EmptyComponent";

const ProjectDetails = () => {
  const styles = useStyles();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const data =
    allProjectDetailsList?.find((project) => project?.id == projectId) || {};

  const {
    about,
    project_details,
    project_price,
    amenities,
    brochure,
    project_images,
    showDetails,
  } = data;
  useEffect(() => {
    if (!(showDetails ?? true)) {
      navigate("/");
    }
  }, [data]);

  if (!data?.id) {
    return <EmptyComponent />;
  }

  return (
    <>
      <ProjectDetailsCard data={data} />
      <AppLayout isEnquiryForm>
        <Stack gap="3rem" className={styles.container}>
          {about && (
            <ScrollComponent variant="slide_left">
              <AboutProject
                aboutData={{
                  project_name: data?.project_name,
                  description: data?.about,
                }}
              />
            </ScrollComponent>
          )}
          {/* <ScrollComponent>
            <WhyShouldSection
              data={{
                project_name: data?.project_name,
                ...data?.why_to_consider,
              }}
            />
          </ScrollComponent> */}
          {/* <ScrollComponent>
            <WhyToInvest />
          </ScrollComponent> */}
          {project_details && (
            <ScrollComponent variant="slide_left">
              <ProjectTable
                data={data?.project_details}
                title={"Project Details"}
              />
            </ScrollComponent>
          )}
          {project_price && (
            <ScrollComponent>
              <ProjectTable
                data={data?.project_price}
                title={"Project Prices"}
                isHeader
              />
            </ScrollComponent>
          )}
          {amenities && (
            <ScrollComponent>
              <Amenities data={data?.amenities} />
            </ScrollComponent>
          )}
          {brochure && (
            <ScrollComponent>
              <BrochureDownload data={data?.brochure} />
            </ScrollComponent>
          )}

          {/* <ScrollComponent>
            <Specifications data={data?.specification} />
          </ScrollComponent> */}
          {project_images && (
            <ScrollComponent>
              <ProjectImage data={data?.project_images} />
            </ScrollComponent>
          )}
        </Stack>
      </AppLayout>
    </>
  );
};

export default ProjectDetails;
