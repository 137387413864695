import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    height: "50rem",
    width: "35rem",
    // background: `linear-gradient(${theme.palette.text.dark},${theme.palette.primary.main})`,
    borderRadius: "2rem",
    position: "relative",
    boxSizing: "border-box",
    padding: "1rem",
    cursor: "pointer",
    boxShadow: "0.3rem 0.3rem 1rem",
    overflow: "hidden",

    "&:hover": {
      "&>img": {
        transform: "scale(1.1)",
      },
      "& $name": {
        visibility: "hidden",
        transition: "all 0.11s linear",
      },
      "& $details": {
        background: "#00000050",
        position: "absolute",
        height: "inherit",
        width: "inherit",
        top: 0,
      },
      "& $title": {
        marginTop: "0 !important",

        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        width: "100%",
      },
    },
    "&>img": {
      position: "absolute",
      bottom: "0",
      height: "100%",
      transition: "all 0.4s linear",
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.main,
    },
  },
  details: {},
  name: {
    marginTop: "3rem !important",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  title: {
    marginTop: "3rem !important",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    flexDirection: "column",
    position: "relative",
    zIndex: "2",
  },
  arrowIcon: {
    height: "2rem",
    width: "2rem",
    borderRadius: "50%",
    padding: "0.3rem",
    background: "#1c7cbbaa",

    "& path": {
      stroke: `${theme.palette.text.main}!important`,
    },
  },
}));
