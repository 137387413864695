import {
  DNRGroup1,
  DNRGroup2,
  DNRGroup3,
  DNRGroup4,
  DNRGroup5,
  DNRGroup6,
  DNRGroup7,
  DNRGroup8,
  DNRGroup9,
  Spectra1,
  Spectra2,
  Spectra3,
  Spectra4,
  Spectra5,
  Spectra6,
  Spectra7,
  Spectra8,
  Spectra9,
  Spectra10,
  Spectra11,
  Spectra12,
  Spectra13,
  Spectra14,
  Spectra15,
  Spectra16,
  PrestigeS1,
  PrestigeS2,
  PrestigeS3,
  PrestigeS4,
  GodrejB1,
  GodrejB2,
  GodrejB3,
  GodrejB4,
  GodrejB5,
  GodrejB6,
  SvamithraS1,
  SvamithraS2,
  SvamithraS3,
  SvamithraS4,
  SvamithraS5,
  SvamithraS6,
  SvamithraS7,
  SvamithraS8,
  SvamithraS10,
  SvamithraS11,
  SvamithraS13,
  SvamithraS14,
  SvamithraS15,
  SvamithraS16,
  SvamithraS17,
  SvamithraS18,
  SvamithraS19,
  PavaniM1,
  PavaniM2,
  PavaniM3,
  PavaniM4,
  PavaniM5,
  PavaniM6,
  PavaniM7,
  PavaniM8,
  PavaniM9,
  PavaniM10,
  PavaniM11,
  PavaniM12,
  PavaniM13,
  PavaniM14,
  PavaniM15,
  PavaniM16,
  PavaniM17,
  PavaniM18,
  PavaniM19,
  PavaniM20,
  PavaniM21,
  PavaniM22,
  PavaniM23,
  PavaniM24,
  PavaniM25,
  PavaniM26,
  PavaniM27,
  SNN_E1,
  SNN_E2,
  SNN_E3,
  SNN_E4,
  SNN_E5,
  SNN_E6,
  SNN_E7,
  PurvaC1,
  PurvaC2,
  PurvaC3,
  PurvaC4,
  PurvaC5,
  PurvaC6,
  OrchidS1,
  OrchidS3,
  OrchidS4,
  OrchidS5,
  OrchidS6,
  OrchidS7,
  OrchidS8,
  OrchidS9,
  OrchidS10,
  OrchidS11,
  OrchidS13,
  OrchidS14,
  OrchidS15,
  OrchidS16,
  OrchidS17,
  OrchidS18,
  OrchidS19,
  OrchidS20,
  AssetzOOK1,
  AssetzOOK3,
  AssetzOOK2,
  AssetzOOK4,
  OrchidN1,
  OrchidN2,
  OrchidN3,
  OrchidN4,
  OrchidN5,
  OrchidN6,
} from "../../../../Components/Assets";

export const Bangalore_apartment_details_data = [
  {
    id: "BAN101",
    project_name: "DNR Group Luxurious Apartment",
    about:
      "DNR Group represemted a amazing project which strategically located at Marathahalli-Sarjapur Outer Ring Road, Banglore is defined by distinct Green Architecture that works in harmony with the natural features and resources to maximize the efficiency of the building. Besides the unique features, DNR Group also promises a host of other facilities which add to the hybrid experience.",

    project_details: [
      {
        name: "Project Location",
        value: "Bellandur, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "4.3 Acres",
      },
      {
        name: "Number of Units",
        value: "241 Units",
      },
      {
        name: "Towers and Blocks",
        value: "B + G + 12 Floors, 8 Towers",
      },
      {
        name: "Unit Variants",
        value: "1260 to 2300 Sqft",
      },
      {
        name: "Possession Time",
        value: "Dec 2024 Onwards",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1250/303/PR/0411 23/006373",
      },
    ],

    project_price: [
      {
        unit: "2BHK",
        size: "1260 Sqft",
        price: "₹ 1.66 Cr*",
      },
      {
        unit: " 3BHK",
        size: "1665sqft To  1685sqft ",
        price: "₹ 2.32Cr - 2.50 Cr*",
      },
    ],

    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],

    project_images: [
      DNRGroup1,
      DNRGroup2,
      DNRGroup3,
      DNRGroup4,
      DNRGroup5,
      DNRGroup6,
      DNRGroup7,
      DNRGroup8,
      DNRGroup9,
    ],
  },
  {
    id: "BAN102",
    project_name: "Spectra Construction Luxurious Apartment",
    about:
      "Located in the heart of Whitefield, Bengaluru, Spectra Construction is The Best of Everything. These 3 BHK Ultra-Luxury Residential Apartments are designed with minimum common walls to ensure your privacy, ample storage spaces for your every need, expansive uninterrupted views of the cityscape and landscape to help you rejuvenate in natural light and fresh air all while ensuring lush green spaces and amenities to connect with your loved ones and community. An epitome of luxury that ensures a lifestyle that one can only dream of.",

    project_details: [
      {
        name: "Project Location",
        value: "Kundhanalli, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "2 Acres",
      },
      {
        name: "Number of Units",
        value: "140 Units",
      },
      {
        name: "Towers and Blocks",
        value: "Towers/block - 3 Buildings ",
      },
      {
        name: "Unit Variants",
        value: "1740.00 sq.ft. - 1760.00 sq.ft ",
      },
      {
        name: "Possession Time",
        value: "Aug, 2028 Onwards",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/446/PR/251023/006346",
      },
    ],
    project_price: [
      {
        unit: "3BHK",
        size: "1740 to 1760 Sqft",
        price: "₹ 2.05 To 2.15Cr*",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [
      Spectra1,
      Spectra2,
      Spectra3,
      Spectra4,
      Spectra5,
      Spectra6,
      Spectra7,
      Spectra8,
      Spectra9,
      Spectra10,
      Spectra11,
      Spectra12,
      Spectra13,
      Spectra14,
      Spectra15,
      Spectra16,
    ],
  },
  {
    id: "BAN103",
    project_name: "Presitge Group",
    about:
      "Prestige Group represented a new residential project situated in Varthur Road, Whitefield. This development spans across 6 acres and comprises 306 units housed within 2 towers. The project offers a variety of apartment sizes, including 2, 3, and 4 BHK configurations.",

    project_details: [
      {
        name: "Project Location",
        value: "Ramgondalli, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "6 Acres",
      },
      {
        name: "Number of Units",
        value: "306 Units",
      },
      {
        name: "Towers and Blocks",
        value: "2 Buildings ",
      },
      {
        name: "Unit Variants",
        value: "1100.00 sq.ft. - 2700.00 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Dec, 2027 ",
      },
      {
        name: "RERA Approved",
        value: "Awating",
      },
    ],
    project_price: [
      {
        unit: "2BHK",
        size: "1100 to 1200 Sqft",
        price: "Price On Request",
      },
      {
        unit: "3BHK",
        size: "1685 to 1800 Sqft",
        price: "Price On Request",
      },
      {
        unit: "4BHK",
        size: "2595 to 2700 Sqft",
        price: "Price On Request",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [PrestigeS1, PrestigeS2, PrestigeS3, PrestigeS4],
  },
  {
    id: "BAN104",
    project_name: "Godrej Group",
    about:
      "Godrej Groups represented an example of modernity nestled in the bustling neighbourhood of Budigere Cross, near Whitefield, Bangalore. It provides 2/3 BHK premium apartments with all types of amazing facilities and amenities. With a captivating fusion of contemporary design and eco-friendly principles, this radiant residential community sets new standards for luxurious yet environmentally conscious living. It has the essence of traditional Indian aesthetics with modern functionality, featuring elegant lines and subtle curves that seamlessly integrate with the natural landscape. The facade, resplendent with floor-to-ceiling glass windows, allows an abundance of natural light to flood the interiors, creating a harmonious blend of indoor and outdoor living.",
    project_details: [
      {
        name: "Project Location",
        value: "Budgeeri Corss, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "28 Acres",
      },
      {
        name: "Number of Units",
        value: "1075 Units",
      },
      {
        name: "Towers and Blocks",
        value: "3B + G  + 39 Floors & 7 Towers  ",
      },
      {
        name: "Unit Variants",
        value: "1285.00 sq.ft. - 2025.00 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Jan, 2028 ",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/446/AG",
      },
    ],
    project_price: [
      {
        unit: "2BHK",
        size: "1285 Sqft",
        price: "Price On Request",
      },
      {
        unit: "3BHK",
        size: " 1700sqft -2025sqft",
        price: "Price On Request",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [
      GodrejB1,
      GodrejB2,
      GodrejB3,
      GodrejB4,
      GodrejB5,
      GodrejB6,
    ],
  },
  {
    id: "BAN105",
    project_name: "Svamitva Infra",
    about:
      "Svamitva Infra designed spacious 2,3 and 3.5 BHK Apartments and Pent house. The project is positioned as a luxury wellness based high end project. An investment here will not only upgrade or elevate your lifestyle but will also give you perks of Luxurious wellness. The apartment has been designed keeping the 5 states of wellness in mind – physical, mental, emotional, spiritual and social. The spaces have been carefully planned to give a holistic experience to residents and visitors alike. Each apartment has the living space opening out to a balcony for allowing the fresh breeze inside the house. The apartment has large green trees at various levels, increasing the biodiversity vertically along the height of the building. A large clubhouse has been interestingly spilled along the length of the apartments to activate the space on the ground floor and encourage the community bonding. The external facade also has a variation to break the monotony and increase aesthetic appeal.",
    project_details: [
      {
        name: "Project Location",
        value: "HSR Layout, Bangalore",
      },
      {
        name: "Total Land Area",
        value: " 4.27 Acres ",
      },
      {
        name: "Number of Units",
        value: "495 Units",
      },
      {
        name: "Towers and Blocks",
        value: "5 Towers",
      },
      {
        name: "Unit Variants",
        value: "889.00 sq.ft. - 1409.00 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Dec, 2028 ",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/310/PR/220223/005735",
      },
    ],
    project_price: [
      {
        unit: "3BHK",
        size: " 1637 to 1773sqft",
        price: " 2.35 to 2.70 Cr",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [
      SvamithraS1,
      SvamithraS2,
      SvamithraS3,
      SvamithraS4,
      SvamithraS5,
      SvamithraS6,
      SvamithraS7,
      SvamithraS8,
      SvamithraS10,
      SvamithraS11,
      SvamithraS13,
      SvamithraS14,
      SvamithraS15,
      SvamithraS16,
      SvamithraS17,
      SvamithraS18,
      SvamithraS19,
    ],
  },
  {
    id: "BAN106",
    project_name: "Pavani Group",
    about:
      "Pavani Group provides 1, 2, and 3 BHK Apartments that have been exquisitely created to provide you with the best living spaces. Vaastu principles are used in the design of the flats to ensure proper facings, measurements, space arrangements, layouts, and spatial geometry. With strategically positioned windows and extensive balcony spaces overlooking the magnificent environment of outside greenery, the flats exhibit excellent ventilation. The project has covered a total land of 13.41 acres and offers more than 1006 units to call home. Pavani Group's Layout has huge clubhouses. There are well-equipped amenities for a trendy, metropolitan social life. They provide world-class amenities such as party lawns, a crèche, spas, and enormous swimming pools, in addition to athletic facilities, gyms, and a massive clubhouse.",
    project_details: [
      {
        name: "Project Location",
        value: "Kadugodi, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "13.41 Acres (79% open) ",
      },
      {
        name: "Number of Units",
        value: "1006 Units",
      },
      {
        name: "Towers and Blocks",
        value: "6 Buildings",
      },
      {
        name: "Unit Variants",
        value: "1050.00 sq.ft. - 1585.00 sq ",
      },
      {
        name: "Possession Time",
        value: "Dec, 2025",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/446/PR/120922/005235",
      },
    ],
    project_price: [
      {
        unit: "2BHK",
        size: "1050 to 1230sqft",
        price: "1.20Cr OnWards",
      },
      {
        unit: "2.5BHK",
        size: "1260 TO 1380sqft ",
        price: "1.25 to 1.40 Cr",
      },
      {
        unit: "2BHK",
        size: "1335 to 1585sqft",
        price: "1.40 cr to 1.46cr",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [
      PavaniM1,
      PavaniM2,
      PavaniM3,
      PavaniM4,
      PavaniM5,
      PavaniM6,
      PavaniM7,
      PavaniM8,
      PavaniM9,
      PavaniM10,
      PavaniM11,
      PavaniM12,
      PavaniM13,
      PavaniM14,
      PavaniM15,
      PavaniM16,
      PavaniM17,
      PavaniM18,
      PavaniM19,
      PavaniM20,
      PavaniM21,
      PavaniM22,
      PavaniM23,
      PavaniM24,
      PavaniM25,
      PavaniM26,
      PavaniM27,
    ],
  },
  {
    id: "BAN107",
    project_name: "SNN Builder",
    about:
      "SNN Builder represented a luxurious apartment which is located in Rachenahalli, Thanisandra, Bengaluru, Karnataka- 560064.Thanisandra is a rapidly developing locality in the northern part of Bangalore. The locality has witnessed a significant increase in property registrations in recent months, indicating its growing popularity and development. The area is well-connected by major roads, including Thanisandra Main Road, Outer Ring Road, KV Jairam Road, and Kempapura Main Road, making it accessible from different parts of the city. SNN Felicity capitalizes on these benefits, offering a prime location in Thanisandra, Bangalore.",
    project_details: [
      {
        name: "Project Location",
        value: "Rachenalli, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "6.50 Acres",
      },
      {
        name: "Number of Units",
        value: "580 Units",
      },
      {
        name: "Towers and Blocks",
        value: "G + 13 Floors 9 Buildings",
      },
      {
        name: "Unit Variants",
        value: "700 sq. ft. -  2300 sq. ft.",
      },
      {
        name: "Possession Time",
        value: "December 2028 ",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/310/PR/220223/005735",
      },
    ],
    project_price: [
      {
        unit: "1.5BHK",
        size: "700 TO 715 sqft ",
        price: "75LKS TO 80LKS",
      },
      {
        unit: "2BHK",
        size: "1055 to 1120 sqft",
        price: "1.06 TO 1.11 Cr",
      },
      {
        unit: "2.5BHK",
        size: "1215 sqft",
        price: "1.18 Cr",
      },
      {
        unit: "3BHK",
        size: "1380 sqft",
        price: " 1.51 Cr",
      },
      {
        unit: "4BHK",
        size: "1890 to 2300 sqft",
        price: "1.75 to 2.04 Cr",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [SNN_E1, SNN_E2, SNN_E3, SNN_E4, SNN_E5, SNN_E6, SNN_E7],
  },

  //need to add below data in list page
  {
    id: "BAN108",
    project_name: "Purvankara",
    about:
      "Purva Group is the new premium residential Apartment project launched in Hosahalli, Airport Road, Bangalore.The residential enclave, Purva Celestial at Purva Zenium is a part of the 150-acre Dreamland and features the very best in Puravankara’s luxury segment. The project offers spacious 2,3BHK apartments with luxurious features. Purva Group is based and conceptualized around thoughtfully designed homes with superior efficiency and the principle of “Technology meeting Organic and Sustainable living“. It is a first-of-its-kind venture being undertaken by Puravankara Some of the 25+ Amenities at Purva Celestial include a fully equipped 14000 sft Oxygenated Clubhouse, landscaped gardens, gymnasium, swimming pool, recreation rooms, outdoor sports courts, children’s play area, party hall, and meticulously planned with utmost importance for state-of-the-art 24/7 securities.",
    project_details: [
      {
        name: "Project Location",
        value: "HOSAHALLI VILLAGE, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "2.97 Acres",
      },
      {
        name: "Number of Units",
        value: "438 Units",
      },
      {
        name: "Towers and Blocks",
        value: "8 Buildings",
      },
      {
        name: "Unit Variants",
        value: "1041 - 1725sq. ft.",
      },
      {
        name: "Possession Time",
        value: "Jun, 2027",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/309/PR/071022/005303 ",
      },
    ],
    project_price: [
      {
        unit: "2BHK",
        size: "1050 - 1116 sqft",
        price: "1cr",
      },

      {
        unit: "4BHK",
        size: "2216 sqft",
        price: "2.05cr",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    //update this image with actual image
    project_images: [PurvaC1, PurvaC2, PurvaC3, PurvaC4, PurvaC5, PurvaC6],
  },
  {
    id: "BAN109",
    project_name: "Goyal & Co Hariyana group",
    about:
      "Orchid Salisbury by Goyal and Co. luxury apartment , are 2 BHK, 3 BHK + 2T, 3 BHK Apartments that exude the essence of present-day extravagance. As one steps inside, it extends a compelling invitation to immerse oneself in a distinct fusion of contemporary luxury at Thanisandra, Bangalore. The builder is guaranteed to bring a quality living experience to the community of Thanisandra Road, North Bangalore with brilliant architecture and an equivalent lifestyle in Orchid Salisbury. Some of the Amenities at Orchid Salisbury include a fully equipped clubhouse, landscaped gardens, gymnasium, swimming pool, recreation rooms, outdoor sports courts, children’s play area, party hall, and meticulously planned with utmost importance to state-of-the-art 24/7 securities.",
    project_details: [
      {
        name: "Project Location",
        value: "Thanisndra main road, Bangalore",
      },
      {
        name: "Total Land Area",
        value: "6.07 Acres ",
      },
      {
        name: "Number of Units",
        value: "663 Units",
      },
      {
        name: "Towers and Blocks",
        value: "7  Buildings",
      },
      {
        name: "Unit Variants",
        value: "930.00 sq.ft. - 1675.00 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Dec, 2028",
      },
      {
        name: "RERA Approved",
        value: "PRM/KA/RERA/1251/309/PR/271123/006440",
      },
    ],
    project_price: [
      {
        unit: "2BHK",
        size: "900 - 1200 sqft",
        price: "1.12cr to 1.17 cr",
      },

      {
        unit: "3BHK",
        size: "1400-1700 sqft",
        price: "1.35 to 1.60 cr",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    //update this image with actual image
    project_images: [
      OrchidS1,
      OrchidS3,
      OrchidS4,
      OrchidS5,
      OrchidS6,
      OrchidS7,
      OrchidS8,
      OrchidS9,
      OrchidS10,
      OrchidS11,
      OrchidS13,
      OrchidS14,
      OrchidS15,
      OrchidS16,
      OrchidS17,
      OrchidS18,
      OrchidS19,
      OrchidS20,
    ],
  },
  {
    id: "BAN110",
    project_name: "Goyal & Co Hariyana group",
    about:
      "Goyal & Co Hariyana Group represented a new residential high-rise apartment project nestled in the vibrant locale of Gunjur, Varthur, within the burgeoning landscape of East Bangalore. This meticulously crafted project offers an array of 2 and 3 BHK apartments that blend modernity with comfort, aiming to cater to the diverse needs and preferences of its prospective residents. Orchid Life, an expansive residential project, is gracefully situated across 7.9 acres of lush land. Comprising a total of 772 units distributed across 5 towers, this development boasts an architectural marvel with a structure encompassing 2 basements, a ground floor, and 25 floors. ",
    project_details: [
      {
        name: "Project Location",
        value: "Gunjur",
      },
      {
        name: "Total Land Area",
        value: "7.9 Acres",
      },
      {
        name: "Number of Units",
        value: "772 Units",
      },
      {
        name: "Towers and Blocks",
        value: "2B+G+24/25 & 5 Towers",
      },
      {
        name: "Unit Variants",
        value: " 900.00 sq.ft. - 1800.00 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Feb, 2030",
      },
      {
        name: "RERA Approved",
        value: "Approved",
      },
    ],
    project_price: [
      {
        unit: "2BHK",
        size: "900sqft - 1200sqft",
        price: "Starting From 1.20 Cr",
      },

      {
        unit: "3BHK",
        size: "1400sqft - 1800sqft",
        price: "Price on Request",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    //update this image with actual image
    project_images: [
      OrchidN1,
      OrchidN2,
      OrchidN3,
      OrchidN4,
      OrchidN5,
      OrchidN6,
    ],
  },
  {
    id: "BAN111",
    project_name: "Assetz Group",
    about:
      "Assetz group represent a amazing project at Whitefield offers a range of luxurious residences to cater to different preferences and needs. Whether you desire a cozy 2BHK apartment or a spacious 4BHK penthouse, there's something for everyone. The interiors are designed with meticulous attention to detail, showcasing a blend of functionality and aesthetics. High-quality fittings and finishes add to the overall elegance of each unit. This project goes the extra mile to incorporate eco-friendly practices. The project boasts rainwater harvesting, energy-efficient lighting, and waste management systems, all contributing to a greener and more sustainable living environment. This commitment to eco-conscious living aligns with the global trend toward responsible and sustainable development. The project features state-of-the-art security systems, CCTV surveillance, and 24/7 security personnel to ensure that you and your loved ones can enjoy peace of mind. The gated community offers a secure and private living experience. Its strategic location, architectural excellence, world-class amenities, luxurious residences, commitment to sustainability, and top-notch security make it a standout choice for those seeking a premium lifestyle in Whitefield, Bangalore. If you aspire to live in a home that redefines opulence and comfort, Assetz Codename One of A Kind Whitefield Bengaluru is the address you've been waiting for. It truly epitomizes the future of upscale living in Bangalore's dynamic real estate landscape.",
    project_details: [
      {
        name: "Project Location",
        value: "Whitefield",
      },
      {
        name: "Total Land Area",
        value: "1.8 Acres",
      },
      {
        name: "Number of Units",
        value: "96 Units",
      },
      {
        name: "Towers and Blocks",
        value: "G+12",
      },
      {
        name: "Unit Variants",
        value: "1260 To 2300 Sqft",
      },
      {
        name: "Possession Time",
        value: "Dec 2024",
      },
      {
        name: "RERA Approved",
        value: "Approved",
      },
    ],

    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    //update this image with actual image
    project_images: [AssetzOOK1, AssetzOOK3, AssetzOOK2, AssetzOOK4],
  },
];
