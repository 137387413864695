import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Background1, Rupees, Size, Unit } from "../../../Assets";
import { useStyles } from "./CarouselCard.styles";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useNavigate } from "react-router-dom";

const defaultData = {
  id: "1",
  project_name: "Sobha Sentosa Luxury Apartments in Panathur Road, Bangalore",
  status: "Ongoing",
  location: "Apartments for Sale in Bangalore",
  price: "59 Lac* - 1.74 Cr*",
  size: "689 - 1804 sqft",
  units: "1 & 3 BHK",
};
const CarouselCard = ({ data }) => {
  const styles = useStyles();
  const {
    id,
    project_name,
    status,
    location,
    price,
    size,
    units,
    bg_img,
    message,
  } = data;
  const navigate = useNavigate();
  const chipData = {
    price: {
      title: "Price",
      value: price,
      icon: Rupees,
    },
    size: {
      title: "Sqft",
      value: size,
      icon: Size,
    },
    units: {
      title: "Unit",
      value: units,
      icon: Unit,
    },
  };
  const chipList = ["price", "size", "units"];

  const Component = ({ type }) => {
    const { title, value, icon: Icon } = chipData?.[type];
    if (!value) {
      return;
    }
    return (
      <Stack width="33rem" gap={"2rem"} direction={"row"}>
        <Icon className={styles.icon} />
        <Typography variant="h5">{title}</Typography>
        <Typography variant="h5">{value}</Typography>
      </Stack>
    );
  };
  return (
    <Stack
      className={styles.container}
      // onClick={() => navigate(`/project/details/${id}`)}
    >
      <Box className={styles.cardImg}>
        <img src={bg_img ? bg_img : Background1} />
      </Box>
      <Stack gap="1.5rem" p="2rem">
        <Typography variant="h3">{project_name}</Typography>
        <Stack direction={"row"} gap="1rem" className={styles.statusContainer}>
          <Typography variant="subtitle2">{status}</Typography>
          <Typography variant="subtitle2">{location}</Typography>
        </Stack>
        {message ? (
          <Typography variant="h5">{message}</Typography>
        ) : (
          <Stack gap="0.5rem" justifyContent={"space-between"}>
            {chipList?.map((item) => (
              <Component type={item} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
export default CarouselCard;
