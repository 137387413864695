import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row !important",
    margin: "10rem 5rem",
    width: "92vw",
    height: "35rem",
    gap: "1rem",
    borderRadius: "0.5rem",
    boxShadow: "inset 0 0 0.7rem",
    overflow: "hidden",
    cursor: "pointer",
    boxSizing: "border-box",
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    padding: "3rem",
    "& .MuiTypography-root": { color: `${theme.palette.text.main} !important` },
    "& hr": {
      borderColor: `${theme.palette.text.main}50`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "92%",
      height: "47rem",
      flexDirection: "column !important",
      margin: "10rem 2rem",
      "& .MuiTypography-h3": {
        fontSize: "1.5rem !important",
      },
    },
  },

  statusContainer: {
    "&>div": {
      padding: "0.5rem 1rem",
      borderRadius: "0.6rem",
      width: "fit-content",
      background: `${theme.palette.primary.main}ae`,
    },
  },
  share: {
    width: "1.7rem",
    padding: "0.5rem",
    background: `${theme.palette.primary.main}ae`,
    borderRadius: "0.5rem",
    marginTop: "1rem",
    float: "right",
  },
}));
