import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.background.main,
    boxShadow: "-0.1rem -0.1rem 1rem",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "3rem",
    "& svg": {
      width: "2rem",
      height: "2rem",
    },
    "&>div": {
      // width: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: "2rem",
      "&>div": {
        marginTop: "2rem",
      },
    },
  },
  links: {
    marginTop: "2rem",
    gap: "1rem",
    "& a": {
      textDecoration: "none",
      margin: "0 0 1rem 0.7rem",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.text.dark,
      },
    },
    "& svg": {
      width: "1.5rem",
      height: "1.5rem",
      "& path": {
        strokeWidth: "0.2rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.5rem",
    },
  },
  info: {
    "& p:nth-child(2)": {
      marginTop: "2rem",
    },
    "& svg": {
      marginRight: "0.7rem",
      width: "1.5rem",
      height: "1.5rem",
      "& path": {
        strokeWidth: "0.2rem",
      },
    },
  },
  details: {
    width: "25%",
    "&>div:last-child": {
      marginTop: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  socialIcon: {
    gap: "1rem",
    justifyContent: "center",
    flexWrap: "wrap",
    "&>a": {
      background: "black",
      padding: "0.7rem",
      width: "4rem",
      height: "4rem",
      borderRadius: "50%",
      cursor: "pointer",
      "& span": {
        height: "inherit",
        width: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& svg": {
        width: "3rem",
        height: "3rem",
        stroke: "white",
        fill: "white",
        transition: "all 0.3s linear",
      },
    },
  },
  instagram: {
    "&:hover": {
      "& svg": {
        transform: "scale(1.1)",
        stroke: "#C13584",
      },
    },
  },
  facebook: {
    "&:hover": {
      "& svg": {
        transform: "scale(1.1)",
        fill: "#2576FE",
      },
    },
  },
  twitter: {
    "&:hover": {
      "& svg": {
        transform: "scale(1.1)",
        stroke: "#2576FE",
      },
    },
  },
  linkedin: {
    "&:hover": {
      "& svg": {
        transform: "scale(1.1)",
        fill: "#007EBB",
      },
    },
  },

  credit: {
    textAlign: "center",
    marginTop: "1rem !important",
    padding: "1rem",
    background: "#00000012",
  },

  chatContainer: {
    padding: "0.5rem",
    "&>a": {
      width: "3rem",
      height: "3rem",
    },
    "& svg": {
      width: "2.5rem !important",
      height: "2.5rem !important",
    },
  },
  emailIcon: {
    marginTop: "1rem",
  },
  whatsapp: {
    "&:hover": {
      "& svg": {
        transform: "scale(1.1)",
        fill: "#0fcc6b",
      },
    },
  },
  location: {
    width: "30%",
    "& img": {
      width: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
