import {
  Bangalore,
  BangaloreCardImg,
  Hyderabad,
  HyderabadCardImg,
} from "../../Components/Assets";
import { BANGALORE_ROUTE, HYDERABAD_ROUTE } from "../utils-constant";

export const locations = [
  {
    name: "Bangalore",
    image: BangaloreCardImg,
    decription: "Bangalore Reality",
    background: "linear-gradient(#1f223e,#a6594d)",
    // redirectTo: `/home/${BANGALORE_ROUTE}`,
    redirectTo: `/property/${BANGALORE_ROUTE}`,
  },
  {
    name: "Hyderabad",
    image: HyderabadCardImg,
    decription: "Hyderabad Reality",
    background: "linear-gradient(#7fc55c,#1f223e)",
    redirectTo: `/property/${HYDERABAD_ROUTE}`,
  },
];

export const LocationNavigateList = [BANGALORE_ROUTE, HYDERABAD_ROUTE];
