import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Projects from "../Projects/Projects";
import { LocationNavigateList } from "../../Utils/Data/LandingPageData";
import {
  BANGALORE_ROUTE,
  projectListingData,
} from "../../Utils/utils-constant";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import EnquiryFormDialog from "../Forms/EnquiryFormDialog/EnquiryFormDialog";
import {
  Apartment1,
  Apartment2,
  Header_Bangalore_Apartments,
  Header_Bangalore_Plots,
  Header_Bangalore_Villas,
  Header_Hyderabad_Apartments,
  Header_Hyderabad_Plots,
  Header_Hyderabad_Villas,
  Plots1,
} from "../Assets";

const Home = () => {
  const { location, propertyType } = useParams();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  let projectList = [];

  if (!LocationNavigateList?.includes(location)) {
    navigate("/");
  } else {
    projectList = projectListingData?.[location]?.filter(
      (project) => project?.type == propertyType
    );
  }
  const handleCardClick = (id, redirect) => {
    let isFormSubmitted = localStorage.getItem("id");
    if (!!isFormSubmitted && (redirect ?? true)) {
      navigate(`/project/details/${id}`);
    } else {
      setOpenDialog(true);
    }
  };

  // const allPropertyTypeData = [
  //   { name: "Apartment", bg_img: Apartment1 },
  //   { name: "Villas", bg_img: Villa5 },
  //   { name: "Plots", bg_img: Plots3 },
  //   { name: "Farmlands", bg_img: Farmlands3 },
  //   { name: "Commercial Space", bg_img: Commercial6 },
  // ];
  const getBangloreImageData = () => {
    switch (propertyType) {
      case "Apartment":
        return {
          img: Header_Bangalore_Apartments,
          title: "BANGALORE",
          subTitle: "Apartments",
        };
      case "Villas":
        return {
          img: Header_Bangalore_Villas,
          title: "BANGALORE",
          subTitle: "Villas",
        };
      case "Plots":
        return {
          img: Header_Bangalore_Plots,
          title: "BANGALORE",
          subTitle: "Plots",
        };
      case "Farmlands":
        return { img: Apartment2, title: "BANGALORE", subTitle: "Farmlands" };
      case "Commercial_Space":
        return {
          img: Apartment2,
          title: "BANGALORE",
          subTitle: "Commercial_Space",
        };
      default:
        return { img: "", title: "", subTitle: "" };
    }
  };
  const getHyderabadImageData = () => {
    switch (propertyType) {
      case "Apartment":
        return {
          img: Header_Hyderabad_Apartments,
          title: "HYDERABAD",
          subTitle: "Apartments",
        };
      case "Villas":
        return {
          img: Header_Hyderabad_Villas,
          title: "HYDERABAD",
          subTitle: "Villas",
        };
      case "Plots":
        return {
          img: Header_Hyderabad_Plots,
          title: "HYDERABAD",
          subTitle: "Plots",
        };
      case "Farmlands":
        return { img: Apartment2, title: "HYDERABAD", subTitle: "Farmlands" };
      case "Commercial_Space":
        return {
          img: Apartment2,
          title: "HYDERABAD",
          subTitle: "Commercial_Space",
        };
      default:
        return { img: "", title: "", subTitle: "" };
    }
  };

  const getImageData = () => {
    if (location == BANGALORE_ROUTE) {
      return getBangloreImageData();
    } else {
      return getHyderabadImageData();
    }
  };

  return (
    <>
      {projectList?.length > 0 ? (
        <Projects
          projectList={projectList}
          handleCardClick={handleCardClick}
          getImageData={getImageData}
        />
      ) : (
        <EmptyComponent message={"No Data Found"}></EmptyComponent>
      )}
      <EnquiryFormDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onCardClick={handleCardClick}
      />
    </>
  );
};

export default Home;
