import { Hyderabad_apartments_list_data } from "./Apartments/Hyderabad_apartments_list_data";
import { Hyderabad_commercialSpace_list_data } from "./Commercial_Spaces/Hyderabad_commercialSpace_list_data";
import { Hyderabad_farmlands_list_data } from "./Farmlands/Hyderabad_farmlands_list_data";
import { Hyderabad_plots_list_data } from "./Plots/Hyderabad_plots_list_data";
import { Hyderabad_villas_list_data } from "./Villas/Hyderabad_villas_list_data";

export const HyderabadProjectList = [
  ...Hyderabad_apartments_list_data,
  ...Hyderabad_commercialSpace_list_data,
  ...Hyderabad_farmlands_list_data,
  ...Hyderabad_plots_list_data,
  ...Hyderabad_villas_list_data,
];
