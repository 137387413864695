import { useEffect, useState } from 'react'

export const useMobileView = () => {
  const [mobileView, setMoibleView] = useState(true)
  const handleMobileDisplay = () => {
    if (window.innerWidth <= 600) {
      setMoibleView(true)
    } else {
      setMoibleView(false)
    }
  }
  useEffect(() => {
    handleMobileDisplay()
    window.addEventListener('resize', handleMobileDisplay)
    return () => {
      window.removeEventListener('resize', handleMobileDisplay)
    }
  }, [window.innerWidth])
  return mobileView
}
