import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem",
    gap: "2rem",
    display: "flex",
    flexDirection: "column",
  },
  pContainer: {
    display: "flex",
    gap: "2rem",
    padding: "0 3rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  imgContainer: {
    "& img": { width: "100%" },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stackContainer: {
    // padding: "1rem",
    display: "flex",
    flexDirection: "row",
    gap: "1.5rem",
    marginTop: "5rem",
    justifyContent: "space-around",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "1rem",
      justifyContent: "center",
    },
  },
}));
