import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { Box, Typography, Stack, Divider } from "@mui/material";
import { useStyles } from "./Contact.styles";
import EnquiryForm from "../Forms/EnquiryForn/EnquiryForm";
import { useNavigate } from "react-router-dom";

import {
  Apartment2,
  Apartment4,
  Commercial2,
  Commercial4,
  Commercial5,
  Contact_Page_Img,
  Earphone_Telephone,
  Gmail_Logo,
  Homeauto2,
  Homeauto3,
  Interior2,
  Plots3,
  Whatapp_Icon,
} from "../Assets/index";
import Card from "./Card/Card";
import GoogleMap from "../Map/GoogleMap";
import SocialLinkPage from "../SocialLinkPage/SocialLinkPage";

const Contact = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const contactCard = [
    {
      name: "Mobile_Number",
      icon: Earphone_Telephone,
      content: "+91 6364795353",
      background: "#666666",
      redirectTo: "tel:6364795353",
      title: "Call us",
    },
    {
      name: "WhatsApp",
      icon: Whatapp_Icon,
      content: "+91 6364585353",
      background: "#666666",
      redirectTo: "https://wa.me/6364585353",
      title: "Send Message in WhatsApp",
    },
    {
      name: "Gmail",
      icon: Gmail_Logo,
      content: "sales@treezenterprise.com",
      background: "#666666",
      redirectTo: "mailto:sales@treezenterprise.com",
      title: "Mail us",
    },
  ];
  const onCardClick = (redirectTo) => {
    if (redirectTo) {
      window.open(redirectTo, "_blank");
    }
    // navigate(redirectTo, "_blank");
  };
  const imgContent = [
    {
      id: "1",
      title: "",
      subTitle:
        "Thank you for considering Treez Enterprise for your real estate needs. We look forward to assisting you in turning your real estate aspirations into reality. Should you have any questions or require further assistance, please do not hesitate to contact us.",
      bg_img: Apartment4,
    },
    {
      id: "1",
      title: "",
      subTitle:
        "Thank you for considering Treez Enterprise for your real estate needs. We look forward to assisting you in turning your real estate aspirations into reality. Should you have any questions or require further assistance, please do not hesitate to contact us.",
      bg_img: Commercial2,
    },
    {
      id: "2",
      title: "",
      subTitle:
        "Thank you for considering Treez Enterprise for your real estate needs. We look forward to assisting you in turning your real estate aspirations into reality. Should you have any questions or require further assistance, please do not hesitate to contact us.",
      bg_img: Interior2,
    },
    {
      id: "3",
      title: "",
      subTitle:
        "Thank you for considering Treez Enterprise for your real estate needs. We look forward to assisting you in turning your real estate aspirations into reality. Should you have any questions or require further assistance, please do not hesitate to contact us.",
      bg_img: Commercial5,
    },
    {
      id: "4",
      title: "",
      subTitle:
        "Thank you for considering Treez Enterprise for your real estate needs. We look forward to assisting you in turning your real estate aspirations into reality. Should you have any questions or require further assistance, please do not hesitate to contact us.",
      bg_img: Plots3,
    },
  ];
  return (
    <AppLayout
      showImage
      imageData={{
        img: Commercial4,
        title: "CONTACT US",
      }}
    >
      <Stack>
        <Box className={styles.pContainer}>
          <Box className={styles.container} mt="5rem">
            <Typography variant="h3" textAlign={"center"}>
              Thank you for considering Treez Enterprise for your real estate
              needs. We look forward to assisting you in turning your real
              estate aspirations into reality. Should you have any questions or
              require further assistance, please do not hesitate to contact us.{" "}
            </Typography>
            <Divider />
            <Box className={styles.stackContainer}>
              {contactCard?.map((item) => (
                <Card contactCard={item} onclick={onCardClick} />
              ))}
            </Box>
          </Box>
        </Box>
        <SocialLinkPage />
      </Stack>
      <GoogleMap /> {/* Add GoogleMap component here */}
    </AppLayout>
  );
};

export default Contact;
