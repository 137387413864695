import { Box, Typography } from "@mui/material";
import { useStyles } from "./ParaCard.styles";
import { LikeIcon } from "../../Assets";
import { theme } from "../../Theme/theme";

const ParaCard = ({ paraData = { boldText: "Text", content: "content" } }) => {
  const styles = useStyles;
  const { id, boldText, content } = paraData;
  return (
    <Box
      key={id}
      sx={{
        borderRadius: "0.5rem",
        boxShadow: "0.1rem 0.1rem 0.5rem",
        padding: "1rem",
        width: "85%",
        [theme.breakpoints.down("md")]: {
          margin: "auto",
        },
      }}
    >
      {/* <Typography variant="h4">{heading}</Typography> */}
      {/* <Box padding={"1rem"} /> */}
      <Box style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <Typography variant="h6">
          <img
            src={LikeIcon}
            style={{ width: "2rem", height: "2rem" }}
            className={styles.likeIcon}
          />
          <span style={{ fontWeight: "bold" }}>{boldText} </span> {content}
        </Typography>
      </Box>
    </Box>
  );
};

export default ParaCard;
