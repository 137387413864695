import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import {
  Background1,
  Background2,
  Contact_Page_Img,
  Floor,
  ShareIcon,
  Size,
  Unit,
} from "../../../Assets";
import { useStyles } from "./ProjectDetailsCard.styles";
import { ImageSlider } from "../../../CommonComponent/ImageSlider/ImageSlider";
import { theme } from "../../../Theme/theme";
import {
  allProjectDetailsList,
  allProjectList,
} from "../../../../Utils/utils-constant";
import { useMobileView } from "../../../CommonComponent/Hooks/useMobileView/useMobileView";

const defaultData = {
  id: "1",
  project_name: "Sobha Sentosa Luxury Apartments ",
  description: "Panathur Road, Bangalore",
  status: "Ongoing",
  location: "Apartments for Sale in Bangalore",
  price: "59 Lac* - 1.74 Cr*",
  size: "689 - 1804 sqft",
  units: "1 & 3 BHK",
};

const ImgSliderData = [
  {
    id: 0,
    title: "Find Your Next Home In Bengaluru",
    subTitle:
      "Through Our Proprietary Platform, Treez Is Changing How Agents And Clients Navigate The Process Of Finding Or Selling A Home.",
    bg_img: Background2,
  },
  {
    id: 1,
    title: "Find Your Next Home In Bengaluru",
    subTitle:
      "Through Our Proprietary Platform, Treez Is Changing How Agents And Clients Navigate The Process Of Finding Or Selling A Home.",
    bg_img: Contact_Page_Img,
  },
  {
    id: 2,
    title: "Find Your Next Home In Bengaluru",
    subTitle:
      "Through Our Proprietary Platform, Treez Is Changing How Agents And Clients Navigate The Process Of Finding Or Selling A Home.",
    bg_img: Contact_Page_Img,
  },
  {
    id: 3,
    title: "Find Your Next Home In Bengaluru",
    subTitle:
      "Through Our Proprietary Platform, Treez Is Changing How Agents And Clients Navigate The Process Of Finding Or Selling A Home.",
    bg_img: Background1,
  },
];

function ProjectDetailsCard({ data, onClick = () => {} }) {
  const styles = useStyles();
  const { id, project_name, project_images } = data;
  const cardData = allProjectList?.find((data) => data?.id == id) || {};

  const { status, location, price, size, units, about } = cardData;
  const isMobile = useMobileView();

  return (
    <Box mt="6.5rem">
      <ImageSlider
        isLight
        data={project_images?.map((img) => ({ bg_img: img }))}
      />
      <Stack
        justifyContent={"space-between"}
        className={styles.container}
        onClick={() => onClick(id)}
        sx={{
          background: `linear-gradient(#000000ae,#00000050),url(${project_images?.[0]})`,
        }}
      >
        <Stack gap="2rem" p="2rem 0">
          <Typography variant="h1">{project_name}</Typography>
          <Typography variant="h5">{about}</Typography>
          <Stack
            direction={"row"}
            gap="1rem"
            className={styles.statusContainer}
          >
            <Typography variant="h5">{status}</Typography>
            <Typography variant="h5">{location}</Typography>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              gap: "3rem",

              [theme.breakpoints.down("sm")]: {
                flexDirection: "column !important",
                gap: "2rem",
              },
            }}
          >
            {size && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap="1rem"
                sx={{
                  "&>svg": {
                    width: "3rem",
                    height: "3rem",
                    "& path": {
                      fill: "white",
                      stroke: "white",
                    },
                  },
                }}
              >
                <Size />
                <Typography variant="h2"> Sqft - {size}</Typography>
              </Stack>
            )}

            {units && (
              <>
                <Divider orientation={isMobile ? "horizontal" : "vertical"} />
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap="1rem"
                  sx={{
                    "&>svg": {
                      width: "3rem",
                      height: "3rem",
                      "& path": {
                        stroke: "white",
                        strokeWidth: 1,
                      },
                    },
                  }}
                >
                  <Unit />
                  <Typography variant="h2">Units - {units}</Typography>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
        <Box p="2rem 0">
          <Typography variant="h1" className={styles.price}>
            Price : ₹{price}
          </Typography>
          {/* <Box className={styles.share} sx={{}}>
            <ShareIcon />
          </Box> */}
        </Box>
      </Stack>
    </Box>
  );
}

export default ProjectDetailsCard;
