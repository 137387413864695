import React from "react";
import { useStyles } from "./GallerySection.styles";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import {
  Apartment1,
  Background1,
  Background2,
  Commercial1,
  Contact_Page_Img,
  Farmlands1,
  Plots1,
  Villa1,
} from "../../Assets";
import { theme } from "../../Theme/theme";
import { styled } from "@mui/styles";

const content = [
  {
    item: 1,
    data: [
      {
        id: 0,
        title: "Apartment",
        subTitle: "17 properties",
        bg_img: Apartment1,
        size: "6",
      },
      {
        id: 1,
        title: "Villas",
        subTitle: "17 properties",
        bg_img: Villa1,
        size: "3",
      },
      {
        id: 2,
        title: "Plots",
        subTitle: "17 properties",
        bg_img: Plots1,
        size: "3",
      },
    ],
  },
  {
    item: 2,
    data: [
      {
        id: 0,
        title: "Farmlands",
        subTitle: "17 properties",
        bg_img: Farmlands1,
        size: "6",
      },
      {
        id: 1,
        title: "Commercial Space",
        subTitle: "17 properties",
        bg_img: Commercial1,
        size: "6",
      },
    ],
  },
];

const GallerySection = () => {
  const styles = useStyles();

  return (
    <>
      <Stack className={styles.titles}>
        <Typography variant="h1">Featured Properties</Typography>
        <Typography variant="h3">All The Properties We Offer</Typography>
      </Stack>
      <Stack gap="2rem">
        {content?.map((item) => {
          const { data } = item;
          return (
            <Grid container spacing={"2rem"}>
              {data?.map((item) => {
                const { id, title, subTitle, size, bg_img } = item;
                return (
                  <Grid item md={size} xs={12}>
                    <Box
                      sx={{
                        // background: `url(${bg_img})`,
                        height: "20rem",
                        borderRadius: "0.5rem",
                        position: "relative",
                        overflow: "hidden",
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          zIndex: "-1",
                          background: `url(${bg_img}) 0 0 repeat`,
                          transform: "scale(1)",
                          transition: "all 0.3s linear",
                          backgroundSize: "cover",
                        },
                        "&:hover": {
                          "&:before": {
                            transform: "scale(1.2)",
                            opacity: 0.8,
                          },
                        },
                      }}
                    >
                      <Typography
                        variant="h2"
                        color={"text.main"}
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: `${theme.palette.background.dark}50`,
                        }}
                      >
                        {title}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Stack>
    </>
  );
};

export default GallerySection;
