import {
  SumadhuraH1,
  SumadhuraO1,
  CandeurC1,
  Rnp1,
  ThePearl1,
} from "../../../../Components/Assets";

export const Hyderabad_apartments_list_data = [
  {
    id: "HAD101",
    project_name: "Sumadura Constuction Group",
    type: "Apartment",
    status: "Ongoing",
    location: "Kondapur, Hyderabad",
    price: "2.85 Cr* Onwards",
    size: "689 - 1804 sqft",
    units: "487 Units",
    bg_img: SumadhuraH1,
  },
  {
    id: "HAD102",
    project_name: "Sumadura Constuction Financial District",
    type: "Apartment",
    status: "Ongoing",
    location: "Finacial Districk, Hyderabad",
    price: "2.26 Cr* Onwords",
    size: "21670.00 sq.ft. - 3000.00 sq.ft. ",
    units: "854 Units",
    bg_img: SumadhuraO1,
  },
  {
    id: "HAD103",
    project_name: "Candeur Groups",
    type: "Apartment",
    status: "Ongoing",
    location: "Lingampally, Hyderabad",
    price: "8325/-per sqft",
    size: "1537.00 sq.ft. - 2656.00 sq.ft.",
    units: "1250 Units",
    bg_img: CandeurC1,
  },
  {
    id: "HAD104",
    project_name: "RNP Groups",
    type: "Apartment",
    status: "Ongoing",
    location: "Kondapur, Hyderabad",
    price: "2cr Onwards",
    size: " 1585.00 sq.ft. - 2190.00 sq.ft.",
    units: "195 Units",
    bg_img: Rnp1,
  },
  {
    id: "HAD105",
    project_name: "Aurobindo Groups",
    type: "Apartment",
    status: "Ongoing",
    location: "Hitechcity, Hyderabad",
    price: "6.85cr Onwards",
    size: " 5300 sqft & 5400 sqft",
    units: "224 Units",
    bg_img: ThePearl1,
  },
];
