import React, { useState } from "react";
import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { Box, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./LandingPage.styles";
import Services from "./Services/Services";
import ScrollGallery from "./ScrollLandingPage/ScrollGallery";
import ScrollCarousel from "./ScrollLandingPage/ScrollCarousel";
import LocationCard from "./LocationCard/LocationCard";
import { locations } from "../../Utils/Data/LandingPageData";
import { BANGALORE_ROUTE, HYDERABAD_ROUTE } from "../../Utils/utils-constant";
import SocialLinkPage from "../SocialLinkPage/SocialLinkPage";
import EnquiryForm from "../Forms/EnquiryForn/EnquiryForm";
import { CrossIcon } from "../Assets";

const LandingPage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const onCardClick = (redirectTo) => {
    navigate(redirectTo);
  };

  return (
    <AppLayout isImageSlider isGoogleMap>
      <LocationCard locations={locations} onCardClick={onCardClick} />
      <Box p={"3rem 0"}>
        <Box className={[styles.layout, styles.box1]}>
          <ScrollCarousel type={BANGALORE_ROUTE} color={"light"} />
        </Box>
        <Box className={[styles.layout, styles.box, styles.carouselLight]}>
          <ScrollCarousel type={HYDERABAD_ROUTE} color={"dark"} />
        </Box>
        <Box className={[styles.layout, styles.box2]}>
          <Services />
        </Box>
        <Box className={styles.layout}>
          <ScrollGallery />
        </Box>
        <SocialLinkPage />
      </Box>
    </AppLayout>
  );
};

export default LandingPage;
