import { useStyles } from "../ProjectDetails.styles";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export const ProjectTable = ({ data = [], title, isHeader = false }) => {
  const styles = useStyles();
  return (
    <Stack gap="2rem" className={styles.box}>
      <Typography variant="h2">{title}</Typography>
      <Table className={`${styles.table} ${isHeader && styles.headerTable}`}>
        {isHeader && (
          <TableHead>
            <TableRow className={styles.thead}>
              {Object.keys(data?.[0] || {})?.map((column) => (
                <TableCell component={"th"}>
                  <Typography variant="h4">{column}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data?.map((item, index) => {
            return (
              <TableRow className={index % 2 ? styles.evenRow : styles.oddRow}>
                {Object.keys(item || {})?.map((columnData) => (
                  <TableCell>{item?.[columnData]}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Stack>
  );
};
