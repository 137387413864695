import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  Slide,
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  Dot,
} from "pure-react-carousel";
import { Link } from "react-router-dom";
import { useStyles } from "./Carousel.styles";
import { useMobileView } from "../Hooks/useMobileView/useMobileView";
import { RightArrow } from "../../Assets";
import { theme } from "../../Theme/theme";

export const Carousel = ({
  items,
  title,
  subTitle,
  view_all,
  view_all_url,
  cardHeight = 400,
  cardWidth = 400,
  visibleSlides = 3.2,
  marginRight = "1.5rem",
  renderItem = () => null,
  onBack = () => {},
  onForward = () => {},
  sx,
  isPlaying = true,
  interval = 5000,
  isDot = true,
  color,
}) => {
  const styles = useStyles();
  const isMobile = useMobileView();
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  visibleSlides = isMobile ? 1 : mdScreen ? 2 : visibleSlides;

  return (
    <CarouselProvider
      visibleSlides={visibleSlides}
      totalSlides={items?.length}
      step={isMobile ? 1 : 1}
      naturalSlideWidth={cardWidth}
      naturalSlideHeight={cardHeight}
      isPlaying={isPlaying}
      interval={interval}
    >
      <Stack className={styles.layout_root} alignItems={"center"}>
        {title && (
          <>
            <Stack gap="1rem" alignItems={"center"} className={styles.content}>
              <Typography
                variant="h1"
                className={styles.launched_title}
                color={color == "dark" ? "text.primary" : "text.main"}
              >
                {title}
              </Typography>
              <Typography
                variant="h2"
                className={styles.launched_subTitle}
                color={color == "dark" ? "text.primary" : "text.main"}
              >
                {subTitle}
              </Typography>
            </Stack>
            <Box className={`${styles.upperSection}`}>
              {!isPlaying && items?.length > visibleSlides && (
                <Box
                  sx={{
                    marginRight: marginRight,
                    display: "flex",
                    justifyContent: "center",
                    ...sx,
                  }}
                >
                  <ButtonBack className={styles.arrow} onClick={() => onBack()}>
                    <div className={`${styles.leftArrow}`}>
                      {<RightArrow />}
                    </div>
                  </ButtonBack>
                  <ButtonNext
                    className={styles.arrow}
                    onClick={() => onForward()}
                  >
                    <div className={`${styles.rightArrow} `}>
                      {<RightArrow />}
                    </div>
                  </ButtonNext>
                  {view_all && items?.length > 4 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "1rem",
                      }}
                    >
                      <Link to={view_all_url} className={styles.view_all}>
                        View all
                      </Link>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </>
        )}
      </Stack>

      <Slider>
        {items?.map((item, i) => (
          <Slide className={styles.slide} key={i} index={i}>
            {renderItem(item, i)}
          </Slide>
        ))}
      </Slider>
      {isDot && (
        <Box className={styles.dotContainer}>
          {items?.slice(0, items?.length - visibleSlides)?.map((item, i) => (
            <Dot slide={i} className={styles.dot} disabled={false} />
          ))}
        </Box>
      )}
    </CarouselProvider>
  );
};
