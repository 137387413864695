import { Box, Typography, Stack } from "@mui/material";
import { useStyles } from "./GoogleMap.styles";

const GoogleMap = () => {
  const styles = useStyles();
  return (
    <Box className={styles.LinkBox}>
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1sElJPtVC7EC3npit4iqh7cVZ8p8jNjs&ehbc=2E312F&noprof=1"
        height="480"
        allowfullscreen=""
        loading="fast"
        referrerpolicy="no-referrer-when-downgrade"
        className={styles.mapBox}
      ></iframe>
    </Box>
  );
};

export default GoogleMap;
