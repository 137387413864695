import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { makeStyles } from "@mui/styles";
import {
  Apartment1,
  Background2,
  Commercial2,
  Commercial6,
  Farmlands1,
  Farmlands3,
  Header_Bangalore_and_Hyderabad,
  Plots3,
  Villa5,
} from "../Assets";
import { ScrollComponent } from "../CommonComponent/ScrollComponent/ScrollComponent";
import { useNavigate, useParams } from "react-router-dom";
import { LocationNavigateList } from "../../Utils/Data/LandingPageData";
import { BANGALORE_ROUTE, HYDERABAD_ROUTE } from "../../Utils/utils-constant";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0rem 5rem 10rem",
    gap: "4rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
      justifyContent: "center",
    },
  },
  card: {
    width: "43rem",
    height: "30rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: `0 0 1rem ${theme.palette.primary.main},0 0 0.5rem`,
    borderRadius: "0.5rem",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "42rem",
    },

    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: "-1",
      transform: "scale(1)",
      transition: "all 0.3s linear",
    },
    "&:hover": {
      "&:before": {
        transform: "scale(1.2)",
        opacity: 0.8,
      },
    },
  },
  title: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `${theme.palette.background.dark}50`,
  },
}));
const allPropertyTypeData = [
  { name: "Apartment", bg_img: Apartment1 },
  { name: "Villas", bg_img: Villa5 },
  { name: "Plots", bg_img: Plots3 },
  { name: "Farmlands", bg_img: Farmlands3 },
  { name: "Commercial Space", bg_img: Commercial6 },
];

const HyderabadContent = [
  {
    id: 0,
    title: "WELCOME INTO TREEZ HYDERABAD REALITY",
    subTitle:
      "Certainly! Here are some categories of properties in Hyderabad along with listings under each category.",
    bg_img: Apartment1,
  },
  {
    id: 1,
    title: "WELCOME INTO TREEZ HYDERABAD REALITY",
    subTitle:
      "Hyderabad has emerged as a prominent IT and tech hub in India, with the presence of major tech parks, multinational companies, and startups. Properties in areas like HITEC City, Gachibowli, and Financial District are particularly sought after due to their proximity to IT hubs and job opportunities.",
    bg_img: Background2,
  },
  {
    id: 2,
    title: "WELCOME INTO TREEZ HYDERABAD REALITY",
    subTitle:
      "Compared to other major cities in India Hyderabad offers relatively affordable property prices. This affordability factor attracts homebuyers and investors looking for value for money in real estate investments.",
    bg_img: Farmlands1,
  },
  {
    id: 3,
    title: "WELCOME INTO TREEZ HYDERABAD REALITY",
    subTitle:
      "Hyderabad boasts well-planned layouts, wide roads, and modern infrastructure developments. Properties in gated communities and integrated townships offer residents amenities such as 24/7 security, parks, swimming pools, and clubhouse facilities.",
    bg_img: Commercial2,
  },
];
const BangloreImgContent = [
  {
    id: 0,
    title: "WELCOME INTO TREEZ BANGALORE REALITY",
    subTitle:
      "Certainly! Here are some categories of properties in Bangalore along with listings under each category.",
    bg_img: Apartment1,
  },
  {
    id: 1,
    title: "WELCOME INTO TREEZ BANGALORE REALITY",
    subTitle:
      "Buying flats in Bangalore allows you to enter a growing market and allocate money to building assets instead of paying rent.",
    bg_img: Background2,
  },
  {
    id: 2,
    title: "WELCOME INTO TREEZ BANGALORE REALITY",
    subTitle:
      "One of the best ways to secure higher returns is property investment. No matter which property in Bangalore you buy, you are likely to receive rewarding returns.",
    bg_img: Farmlands1,
  },
  {
    id: 3,
    title: "WELCOME INTO TREEZ BANGALORE REALITY",
    subTitle:
      "Bangalore properties often come equipped with modern amenities such as gated communities, 24/7 security, swimming pools, fitness centers, and clubhouses. These amenities enhance the quality of life and cater to the lifestyle needs of residents.",
    bg_img: Commercial2,
  },
  {
    id: 4,
    title: "WELCOME INTO TREEZ BANGALORE REALITY",
    subTitle:
      "Bangalore properties have shown consistent appreciation in value over the years, making them attractive investment options. The city's growing population, robust economy, and infrastructural developments contribute to the appreciation potential of properties.",
    bg_img: Commercial2,
  },
];

const getImgDetails = (location) => {
  switch (location) {
    case BANGALORE_ROUTE:
      return BangloreImgContent;
    case HYDERABAD_ROUTE:
      return HyderabadContent;
  }
};
const Properties = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { location, propertyType } = useParams();

  useEffect(() => {
    if (!LocationNavigateList?.includes(location)) {
      navigate("/");
    } else {
    }
  }, []);
  const onCardClick = (item) => {
    let link = item?.name.split(" ").join("_");
    navigate(`/property/${location}/${link}`);
  };
  return (
    <AppLayout
      isEnquiryForm
      showImage
      imageData={{
        img: Header_Bangalore_and_Hyderabad,
        title:
          location == BANGALORE_ROUTE
            ? "WELCOME INTO TREEZ BANGALORE REALITY"
            : "WELCOME INTO TREEZ HYDERABAD REALITY",
        subTitle:
          location == BANGALORE_ROUTE
            ? "Bangalore properties often come equipped with modern amenities such as gated communities, 24/7 security, swimming pools, fitness centers, and clubhouses. These amenities enhance the quality of life and cater to the lifestyle needs of residents."
            : "Hyderabad has emerged as a prominent IT and tech hub in India, with the presence of major tech parks, multinational companies, and startups. Properties in areas like HITEC City, Gachibowli, and Financial District are particularly sought after due to their proximity to IT hubs and job opportunities.",
      }}
    >
      <PropertiesComponent
        allPropertyType={allPropertyTypeData}
        onCardClick={onCardClick}
      />
    </AppLayout>
  );
};

export const PropertiesComponent = ({
  allPropertyType = allPropertyTypeData,
  onCardClick = () => {},
  className,
  cardClass,
}) => {
  const styles = useStyles();

  return (
    <Stack
      direction={"row"}
      flexWrap={"wrap"}
      className={[styles.container, className ? className : ""]}
    >
      {allPropertyType?.map((item, index) => (
        <ScrollComponent>
          <Box
            className={[styles.card, cardClass ? cardClass : ""]}
            sx={{
              "&:before": {
                background: `url(${item?.bg_img}) 0 0 repeat`,
                backgroundSize: "cover",
              },
            }}
            onClick={() => {
              onCardClick(item);
            }}
          >
            <Typography
              variant="h1"
              color={"text.main"}
              className={styles.title}
            >
              {item?.name}
            </Typography>
          </Box>
        </ScrollComponent>
      ))}
    </Stack>
  );
};

export default Properties;
