import { useStyles } from "../Interior/interior.styles";
import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { Box, Typography, Stack } from "@mui/material";
import Card from "./Card/Card";
import {
  Clients1,
  Clients2,
  Clients3,
  Clients4,
  Clients5,
  Clients6,
  Clients7,
  HomeCinema,
  Interior1,
  Interior2,
  Interior3,
  Interior4,
  InteriorWork,
  Interior_Design,
  ProtectIcon,
  Renovation,
  kitchenInterior1,
  kitchenInterior3,
  kitchenInterior4,
} from "../Assets";
import ImgCard from "./Card/ImgCard";
import ContentWithCard from "./ContentWithCard/ContentWithCard";

const Interior = () => {
  const styles = useStyles();
  const contactCard = [
    {
      name: "INTERIOR WORKS",
      icon: InteriorWork,
      content:
        "We plan, researches, coordinates, and manages project come reality.",
    },
    {
      name: "RENOVATION WORKS",
      icon: Renovation,
      content:
        "We are always on the lookout for new trends with a team of interior designers.",
    },
    {
      name: "HOME CENIMA",
      icon: HomeCinema,
      content:
        "The only thing better than going to the movies is enjoying from the comfort of your home theatre.",
    },
    {
      name: "CIVIL WORKS",
      icon: ProtectIcon,
      content:
        "We have skilled civil labours who can execute the civil changes at the time of interior or expansion work.",
    },
    {
      name: "PAINTING & WALL CLADDING WORKS",
      icon: ProtectIcon,
      content:
        "Painting is one of the most important part of any interior, it defines the entire work done.",
    },
    {
      name: "TILE & GRANITE WORKS",
      icon: ProtectIcon,
      content:
        "If you think of modifying or changing of floor tiles/ Granite, bathroom tiles, Kitchen dado our team will help.",
    },
    {
      name: "ELECTRICAL WORKS",
      icon: ProtectIcon,
      content:
        "Several places attract us with a cozy and perfect ambiance. All credit goes to the lighting of those places.",
    },
    {
      name: "FALSE CEILING WORKS",
      icon: ProtectIcon,
      content:
        "False ceilings are often secondary ceilings that are hung below the main ceiling with the help of suspension cords or struts.",
    },
    {
      name: "HOME AUTOMATION",
      icon: ProtectIcon,
      content:
        "As we are living in the era of technology home automation has become a basic necessary for any home/work stations.",
    },
  ];
  const how_we_work = [
    {
      image: Interior2,
      title: "DEVELOPMENT OF RENDERINGS",
      content:
        "The creating process includes making the virtual 3D-model of the object in the real size, which helps to see the final result clearer. This format is comfortable for both of us.",
    },
    {
      image: kitchenInterior1,
      title: "PROJECT PRESENTATION",
      content:
        "After creating the 3D-model all the corrections and improving will be added according to the client choice, during the presentation the recommendations about the project’s realization are given by the designers.",
    },
    {
      image: kitchenInterior3,
      title: "MATERIALS SELECTION",
      content:
        "Our team will suggest you the best of material to be used in your project according to your budget, at the same time we create the specification of the equipment, decorative materials and furniture used in the project. If the creation of the author’s constructions or furniture elements takes place, the client gets a complete set of the technical documentation.",
    },
    {
      image: Interior3,
      title: "DESIGNER SUPERVISION & COMPLETION",
      content:
        "The work upon the project does not end when the drawing package and visualization are admitted by the customer. During the repair and construction works we control all the key processes from the quality of the operations and terms of furniture delivery to timely occupancy.",
    },
    {
      image: kitchenInterior4,
      title: "STATEMENT OF THE PROBLEM",
      content:
        "To start creating the project, we have to prepare in a certain way. Usually we hold one or several meetings with the clients and find out their ideas and requirements to the future design.",
    },
  ];
  const interiorImages = [
    {
      id: 1,
      title: "WELCOME TO TREEZ INTERIORS",
      subTitle:
        "GET IN TOUCH FASTEST GROWING INTERIOR DESIGNING COMPANY CONTEMPORARY INTETIOR DESIGN FOR YOU HOME AND OFFICE Highly Qualified Designers VARIETY OF AFFORDABLE LOOSE FURNITUTES PRODUCTS ",
      bg_img: Interior1,
    },
    {
      id: 2,
      title: "FURNITURES FOR ANY INTERIORS",
      subTitle: "AFFORDABLE INTERIORS FOR YOU HOME AND OFFICE",
      bg_img: Interior2,
    },
    {
      id: 3,
      title: "'GET PERSONALISED INTERIORS FOR YOUR LIFESTYLE'",
      subTitle: "FASTEST GROWING INTERIOR DESIGNING COMPANY",
      bg_img: Interior3,
    },
    {
      id: 1,
      title: "WELCOME TO TREEZ INTERIORS",
      subTitle:
        "GET IN TOUCH FASTEST GROWING INTERIOR DESIGNING COMPANY CONTEMPORARY INTETIOR DESIGN FOR YOU HOME AND OFFICE Highly Qualified Designers VARIETY OF AFFORDABLE LOOSE FURNITUTES PRODUCTS ",
      bg_img: kitchenInterior1,
    },
    {
      id: 2,
      title: "FURNITURES FOR ANY INTERIORS",
      subTitle: "AFFORDABLE INTERIORS FOR YOU HOME AND OFFICE",
      bg_img: kitchenInterior4,
    },
    {
      id: 3,
      title: "'GET PERSONALISED INTERIORS FOR YOUR LIFESTYLE'",
      subTitle: "FASTEST GROWING INTERIOR DESIGNING COMPANY",
      bg_img: kitchenInterior3,
    },
  ];

  return (
    <>
      <AppLayout isEnquiryForm isImageSlider ImgContent={interiorImages}>
        <Stack padding={"1rem"}>
          <Typography variant="h3" className={styles.typoHeading}>
            LIST OF SERVICE OFFERED
          </Typography>
          <Box padding={"1rem"} />
          <Box className={styles.stackContainer}>
            {contactCard?.map((item) => (
              <Card contactCard={item} />
            ))}
          </Box>
          <Box padding={"2rem"} />
          <Stack className={`${styles.ImgBox}`}>
            <Box className={styles.InBox}>
              <Box padding={"2rem"} />

              <Box className={styles.InBox2}>
                <Typography className={styles.TypoHeading2}>
                  5 YEARS OF EXPERIENCE
                </Typography>
              </Box>
              <Box padding={"1rem"} />
              <Box className={styles.InBox2}>
                <Typography className={styles.typoText}>
                  Since 2017, we have been providing great Interior works and
                  customer service for homeowners and commercial clients.
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Box padding={"2rem"} />
          <Typography variant="h3" className={styles.typoHeading}>
            HOW WE WORK
          </Typography>
          <Box padding={"2rem"} />
          <ContentWithCard data={how_we_work} />
          <Box padding={"2rem"} />
          <Typography variant="h3" className={styles.typoHeading}>
            OUR PARTNERS
          </Typography>
          <Box padding={"1rem"} />
          <Box className={[styles.layout]}>
            <ImgCard
              images={[
                Clients1,
                Clients2,
                Clients3,
                Clients4,
                Clients5,
                Clients6,
                Clients7,
              ]}
            />
          </Box>
        </Stack>
      </AppLayout>
    </>
  );
};

export default Interior;
