import { Box, Typography } from "@mui/material";
import Header from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import { ImageSlider } from "../ImageSlider/ImageSlider";
import EnquiryForm from "../../Forms/EnquiryForn/EnquiryForm";
import { useStyles } from "./AppLayout.styles";
import { useMobileView } from "../Hooks/useMobileView/useMobileView";
import GoogleMap from "../../Map/GoogleMap";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HaderImage } from "../../Assets";

const AppLayout = ({
  children,
  isImageSlider = false,
  isEnquiryForm = false,
  isGoogleMap = false,
  ImgContent,
  isLight,
  showImage,
  imageData = { img: HaderImage, title: "", subTitle: "" },
}) => {
  const styles = useStyles();
  const isMobile = useMobileView();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Header />
      <Box mt={"6rem"} autoscroll="true">
        {isImageSlider && <ImageSlider data={ImgContent} isLight={isLight} />}
        {showImage && (
          <Box
            sx={{
              backgroundColor: "grey",
              width: "100vw",
              height: "50rem",
              overflow: "hidden",
              position: "relative",
              "&>img": {
                position: "absolute",
                height: "100%",
                width: "100vw",
              },
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "black", // adjust opacity as needed
                zIndex: 2,
                opacity: 0.5,
              },
            }}
          >
            <img src={imageData?.img} />
            <Box className={styles.content} key={imageData?.id}>
              <Typography variant="h1" textAlign={"center"}>
                {imageData?.title}
              </Typography>
              <Typography
                variant="h2"
                mt={"2rem"}
                textAlign={"center"}
                className={styles.title}
              >
                {imageData?.subTitle}
              </Typography>
            </Box>
          </Box>
        )}
        <Box className={isEnquiryForm && styles.container}>
          <Box>{children}</Box>
          {isEnquiryForm && (
            <EnquiryForm containerClass={styles.authContainer} />
          )}
        </Box>
      </Box>
      {isGoogleMap && <GoogleMap />}
      <Footer />
    </>
  );
};

export default AppLayout;
