import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row !important",
    width: "80rem",
    height: "28rem",
    gap: "1rem",
    borderRadius: "0.5rem",
    boxShadow: "0 0 0.7rem",
    overflow: "hidden",
    cursor: "pointer",
    margin: "2rem",
    "&:hover": {
      "& $cardImg": {
        "& img": {
          transform: "scale(1.1)",
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column !important",
      width: "37rem",
      height: "45rem",
    },
  },
  cardImg: {
    overflow: "hidden",
    width: "40%",
    "& img": {
      transition: "transform 0.2s linear",
      width: "100%",
      aspectRatio: "1/1",
      transform: "scale(1)",
    },
    [theme.breakpoints.down("md")]: {
      "&>img": {
        height: "100%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&>img": {
        objectFit: "cover",
      },
    },
  },
  statusContainer: {
    "&>div": {
      padding: "0.5rem 1rem",
      borderRadius: "0.6rem",
      width: "fit-content",
      background: `${theme.palette.primary.main}ae`,
    },
  },
  details: {
    gap: "2rem",
    padding: "2rem 0",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "2rem",
      boxSizing: "border-box",
    },
  },
}));
