import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  titles: {
    alignItems: 'center',
    gap: '1rem',
    '&>div': {
      width: '70%',
      textAlign: 'center',

      '&:last-child': {
        marginBottom: '5rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&>div': {
        '&:first-child': {
          marginTop: '5rem',
        },
        width: '100%',
      },
    },
  },
}))
