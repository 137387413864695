import { Box, Icon, Stack, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../Theme/theme";
import { useStyles } from "./Services.styles";
import { Home_Automation, Interior, Interior_Design } from "../../Assets";
import { useNavigate } from "react-router-dom";

const services = [
  {
    id: 1,
    name: "Interior Design",
    icon: Interior,
    redirect: "interior",
  },
  {
    id: 2,
    name: "Home Automation",
    icon: Home_Automation,
    redirect: "home-automation",
  },
];

const Services = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const onCardClick = (data) => {
    navigate(data?.redirect);
  };
  return (
    <Box>
      <Stack className={styles.titles}>
        <Typography variant="h1" color={"text.main"}>
          Our Services
        </Typography>
      </Stack>
      <Stack direction={"row"} gap={"2rem"} justifyContent={"center"}>
        {services?.map((service) => (
          <Card data={service} onCardClick={onCardClick} />
        ))}
      </Stack>
    </Box>
  );
};

export default Services;

const Card = ({ data, onCardClick }) => {
  const { name, icon: Icon } = data;
  return (
    <Stack
      sx={{
        width: "25rem",
        height: "25rem",
        background: `${theme.palette.primary.main}ae`,
        boxShadow: "0.3rem 0.3rem 1rem",
        transition: "all 0.2s linear",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        cursor: "pointer",
        "&:hover": {
          transform: "translateY(-1.5rem)",
        },
        "& svg": {
          width: "6rem",
          height: "6rem",
          border: "1px solid ",
          "& path": {
            // fill: "white",
            // stroke: "white",
          },
        },
      }}
      onClick={() => onCardClick(data)}
    >
      <Icon />
      <Typography variant="h4">{name}</Typography>
    </Stack>
  );
};
