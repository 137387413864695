import React from "react";
import { Button as MuiButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    color: `${theme.palette.text.main} !important`,
    borderRadius: "3rem !important",
    height: "5rem",
    width: "20rem",
  },
}));

const Button = ({
  title = "Button",
  size = "large",
  variant = "contained",
  onClick = () => {},
  sx = {},
}) => {
  const styles = useStyles();
  return (
    <MuiButton
      size={size}
      variant={variant}
      onClick={onClick}
      sx={{ ...sx }}
      className={styles.button}
    >
      {title}
    </MuiButton>
  );
};

export default Button;
