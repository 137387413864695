import React from "react";
import { Carousel } from "../../CommonComponent/Carousel/Carousel";
import CarouselCard from "../../CommonComponent/Carousel/CarouselCard/CarouselCard";
import {
  BANGALORE_ROUTE,
  projectListingData,
} from "../../../Utils/utils-constant";

const ScrollCarousel = ({ type, color }) => {
  const location = type == BANGALORE_ROUTE ? "Bangalore" : "Hyderabad";
  return (
    <Carousel
      items={projectListingData[type]}
      renderItem={(item) => <CarouselCard data={item} type={type} />}
      title={`Latest & Featured Properties of ${location}`}
      subTitle={
        "The premium real estate brand in the city. Crafted according to your expectations."
      }
      interval={4000}
      visibleSlides={3.2}
      cardHeight={450}
      color={color}
      isDot={false}
    />
  );
};

export default ScrollCarousel;
