import { makeStyles } from "@mui/styles";
import {
  Apartment3,
  Apartment5,
  Apartment7,
  Background1,
  Commercial2,
  Contact_Page_Img,
  Villa2,
} from "../Assets/index";

export const useStyles = makeStyles((theme) => ({
  titles: {
    alignItems: "center",
    gap: "1rem",
    "&>div": {
      width: "70%",
      textAlign: "center",

      "&:last-child": {
        marginBottom: "5rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&>div": {
        "&:first-child": {
          marginTop: "5rem",
        },
        width: "100%",
      },
    },
  },
  box1: {
    // background: '#e5e5e5',
    background: `linear-gradient(#00000070 100%,#000000 100%), url(${Villa2})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  box2: {
    // background: '#e5e5e5',
    background: `linear-gradient(#00000070 100%,#000000 100%), url(${Apartment3})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  carouselLight: {
    background: theme.palette.background.main,
  },
  layout: {
    margin: "5rem 0",
    padding: "5rem 2rem",
  },
  
}));
