import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  leftArrow: {
    transform: "rotate(180deg)",
    marginRight: "2rem",
    cursor: "pointer",
    "& svg": {
      width: "2rem",
      height: "2rem",
    },
  },
  rightArrow: {
    cursor: "pointer",
    "& svg": {
      width: "2rem",
      height: "2rem",
    },
  },
  arrowDisabled: {
    cursor: "not-allowed",
    opacity: ".5",
  },
  content: {
    width: "70%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  launched_title: {
    textAlign: "center",
    // marginTop: '5rem !important',
  },
  launched_subTitle: {
    textAlign: "center",
    marginBottom: "5rem !important",
  },
  arrow: {
    background: "transparent",
    outline: "none",
    border: "none",
    padding: 0,
    marginRight: "-0.5rem",
    "& .theme_color": {
      fill: theme.palette.primary.main,
    },
    "& svg": {
      "& g": {
        "& line": {
          stroke: theme.palette.text.button_text,
        },
      },
    },
  },
  upperSection: {
    display: "flex",
    marginTop: "2rem",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "0 1em 0 0",
    },
  },
  layout_root: {
    // margin: '1.11rem',
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },

  view_all: {
    // position: 'absolute',
    // right: 5,
    width: "fit-content",
    // bottom: '-2.3rem',
    fontSize: "1.25rem",
    color: theme.palette.text.button_text,
    textDecoration: "none",
    background: theme.palette.primary.main,
    padding: "0.5rem 2rem",
    borderRadius: "0.3rem",
    "&:hover": {
      color: theme.palette.text.button_text,
    },
  },
  slide: {
    // paddingBottom: '18% !important',
  },
  dot: {
    borderRadius: "50%",
    padding: "0.4rem",
    marginRight: "0.5rem",
  },
  dotContainer: {
    textAlign: "center",
  },
}));
