import { Bangalore_apartment_details_data } from "./Apartments/Bangalore_apartment_details_data";
import { Bangalore_plots_details_data } from "./Plots/Bangalore_plots_details_data";
import { Bangalore_farmlands_details_data } from "./Farmlands/Bangalore_farmlands_details_data";
import { Bangalore_commercialSpace_details_data } from "./Commercial_Spaces/Bangalore_commercialSpace_details_data";
import { Bangalore_villas_details_data } from "./Villas/Bangalore_villas_details_data";

export const BangaloreProjectDetailsData = [
  ...Bangalore_apartment_details_data,
  ...Bangalore_commercialSpace_details_data,
  ...Bangalore_plots_details_data,
  ...Bangalore_farmlands_details_data,
  ...Bangalore_villas_details_data,
];
