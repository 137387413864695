import { Hyderabad_apartments_details_data } from "./Apartments/Hyderabad_apartments_details_data";
import { Hyderabad_commercialSpace_details_data } from "./Commercial_Spaces/Hyderabad_commercialSpace_details_data";
import { Hyderabad_farmlands_details_data } from "./Farmlands/Hyderabad_farmlands_details_data";
import { Hyderabad_plots_details_data } from "./Plots/Hyderabad_plots_details_data";
import { Hyderabad_villas_details_data } from "./Villas/Hyderabad_villas_details_data";

export const HyderabadProjectDetailsData = [
  ...Hyderabad_apartments_details_data,
  ...Hyderabad_commercialSpace_details_data,
  ...Hyderabad_farmlands_details_data,
  ...Hyderabad_plots_details_data,
  ...Hyderabad_villas_details_data,
];
