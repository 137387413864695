import {
  RKAgri1,
  AssetzM1,
  PurvaT1,
  BrigadeO1,
  EarthA1,
  AssetzF1,
} from "../../../../Components/Assets";

export const Bangalore_plots_list_data = [
  {
    id: "BAN401",
    project_name: "RK Agri Venture",
    type: "Plots",
    status: "Ongoing",
    location: "Budigere Cross, Bangalore",
    price: "4500/- per sqft",
    size: "30*40 // 30*50 // 40*60 // 1200-4000 sqft",
    bg_img: RKAgri1,
  },
  {
    id: "BAN402",
    project_name: "Assetz Group",
    type: "Plots",
    status: "Ongoing",
    location: "Off Hosa Road, Bangalore",
    price: "12000/- per sqft",
    size: "30*40 // 30*60 // 30*50 // 40*60 // 1200-4000 sqft",
    bg_img: AssetzM1,
  },
  {
    id: "BAN403",
    project_name: "Purvankara",
    type: "Plots",
    status: "Ongoing",
    location: "S.Medihalli , Sarjapura road",
    price: "7500/- per sqft",
    size: "1200 -2400sq.ft",
    bg_img: PurvaT1,
  },
  {
    id: "BAN404",
    project_name: "Brigade Group",
    type: "Plots",
    status: "Ongoing",
    location: "N H Devanahalli",
    price: "5640 - 5990 /- per sqft",
    size: "1500 - 2000sq.ft",
    bg_img: BrigadeO1,
  },
  {
    id: "BAN405",
    project_name: "GM Group",
    type: "Plots",
    status: "Ongoing",
    location: "IVC Road",
    price: "5650/- per sqft",
    size: "1200 - 2400sq.ft",
    bg_img: EarthA1,
  },
  {
    id: "BAN406",
    project_name: "Assetz Group",
    type: "Plots",
    status: "Ongoing",
    location: "N H Devanahalli",
    price: "5750/- per sqft",
    size: "1200 - 3300 Sq.Ft",
    bg_img: AssetzF1,
  },
];
