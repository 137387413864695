import { Box, Modal } from "@mui/material";
import React from "react";
import EnquiryForm from "../EnquiryForn/EnquiryForm";
import { CrossIcon } from "../../Assets";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    "&>div": {
      margin: "auto",
    },
  },
}));

const EnquiryFormDialog = ({
  openDialog = false,
  setOpenDialog = () => {},
  onCardClick = () => {},
}) => {
  const styles = useStyles();
  const handleFormClick = () => {
    localStorage.setItem("id", true);
    setOpenDialog(false);
    onCardClick();
  };
  return (
    <Modal open={openDialog} onClose={() => setOpenDialog(false)}>
      <Box
        sx={{
          height: "100vh",
          "& svg": {
            position: "absolute",
            top: "5rem",
            right: "3rem",
            cursor: "pointer",
          },
        }}
      >
        <EnquiryForm
          containerClass={styles.form}
          callback={() => handleFormClick()}
        />{" "}
        <CrossIcon onClick={() => setOpenDialog(false)} />
      </Box>
    </Modal>
  );
};

export default EnquiryFormDialog;
