import { Box, Stack, Typography } from "@mui/material";
import {
  EmailIcon,
  Facebook,
  Instagram,
  Linkedin,
  PhoneIcon,
  RightArrow,
  Twitter,
  TreezLogo,
  LocationIcon,
} from "../Assets/index";
import { useStyles } from "./Footer.styles";
import { useMobileView } from "../CommonComponent/Hooks/useMobileView/useMobileView";
import { Link } from "../CommonComponent/Link/Link";
import SocialIcons from "./SocialIcons";

export const Footer = () => {
  const styles = useStyles();
  const isMobileDisplay = useMobileView();
  const quickLink = [
    { label: "Home", link: "/" },
    // { label: "Gallery", link: "/gallery" },
    { label: "About", link: "/about" },
    // { label: "Projects", link: "/projects" },
    // { label: "Services", link: "/services" },
    { label: "Contacts", link: "/contacts" },
    { label: "Interior Design", link: "/interior" },
    { label: "Home Automation", link: "/home-automation" },
  ];
  const extraLink = [
    // { label: "Privacy & Policy", link: "/privacy&policy" },
    // { label: "Payment Method", link: "/payment-method" },
    // { label: "Our Services", link: "/services" },
    // { label: "About", link: "/about" },
    // { label: "Reviews", link: "/review" },
    { label: "Interior Design", link: "/interior-design" },
    { label: "Home Automation", link: "/home-automation" },
  ];

  const address = [
    {
      icon: LocationIcon,
      address:
        "#42/14, Lakshmi Nivasam, 1st Floor, 1st Cross, Singayanapalya,Bangalore - 560048",
      title: "Bangalore Location",
    },
    {
      icon: LocationIcon,
      address:
        " C.W.S One, Treez Enterprise, 3rd Floor, plot no. 40,41,42,Survey No 054, Kondapur, Hyderabad - 500084",
      title: "Hyderabad Location",
    },
  ];

  const LinkWithArrow = ({ title = "", link = [] }) => {
    return (
      <Box>
        <Typography variant="h4">{title}</Typography>
        <Stack className={styles.links}>
          {link?.map((item) => (
            <Box
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "1.7rem",
                },
              }}
            >
              <RightArrow />
              <Link to={item?.link}>{item?.label}</Link>
            </Box>
          ))}
        </Stack>
      </Box>
    );
  };
  return (
    <Box className={styles.main}>
      <Box className={styles.container}>
        <Box className={styles.details}>
          <Box className={styles.logo}>
            <img src={TreezLogo} />
          </Box>
          <Typography variant="h3">
            Treez Enterprise path to luxury real estate, interior & Home
            automation service has been a bit long and winding, we understand
            that buying or selling a home is more than just a transaction. it’s
            a life changing experience.
          </Typography>
        </Box>
        <LinkWithArrow title="Quick Links" link={quickLink} />
        {/* <LinkWithArrow title="Extra Links" link={extraLink} /> */}
        <Box className={styles.location}>
          <Typography variant="h3">Our Locations</Typography>
          <Box mt="2rem">
            <Box>
              {address?.map((item) => (
                <>
                  <Typography mt="2rem" variant="h4">
                    {item?.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    mt="2rem"
                    display={"flex"}
                    gap={"1rem"}
                  >
                    <span>
                      {" "}
                      <img src={item?.icon} />
                    </span>{" "}
                    <span>{item?.address}</span>
                  </Typography>
                </>
              ))}
            </Box>
          </Box>
        </Box>

        <Box className={styles.info}>
          <Typography variant="h3">Contact Info</Typography>
          <Stack direction={"row"} my="1rem">
            <PhoneIcon />
            <Box>
              <Typography variant="h4">+91 - 6364585353,</Typography>
              <Typography variant="h4"> +91 - 6364795353</Typography>
            </Box>
          </Stack>

          <Stack direction={"row"}>
            <EmailIcon className={styles.emailIcon} />
            <Box>
              <Typography variant="h4">Info@treezenterprise.com </Typography>
              <Typography variant="h4">Sales@treezenterprise.com </Typography>
              <Typography variant="h4">
                {" "}
                Support@treezenterprise.com{" "}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box>
        <SocialIcons />

        <Typography className={styles.credit} variant="h3">
          Created By Treez Enterprise | All Rights reserved
        </Typography>
      </Box>
    </Box>
  );
};
