import { useStyles } from "../ProjectDetails.styles";
import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { CrossIcon } from "../../../Assets";

export const ProjectImage = ({ data }) => {
  const styles = useStyles();
  const [selectedImg, setSelectedImg] = useState(null);

  const closeModal = () => {
    setSelectedImg(null);
  };

  return (
    <>
      <Stack className={`${styles.box}`} gap="2rem">
        <Typography variant="h2">Project Image</Typography>
        <Divider />
        <Stack direction={"row"} gap="3rem" flexWrap={"wrap"}>
          {data?.map((image) => (
            <Box
              className={styles.imageContainer}
              onClick={() => setSelectedImg(image)}
            >
              <img src={image} />
            </Box>
          ))}
        </Stack>
      </Stack>
      <Modal
        open={selectedImg}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.imgModal}>
          <img src={selectedImg} />
          <CrossIcon className={styles.CrossIcon} onClick={closeModal} />
        </Box>
      </Modal>
    </>
  );
};
