import { Box, Stack } from "@mui/material";
import React from "react";
import { useStyles } from "./ImgCard.styles";
import "pure-react-carousel/dist/react-carousel.es.css";
import { ScrollComponent } from "../../CommonComponent/ScrollComponent/ScrollComponent";

const defaultData = {
  id: "1",
};
const ImgCard = ({ data = defaultData, images = [] }) => {
  const styles = useStyles();

  return (
    <Stack direction={"row"} flexWrap={"wrap"} className={styles.parent}>
      {images.map((img, index) => {
        return (
          <ScrollComponent>
            <Box key={index} className={styles.container}>
              <Box className={styles.cardImg}>
                <img src={img} alt=" " />
              </Box>
            </Box>
          </ScrollComponent>
        );
      })}
    </Stack>
  );
};
export default ImgCard;
