import { Box, Typography } from "@mui/material";
import React from "react";
import { Carousel } from "../../CommonComponent/Carousel/Carousel";
import { theme } from "../../Theme/theme";
import { useMobileView } from "../../CommonComponent/Hooks/useMobileView/useMobileView";
import { useStyles } from "./ContentWithCard.styles";

const ContentWithCard = ({ data }) => {
  const isMobile = useMobileView();
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <Carousel
        items={data}
        renderItem={(item, id) => <Card item={item} id={id} />}
        cardHeight={500}
        visibleSlides={2}
        cardWidth={isMobile ? 300 : 350}
        isDot={false}
      />
    </Box>
    // </Box>
  );
};

export default ContentWithCard;

const Card = ({ item, id }) => {
  const styles = useStyles();
  const { image, title, content } = item;

  return (
    <Box className={styles.card}>
      <Box sx={{}} className={styles.imgContainer}>
        <img src={image} />
      </Box>
      <Box className={styles.content}>
        <Typography variant="h2">{title}</Typography>
        <Typography mt="3rem">{content}</Typography>
        <Typography variant="h4" color={"text.main"} className={styles.index}>
          {id + 1}
        </Typography>
      </Box>
    </Box>
  );
};
