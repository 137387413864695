import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    padding: "0.47rem 1.00rem",
    borderRadius: "6px",
    background: `${theme.palette.background.dark}10`,
    height: "3.5rem",
    width: "30rem !important",
    "& svg": {
      width: "1.26rem",
      height: "1.26rem",
    },
    "& .MuiInputBase-input": {
      fontSize: "1.11rem",
    },
  },
  customInput: {
    height: "3.5rem",
  },
}));

export default useStyles;
