import { useStyles } from "../ProjectDetails.styles";
import { Divider, Stack, Typography } from "@mui/material";
import React from "react";

export const AboutProject = ({ aboutData }) => {
  const styles = useStyles();

  return (
    <Stack gap={"1rem"} sx={{}} className={`${styles.box}`}>
      {/* <Typography variant="h2">About {aboutData?.project_name}</Typography> */}
      <Typography variant="h2">About the project</Typography>
      <Divider />
      <Typography variant="h6">{aboutData?.description}</Typography>
    </Stack>
  );
};
