import { Stack } from "@mui/material";
import React from "react";
import { useStyles } from "./Footer.styles";
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  WhatsApp,
  Youtube,
} from "../Assets/index";
import { Link } from "../CommonComponent/Link/Link";
import { BANGALORE_ROUTE, HYDERABAD_ROUTE } from "../../Utils/utils-constant";
import { socialLinks } from "../../Utils/Data/SocialLinksData";

// const bangaloreLinks = {
//   instagram: "https://www.instagram.com/treez_enterprise/",
//   facebook: "https://www.facebook.com/treezenterprise1",
//   linkedin: "https://linkedin.com/company/79067980/admin",
//   youtube: "https://www.youtube.com/@treezreality6363",
//   whatsapp: "https://www.youtube.com/@treezreality6363",
// };
// const hyderabadLinks = {
//   instagram: "https://www.instagram.com/treez_enterprise/",
//   facebook: "https://www.facebook.com/treezenterprise1",
//   linkedin: "https://www.linkedin.com/company/79067980/admin",
//   youtube: "https://www.youtube.com/@treezreality6363",
//   whatsapp: "https://www.youtube.com/@treezreality6363",
// };

const links = socialLinks;

const SocialIcons = ({
  direction = "row",
  isChat = false,
  className,
  location = BANGALORE_ROUTE,
}) => {
  const styles = useStyles();
  const {
    instagram,
    facebook,
    youtube,
    linkedin,
    whatsapp = { link: "https://wa.me/6364585353" },
  } = links[location];

  return (
    <Stack
      direction={direction}
      className={[
        styles.socialIcon,
        className ? className : "",
        isChat ? styles.chatContainer : "",
      ]}
    >
      <Link to={whatsapp?.link} target="_blank" className={styles.whatsapp}>
        <WhatsApp />
      </Link>
      <Link to={instagram?.link} target="_blank" className={styles.instagram}>
        <Instagram />
      </Link>
      <Link to={facebook?.link} target="_blank" className={styles.facebook}>
        <Facebook />
      </Link>
      <Link to={linkedin?.link} target="_blank" className={styles.linkedin}>
        <Linkedin />
      </Link>
      <Link to={youtube?.link} target="_blank" className={styles.twitter}>
        <Youtube />
      </Link>
    </Stack>
  );
};

export default SocialIcons;
