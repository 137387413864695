import { Box, Typography } from "@mui/material";
import { useStyles } from "./Card.styles";

const Card = ({
  contactCard = { name: "cardName", icon: "cardImage", content: "content" },
}) => {
  const styles = useStyles();
  const { id, name, icon, content } = contactCard;
  return (
    <Box key={id} alignItems={"left"}>
      <Box className={styles.LinkBox}>
        <img src={icon} />
        <Typography className={styles.NmaeTypo}>{name}</Typography>
        <Typography>{content}</Typography>
      </Box>
    </Box>
  );
};

export default Card;
