import { Link } from "../CommonComponent/Link/Link";
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Modal,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useStyles } from "./Header.styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LinkContainer = ({ isMobile }) => {
  const styles = useStyles();
  return (
    <Stack alignItems={"center"} className={` ${styles.linkContainer}`}>
      <Link to="/">HOME</Link>
      {/* <Link to='/gallery'>GALLERY</Link> */}
      <Link to="/about">ABOUT</Link>
      {/* <Link to='/projects'>PROJECTS</Link> */}
      <ServicesLink />
      <Link to="/contacts">CONTACT</Link>
    </Stack>
  );
};

export default LinkContainer;

const ServicesLink = () => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  return (
    <Box
      onMouseLeave={handlePopoverClose}
      onMouseEnter={handlePopoverOpen}
      className={styles.serviceWrapper}
    >
      <Box aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
        <Typography>SERVICES</Typography>
      </Box>
      <Box
        className={[open ? styles.show : styles.hide, styles.parent]}
        onMouseLeave={handlePopoverClose}
        onMouseEnter={handlePopoverOpen}
      >
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            navigate("/interior");
          }}
        >
          INTERIOR
        </Typography>
        <Divider />
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            navigate("/home-automation");
          }}
        >
          HOME AUTOMATION
        </Typography>
      </Box>
    </Box>
  );
};
