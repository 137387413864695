import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    // display: 'flex',
    // justifyContent: 'space-between',
    margin: "5rem 0",
    position: "relative",
    boxSizing: "border-box",
    "&>div:first-child": {
      width: "75%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    "&div:last-child": {
      width: "25%",
    },
  },

  authContainer: {
    position: "absolute",
    right: "6rem",
    bottom: "2rem",
    "&>div:first-child": {
      position: "sticky",
      top: "10rem",
    },
    [theme.breakpoints.down("md")]: {
      position: "static",
      justifyContent: "center",
      "&>div:first-child": {
        position: "static",
      },
    },
  },
  content: {
    position: "absolute",
    top: "50% !important",
    left: "50%",
    transform: "translate(-50%,-50%)",
    overflow: "hidden",
    zIndex: 2,
    "&>div": {
      top: "50%",
      left: "50%",
      color: theme.palette.text.main,
      letterSpacing: "0.2rem",
      textShadow: "0.4rem 0.4rem 0.2rem gray",
    },

    [theme.breakpoints.down("sm")]: {
      top: "2rem",
      width: "100%",
      textAlign: "center",
      marginLeft: "0",
    },
  },
}));
