import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { Divider, Box, Typography, Stack } from "@mui/material";
import { useStyles } from "./About.styles";
// import { useNavigate } from "react-router-dom";
import {
  Certificate,
  Int1,
  TreezVideo,
  Int2,
  Int3,
  TreezPartnerLogo1,
  TreezPartnerLogo2,
  TreezPartnerLogo3,
  TreezPartnerLogo4,
  TreezPartnerLogo5,
  TreezPartnerLogo6,
  TreezPartnerLogo7,
  TreezPartnerLogo8,
  TreezPartnerLogo9,
  Verfied_Account,
  Background2,
  Background8,
} from "../Assets/index";
import ImgCard from "./Img/ImgCard";
import { ScrollComponent } from "../CommonComponent/ScrollComponent/ScrollComponent";
import ContentCard from "./ContentCard/ContentCard";
import { PropertiesComponent } from "../Properties/Properties";

const About = () => {
  const styles = useStyles();
  const contentData = [
    {
      id: 1,
      heading: "ABOUT US",
      content: [
        "Treez Eneterprises path to luxury real estate, interior & Home automation service has been a bit long and winding, we understand that buying or selling a home is more than just a transaction ;it’s a life changing experience.That’s why our team of highly-seasoned real estate professionals is dedicated to providing exceptional , personalized services to all our clients.",
        "We take great pride in the relationship we build & always work relentlessly on the client’s behalf to help them achieve their real estate goals.",
      ],
    },
    {
      id: 2,
      heading: "OUR MISSION & VISION",
      content: [
        "We promote only the projects which are approved by RERA & which are bind under the norms of RERA located at the most happening places in Bangalore. That’s why our extensive knowledge & commitment to providing only the best & most timely information to our clients, we are your go-to source for real estate industry insight & advice.",
        "As part of your buying a home we belive every home should have irresistible stylie showcasing timeless, classic & one of a kind pieces of design. We’ll fully brand our interior design to reflect your home a unique identity, help you select material & furnishing, provide you with detailed specification of our design to guarantee a successful execution, and ensure that the final product earns your total approval.",
        "We also follow the technology and upgrade your home with the home automation, as the automation has become a basic necessary for every individual to secure in smart ways and comfort your home with safety & energy efficiency solution. ",
      ],
    },
    {
      id: 3,
      heading: "LEGAL SERVICES",
      content: [
        "Empowering clients with expert legal guidance, our company offers a comprehensive suite of legal services. From adept contract drafting to strategic litigation support, we are committed to safeguarding your rights and interests. Our experienced legal team navigates complexities with precision, ensuring thorough representation in every legal matter. Trust us for personalized solutions, ethical counsel, and a steadfast commitment to your legal well-being.",
      ],
    },
    {
      id: 4,
      heading: "HOME LOANS SERVICES",
      content: [
        "Bringing comfort to your doorstep, our company specializes in comprehensive back home services. Whether it's skilled maintenance, efficient repairs, or personalized care, we ensure that your homecoming is met with convenience. Our dedicated team is committed to creating a worry-free environment, allowing you to return to a well-maintained and welcoming home. Count on us for reliable and tailored back home services, turning your homecoming into a seamless and enjoyable.",
      ],
    },
  ];

  const headingData = [
    "WHY TREEZ ENTERPRISE?",

    "LEGAL SERVICES",

    "HOME LOANS SERVICES",

    "INTERIOR & HOME AUTOMATION SERVICES",

    "PROPERTY MANAGEMENT SERVICES",
  ];
  return (
    <AppLayout
      isEnquiryForm
      showImage
      imageData={{
        img: Background8,
        title: "ABOUT US",
      }}
      isGoogleMap
    >
      <Stack className={styles.parent}>
        <Stack direction="row" spacing={0}></Stack>
        <ScrollComponent variant="slide_left">
          <ContentCard contentData={contentData[0]} />
        </ScrollComponent>
        <Box padding={"1rem"} />
        <ScrollComponent variant="slide_left">
          <ContentCard contentData={contentData[1]} />
        </ScrollComponent>
        <Box padding={"1rem"} />
        <Box className={styles.stackBox}>
          <Typography variant="h3" className={styles.typoHeading}>
            <ScrollComponent> {headingData[0]} </ScrollComponent>
          </Typography>
          <Divider />
          <Box py={1} />
          <Box className={styles.InBox3}>
            <ScrollComponent>
              <img src={Int1} className={styles.imgBox2} />
            </ScrollComponent>
            <ScrollComponent>
              {/* <img src={Int1} className={styles.imgBox2} /> */}

              <video
                className={styles.imgBox2}
                controls
                autoPlay={true}
                muted={true}
              >
                <source src={TreezVideo} type="video/mp4" />
              </video>
            </ScrollComponent>
          </Box>
          <Box padding={"1rem"} />
          <PropertiesComponent
            cardClass={styles.cardClass}
            className={styles.className}
          />
        </Box>
        <Box padding={"1rem"} />
        <ScrollComponent variant="slide_left">
          <ContentCard contentData={contentData[2]} />
        </ScrollComponent>
        <Box padding={"1rem"} />
        <ScrollComponent variant="slide_left">
          <ContentCard contentData={contentData[3]} />
        </ScrollComponent>
        <Box padding={"1rem"} />

        <ScrollComponent>
          <Box className={styles.stackBox}>
            <Typography variant="h3" className={styles.typoHeading}>
              <ScrollComponent> {headingData[3]} </ScrollComponent>
            </Typography>
            <Divider />
            <Box padding={"1rem"} />

            <ScrollComponent>
              <Box className={styles.InBox3}>
                <img src={Int2} className={styles.imgBox3} />
              </Box>
            </ScrollComponent>
            <Box padding={"1rem"} />
            <ScrollComponent>
              <Box
                style={{ display: "flex", gap: "1rem", flexDirection: "row" }}
              >
                <img src={Verfied_Account} className={styles.tickIcon} />
                <Typography variant="h6">
                  Elevate your living spaces with our expert interior services.
                  From conceptual design to meticulous execution, our skilled
                  team transforms your vision into a harmonious and stylish
                  reality. We specialize in creating personalized interiors that
                  reflect your taste and lifestyle. Whether it's a single room
                  or an entire home, our commitment is to enhance functionality
                  and aesthetics, delivering a space that truly feels like home.
                  Experience the art of interior transformation with our
                  dedicated services & experience.
                </Typography>
              </Box>
            </ScrollComponent>
          </Box>
        </ScrollComponent>
        <Box padding={"1rem"} />
        <ScrollComponent>
          <Box className={styles.stackBox}>
            <Typography variant="h3" className={styles.typoHeading}>
              <ScrollComponent> {headingData[4]} </ScrollComponent>
            </Typography>
            <Divider />
            <Box padding={"1rem"} />

            <ScrollComponent>
              <Box className={styles.InBox3}>
                <img src={Int3} className={styles.imgBox3} />
              </Box>
            </ScrollComponent>
            <Box padding={"1rem"} />
            <ScrollComponent>
              <Box
                style={{ display: "flex", gap: "1rem", flexDirection: "row" }}
              >
                <img src={Verfied_Account} className={styles.tickIcon} />
                <Typography variant="h6">
                  Streamlining property ownership, our company takes pride in
                  providing comprehensive property management services. From
                  tenant relations to maintenance oversight, we handle every
                  aspect of property care. Our dedicated team ensures optimal
                  occupancy rates, timely rent collection, and proactive
                  maintenance, allowing property owners to enjoy a hands-off and
                  stress-free investment experience. Trust us to safeguard your
                  property, maximize its value, and provide a seamless
                  management solution tailored to your needs.
                </Typography>
              </Box>
            </ScrollComponent>
          </Box>
        </ScrollComponent>

        <Box padding={"1rem"} />
        <ScrollComponent>
          <Box
            className={styles.stackBox}
            styles={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" className={styles.typoHeading}>
              OUR PARTNERS
            </Typography>
            <Divider />
            <Box padding={"1rem"} />
            <Box className={[styles.layout, styles.box, styles.carouselLight]}>
              <ImgCard
                images={[
                  TreezPartnerLogo1,
                  TreezPartnerLogo2,
                  TreezPartnerLogo3,
                  TreezPartnerLogo4,
                  TreezPartnerLogo5,
                  TreezPartnerLogo6,
                  TreezPartnerLogo7,
                  TreezPartnerLogo8,
                  TreezPartnerLogo9,
                ]}
              />
            </Box>
          </Box>
        </ScrollComponent>
        <Box py={2}>{/* <Divider /> */}</Box>
        <ScrollComponent>
          <Box className={styles.stackBox}>
            <Typography variant="h3" className={styles.typoHeading}>
              AWARDS AND RECOGNITION
            </Typography>
            <Divider />
            <Box padding={"1rem"} />
            <ScrollComponent>
              <Box className={styles.InBox3}>
                <img src={Certificate} className={styles.imgbox1} />
              </Box>
            </ScrollComponent>
          </Box>
        </ScrollComponent>
      </Stack>
    </AppLayout>
  );
};

export default About;
