import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: "0.5rem",
    padding: "2rem",
    boxShadow: "0rem 0rem 0.3rem",
  },
  header: {},
  container: {
    padding: "0rem 13rem 5rem 5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },

  //project Table
  table: {
    "& .MuiTableCell-root": {
      border: "0.01rem solid",
    },
  },
  evenRow: {},
  oddRow: {
    background: `${theme.palette.primary.main}50`,
  },
  headerTable: {
    "& $thead": {
      background: theme.palette.primary.main,
      "& div": {
        color: theme.palette.text.main,
      },
    },
    "& $evenRow": {
      background: `${theme.palette.primary.main}50`,
    },
    "& $oddRow": {
      background: "unset",
    },
  },
  thead: {},

  //project Image

  imageContainer: {
    boxShadow: "0 0 0.4rem",
    width: "19rem",
    height: "19rem",
    cursor: "pointer",
    "&>img": {
      width: "inherit",
      height: "inherit",
      aspectRatio: "1/1",
      objectFit: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  imgModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    boxShadow: "0 0 1rem",

    "&>img": {
      aspectRatio: "16/9",
      width: "100%",
      objectFit: "cover",
    },
  },

  CrossIcon: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    cursor: "pointer",
  },
}));
