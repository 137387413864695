import React from "react";
import ProjectCard from "./ProjectCard/ProjectCard";
import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { Stack } from "@mui/material";
import { ScrollComponent } from "../CommonComponent/ScrollComponent/ScrollComponent";
import { theme } from "../Theme/theme";

const Projects = ({
  projectList = [],
  handleCardClick = () => {},
  getImageData,
}) => {
  return (
    <AppLayout isEnquiryForm showImage imageData={getImageData()}>
      <Stack
        gap="2rem"
        sx={{
          padding: "1rem 5rem 10rem",
          [theme.breakpoints.down("sm")]: {
            padding: "0",
            alignItems: "center",
          },
        }}
      >
        {projectList?.map((project) => (
          <ScrollComponent variant="slide_left">
            <ProjectCard data={project} onClick={handleCardClick} />
          </ScrollComponent>
        ))}
      </Stack>
    </AppLayout>
  );
};

export default Projects;
