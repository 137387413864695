import {
  FBColored,
  Facebook,
  InstaColored,
  Instagram,
  Linkedin,
  LinkedinColored,
  Youtube,
} from "../../Components/Assets";
import { BANGALORE_ROUTE, HYDERABAD_ROUTE } from "../utils-constant";

const bangaloreLinks = {
  instagram: {
    name: "Instagram",
    link: "https://www.instagram.com/treez_enterprise/",
    icon: InstaColored,
  },
  facebook: {
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=61552497347630",
    icon: FBColored,
  },
  linkedin: {
    name: "Linkedin",
    link: "https://linkedin.com/company/79067980/admin",
    icon: LinkedinColored,
  },
  youtube: {
    name: "Youtube",
    link: "https://www.youtube.com/@treezreality6363",
    icon: Youtube,
  },
};
const hyderabadLinks = {
  instagram: {
    name: "Instagram",
    link: "https://www.instagram.com/treezhyderabad/",
    icon: InstaColored,
  },
  facebook: {
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=100093479577166&ref=xav_ig_profile_web ",
    icon: FBColored,
  },
  linkedin: {
    name: "Linkedin",
    link: "https://www.linkedin.com/company/79067980/admin",
    icon: LinkedinColored,
  },
  youtube: {
    name: "Youtube",
    link: "https://www.youtube.com/@TreezRealityHYD",
    icon: Youtube,
  },
};

export const socialLinks = {
  [BANGALORE_ROUTE]: bangaloreLinks,
  [HYDERABAD_ROUTE]: hyderabadLinks,
};
