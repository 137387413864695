import Home from "./Components/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Chat from "./Components/CommonComponent/Chat/Chat";
import LandingPage from "./Components/LandingPage/LandingPage";
import Projects from "./Components/Projects/Projects";
import Contact from "./Components/Contact/Contact";
import About from "./Components/About/About";
import Interior from "./Components/Interior/Interior";

import ProjectDetails from "./Components/Projects/ProjectDetails/ProjectDetails";
import EmptyComponent from "./Components/EmptyComponent/EmptyComponent";
import { BANGALORE_ROUTE } from "./Utils/utils-constant";
import Properties from "./Components/Properties/Properties";
import HomeAutoMation from "./Components/HomeAutomation/HomeAutoMation";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/gallery" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/services" element={<Home />} />
          <Route path="/home/:location" element={<Home />} />
          <Route
            path="/project/details/:projectId"
            element={<ProjectDetails />}
          />
          <Route path="/interior" element={<Interior />} />
          <Route path="/home-automation" element={<HomeAutoMation />} />
          <Route path={"/property/:location"} element={<Properties />} />
          <Route
            path={"/property/:location/:propertyType"}
            element={<Home />}
          />

          <Route path="*" element={<EmptyComponent />} />
        </Routes>
        <Chat />
      </BrowserRouter>
    </>
  );
}

export default App;
