import { Bangalore_apartment_list } from "./Apartments/Bangalore_apartment_list";
import { Bangalore_commercialSpace_list_data } from "./Commercial_Spaces/Bangalore_commercialSpace_list_data";
import { Bangalore_farmlands_list_data } from "./Farmlands/Bangalore_farmlands_list_data";
import { Bangalore_plots_list_data } from "./Plots/Bangalore_plots_list_data";
import { Bangalore_villas_list_data } from "./Villas/Bangalore_villas_list_data";

export const BangloreProjectList = [
  ...Bangalore_apartment_list,
  ...Bangalore_commercialSpace_list_data,
  ...Bangalore_plots_list_data,
  ...Bangalore_farmlands_list_data,
  ...Bangalore_villas_list_data,
];
