import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useStyles } from "./Header.styles";
import { Link } from "../CommonComponent/Link/Link";
import { useMobileView } from "../CommonComponent/Hooks/useMobileView/useMobileView";
import MobileHeader from "./MobileHeader";
import { SearchIcon, TreezLogo } from "../Assets/index";
import LinkContainer from "./LinkContainer";
import { useNavigate } from "react-router-dom";
import SearchBar from "../CommonComponent/SearchBar/SearchBar";

const Header = () => {
  const styles = useStyles();
  const isMobile = useMobileView();
  const navigate = useNavigate();
  const setSearchModal = () => {};
  const updateSearchKey = () => {};

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      className={styles.container}
    >
      <Stack direction={"row"} gap="5rem" alignItems={"center"}>
        <img
          src={TreezLogo}
          className={styles.logo}
          onClick={() => navigate("/")}
        />

        {/* {!isMobile && <SearchBar isAutoComplete />} */}
      </Stack>
      {isMobile ? <MobileHeader /> : <LinkContainer />}
    </Stack>
  );
};

export default Header;
