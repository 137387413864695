import { BangaloreProjectDetailsData } from "./Data/Banglore/BangaloreProjectDetailsData";
import { BangloreProjectList } from "./Data/Banglore/BangaloreProjectList";
import { HyderabadProjectDetailsData } from "./Data/Hyderabad/HyderabadProjectDetailsData";
import { HyderabadProjectList } from "./Data/Hyderabad/HyderabadProjectList";

export const BANGALORE_ROUTE = "property-in-bangalore";
export const HYDERABAD_ROUTE = "property-in-hyderabad";

//Listing data constant

export const projectListingData = {
  [BANGALORE_ROUTE]: BangloreProjectList,
  [HYDERABAD_ROUTE]: HyderabadProjectList,
};

export const allProjectList = [...BangloreProjectList, ...HyderabadProjectList];

export const allProjectDetailsList = [
  ...BangaloreProjectDetailsData,
  ...HyderabadProjectDetailsData,
];
