import {
  Kesineni1,
  Kesineni2,
  PraneethG1,
  PraneethG2,
  PraneethG3,
  PraneethG4,
  PraneethG5,
  PraneethG6,
  PraneethG7,
  PraneethG8,
  PraneethG9,
} from "../../../../Components/Assets";

export const Hyderabad_villas_details_data = [
  {
    id: "HAD501",
    project_name: "Praneet Pranav Group",
    about:
      "Gagillapur’s Most Lavish Project, Spread over a vast expanse of 70 acres, Grove Park stands as an elite villa community boasting 884 triplex villas. Nestled just 3 km from ORR Exit 5 in Gagillapur, this grand haven is an oasis of tranquillity amidst the urban hustle. The grand villa community with its 6-acres private lake is a serene, scenic and lavish home that delivers a leisurely and vacation-like atmosphere. It's a home crafted for those who love living with a touch of serenity and indulgence.",
    project_details: [
      {
        name: "Project Location",
        value: "Chetlapotharam.Near to Dhindikal ,Gagillapur",
      },
      {
        name: "Total Land Area",
        value: "70.49 Acres",
      },
      {
        name: "Number of Units",
        value: "884 Units",
      },
      {
        name: "Unit Variants",
        value: " 2176.00 sq.ft. - 4566.00 sq.ft. ",
      },
      {
        name: "Possession Time",
        value: "Sep 2025 Onwards",
      },
      {
        name: "RERA Approved",
        value: "P02200004882|-1P02200004882|-1",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [
      PraneethG1,
      PraneethG2,
      PraneethG3,
      PraneethG4,
      PraneethG5,
      PraneethG6,
      PraneethG7,
      PraneethG8,
      PraneethG9,
    ],
  },
  {
    id: "HAD502",
    project_name: "Kesineni Villa",
    about:
      "Kesineni Villas is an exclusive Residential project located in dundigal, hyderabad. it is developed by Kesineni Developers who are one of the renowned developers in hyderabad. the current status of this project is Launch. it has a total of 455 units. Kesineni features villas in various configurations such as villas. Residential sizes vary from 2195 sqft - 2650 sqft. These villas are available at a minimum price of 1.20 Cr. The total area of this project is 80 Acres. connectivity to the rajiv gandhi international airport and an ample amenity package makes this residential project one-of-a-kind.",
    project_details: [
      {
        name: "Project Location",
        value: "Dungigal North Hyderabad",
      },
      {
        name: "Total Land Area",
        value: "80 Acres",
      },
      {
        name: "Number of Units",
        value: "455 Units",
      },
      {
        name: "Unit Variants",
        value: "2195.00 sq.ft. - 2650.00 sq.ft.",
      },
      {
        name: "Possession Time",
        value: "Nov 2025 Onwards",
      },
      {
        name: "RERA Approved",
        value: "Awaiting",
      },
    ],
    project_price: [
      {
        Phase: "Phase 1 (47.23 Acres)",
        size: "167 Sqyds with 2195 Sqft",
        price: "₹ 1.2 Cr*",
      },
      {
        Phase: "Phase 2 (33.34 Acres)",
        size: "200 Sqyds with 2600 Sqft",
        price: "Price On Request",
      },
    ],
    amenities: [
      "gym",
      "club_house",
      "swimming_pool",
      "indoor_games",
      "basket_ball",
      "roads",
      "tennis_courts",
      "flower_garden",
      "kids_club",
      "kids_play_area",
      "amphitheater",
      "retail_shops",
    ],
    project_images: [Kesineni1, Kesineni2],
  },
];
