import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0rem 3rem",
    boxShadow: "0.3rem 0.3rem 1rem",
    position: "fixed",
    width: "calc(100vw - 6rem)",
    top: 0,
    background: theme.palette.background.main,
    zIndex: "1000",
  },
  logo: {
    height: "5.6rem",
    cursor: "pointer",
  },
  linkContainer: {
    gap: "2rem",
    flexDirection: "row !important",
    "& a": {
      textDecoration: "none",
      "&>div:hover": {
        color: theme.palette.primary.main,
      },
      "&>span": {
        color: theme.palette.text.dark,
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "5rem",
      alignItems: "center",
      gap: "2rem",
      flexDirection: "column !important",

      "& a>span": {
        color: theme.palette.text.dark,
      },
    },
  },
  crossIcon: {
    position: "absolute",
    top: "2rem",
    right: "2rem",
    width: "2rem",
    height: "2rem",
    "& path": {
      fill: theme.palette.text.dark,
    },
    cursor: "pointer",
  },
  burgerIcon: {
    "& path": {
      fill: theme.palette.text.dark,
    },
    cursor: "pointer",
  },
  serviceWrapper: {
    position: "relative",
    padding: "2rem 0",
  },
  parent: {
    position: "absolute",
    top: "6rem",
    right: "0rem",
    background: theme.palette.background.main,
    width: "20rem",
    boxShadow: "0.1rem 0.1rem 1rem",
    zIndex: "10",
    transition: "all 0.2s linear",
    overflow: "hidden",
    "&>div": {
      padding: "2rem",
      cursor: "pointer",
      transition: "all 0.2s linear",
      "&:hover": {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  show: {
    height: "13rem",
  },
  hide: {
    height: "0",
  },
  accordion: {
    width: "100%",
    boxShadow: "none !important",
    "& .MuiAccordionSummary-root": {
      minHeight: "unset !important",
      "& .MuiAccordionSummary-content": {
        margin: "0 0 !important",
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "1rem 0",
      boxShadow: "0.2rem 0rem 0.1rem",
    },
  },
}));
