import AppLayout from "../CommonComponent/AppLayout/AppLayout";
import { Box, Typography, Stack } from "@mui/material";
import ParaCard from "./Card/ParaCard";
import { useStyles } from "./HomeAutoMation.styles";
import {
  Home_Automation,
  Homeauto1,
  Homeauto2,
  Homeauto3,
  LikeIcon,
} from "../Assets";
import { ScrollComponent } from "../CommonComponent/ScrollComponent/ScrollComponent";

const HomeAutoMation = () => {
  const styles = useStyles();
  const paraData = [
    {
      boldText: "MEET V/S TOUCH : ",
      // content: "iurwfhqiowedm",
      content:
        "Smart homes just got smarter, simpler and affordable with VTouch Pro, the future of home automation. Because of its unique ability to simply replace regular electrical switches, VTouch Pro's innovative use of wireless technology gives you all of the benefits of a typical home automation system without the need for expensive infrastructure, renovations or complicated, wired installations. At the swipe of your fingertip, you can control the lights, blinds, arm your security system or control your HVAC through an app on your favorite mobile device. Should your device ever malfunction or you just prefer not to use one, controlling these features of your home can be done simply by touching the VTouch Pro switches. Now you can also upgrade your interior design with VTouch Pro's digital elegant glass touch switches which give you the benefit of functioning through a central controller.",
    },
    {
      boldText: "YOUR DIGITAL HOME : ",
      content:
        "Today all of your home appliances are digital. Shouldn't your electrical switches, which you use the most, be too? It will be love at first touch as you enjoy the comfort and stability of knowing VTouch Pro is always there.",
    },
    {
      boldText: "CONTROL YOUR BUSINESS WITH ONE TOUCH : ",
      content:
        "Rest easy knowing that you’re in control of your business even when you’re not there. With cost saving measures like the ability to turn off forgotten office lights or activate your shop’s security system, you’ll never have to worry with VTouch Pro on the job. Set the mood in your restaurant or workplace by creating a scenario which suits your needs; brighten the lights to energize the atmosphere or dim them low to create a more intimate feeling, all of this and more can be controlled on a regular basis by using the VTouch Pro’s time and date option in the calendar.",
    },
    {
      boldText: "DESIGN : ",
      content:
        "Now you can upgrade your interior space without a second thought, bringing the finer things in life into your home and office with VTouch Pro. The VBox Pro Controller’s small size means you don’t have to waste space on a traditionally large electrical board. VTouch Pro’s customized glass panels are available in Vitrea’s signature stylish colors and marked with personalized engraving so you can tailor your buttons to the exact specifications that suit your needs. Glass frames custom built for the electrical sockets offer your interior a finished look. Flushed or mounted, vertical or horizontal, VTouch Pro integrates seamlessly and discreetly onto any wall, with blue LED lights always glowing from your glass panel to light your way. Isn’t it time you reinvented your space into modern luxury? With VTouch Pro it’s your world, your way, with a simple touch of a button.",
    },
    {
      boldText: "TECHNOLOGY SIMPLIFIED : ",
      content:
        "ARANET takes home automation to the next level. ARANET, which is based on RF Mesh, creates a virtual spider web, connected through each VTouch Pro switch. When the VBox Pro Controller receives a command, the data moves through the web to the specific switch it needs, at lightning fast speeds and with ultimate reliability. Easy to use and intuitive, everyone can build their own lighting scenarios and control their home’s interior and exterior. In just seconds and as often as you like, you can change your customized scenarios through easy to use software. Once you install VTouch Pro, there’s no need to rely on a service technician to reprogram your system or create new scenarios. VTouch Pro switches are authentic switches, not keypads. They function 100% of the time so that you don’t have to rely on the app or the main controller. This makes VTouch Pro the most stable and reliable in home automation.",
    },
    {
      boldText: "Features and Benefits : ",
      content:
        "Affordable for every home and office | Eliminates the need to invest in a multi-controller system or expensive infrastructure | Small, easy to hide VBox Pro Controller | Safe and reliable ARANET technology Simple installation and configuration | User friendly programming options and one touch control scenarios | Control electronic appliances from anywhere with your smartphone or mobile device | Specific scenarios can be scheduled using the system’s calendar | Customized scheduling for different rooms in your home or office | Automate at your own pace | Stylish colors and personalized engravings offer a customizable design",
    },
  ];

  const imgContent = [
    {
      id: 1,
      title: "WELCOME TO OUR SMART HOME",
      subTitle:
        "where lights, temperature, and security are all at your fingertips.From smart thermostats to automated blinds, our home is equipped with the latest in automation technology for a seamless living experience.",
      bg_img: Homeauto1,
    },
    {
      id: 2,
      title: "WELCOME TO OUR SMART HOME",
      subTitle:
        "where lights, temperature, and security are all at your fingertips.From smart thermostats to automated blinds, our home is equipped with the latest in automation technology for a seamless living experience.",
      bg_img: Homeauto2,
    },
    {
      id: 3,
      title: "WELCOME TO OUR SMART HOME",
      subTitle:
        "where lights, temperature, and security are all at your fingertips.From smart thermostats to automated blinds, our home is equipped with the latest in automation technology for a seamless living experience.",
      bg_img: Homeauto3,
    },
    {
      id: 4,
      title: "WELCOME TO OUR SMART HOME",
      subTitle:
        "where lights, temperature, and security are all at your fingertips.From smart thermostats to automated blinds, our home is equipped with the latest in automation technology for a seamless living experience.",
      bg_img: Homeauto1,
    },
  ];

  return (
    <>
      <AppLayout isEnquiryForm isImageSlider ImgContent={imgContent}>
        <Stack padding={"2rem"}>
          <ScrollComponent variant="slide_left">
            <Box className={styles.Box1}>
              <Typography variant="h3" className={styles.typoHeading}>
                VICTREA
              </Typography>
              <Box padding={"1rem"} />
              <Typography variant="h4">
                DISCOVER A WORLD OF DIGITAL LUXURY
              </Typography>
            </Box>
          </ScrollComponent>
          <Box padding={"1rem"} />
          <ScrollComponent variant="slide_left">
            <ParaCard paraData={paraData[0]} />{" "}
          </ScrollComponent>
          <Box padding={"1rem"} />
          <ScrollComponent>
            <ParaCard paraData={paraData[1]} />
          </ScrollComponent>{" "}
          <Box padding={"1rem"} />
          <ScrollComponent variant="slide_left">
            <ParaCard paraData={paraData[2]} />
          </ScrollComponent>
          <Box padding={"1rem"} />
          <ScrollComponent variant="slide_left">
            <ParaCard paraData={paraData[3]} />
          </ScrollComponent>
          <Box padding={"1rem"} />
          <ScrollComponent variant="slide_left">
            <ParaCard paraData={paraData[4]} />
          </ScrollComponent>
          <Box padding={"1rem"} />
          <ScrollComponent variant="slide_left">
            <ParaCard paraData={paraData[5]} />
          </ScrollComponent>
          <Box padding={"1rem"} />
        </Stack>
      </AppLayout>
    </>
  );
};

export default HomeAutoMation;
