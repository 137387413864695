import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "35rem",
    height: "40rem",
    gap: "1rem",
    borderRadius: "1rem",
    boxShadow: "0 0 0.7rem",
    overflow: "hidden",
    margin: "1rem",
    cursor: "pointer",
    background: theme.palette.background.main,
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  cardImg: {
    "& img": {
      width: "100%",
      height: "15rem",
      objectFit: "cover",
    },
  },
  statusContainer: {
    "&>div": {
      padding: "0.5rem 1rem",
      borderRadius: "0.6rem",
      width: "fit-content",
      background: `${theme.palette.primary.main}ae`,
    },
  },
  icon: {
    width: "2rem",
    height: "2rem",
    "& path": {
      fill: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
    },
  },
}));
